import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import * as actions from '../../auth/actions';
import { checkAuthAccess } from '../../actions';
import { connect } from 'react-redux';

import Dashboard from '../dashboard';
import Game from '../game';
import Games from '../games';
import Users from '../users';
import Outlets from '../outlets';
import Transactions from '../transactions';
import Userlogs from '../userlogs';
import Requests from '../requests';
import Pos from '../pos';
import UserPermissions from '../userPermissions';
import UserRoles from '../userRoles';
import UserScreens from '../userScreens';
import userVerification from '../userVerification';
import userAgeVerification from '../userAgeVerification';
import reports from '../reports';

import { Result, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
const NoMatch = () => (
  <div>
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="primary">Back</Button>
        </Link>
      }
    />
  </div>
);

class BaseRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.checkAccess('1', 'read');
    this.checkAccess('2', 'read');
    this.checkAccess('3', 'read');
    this.checkAccess('4', 'read');
    this.checkAccess('5', 'read');
    this.checkAccess('6', 'read');
    this.checkAccess('7', 'read');
    this.checkAccess('8', 'read');
    this.checkAccess('12', 'read');
    this.checkAccess('14', 'read');
    this.checkAccess('15', 'read');
    this.checkAccess('16', 'read');
  }

  checkAccess(screen, action) {
    var name = action + screen;
    this.setState({ [name]: true, loading: true });
    if (this.props.user_admin) {
      this.setState({ [name]: true, loading: false });
    } else {
      this.setState({ [name]: false, loading: false });
      this.props
        .checkAuthAccess(this.props.user_role, screen, action, this.props.token)
        .then((res) => {
          var permission = res.data.results[0];
          console.log(permission);
          if (permission[action] && action) {
            this.setState({ [name]: true, loading: false });
          } else {
            this.setState({ [name]: false, loading: false });
          }
        })
        .catch((err) => {
          console.log(err);

          this.setState({ [name]: false, loading: false });
        });
    }
  }

  returnRoute(screen, path, Component) {
    if (this.state[`read${screen}`]) {
      return <Route exact path={path} component={Component} />;
    } else if (this.state.loading) {
      return (
        <div>
          <Result icon={<Spin size="large" />} title="🔒ACCESS CHECKING" />
        </div>
      );
    } else {
      return <Route exact path={path} component={NoMatch} />;
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Result icon={<Spin size="large" />} title="🔒ACCESS CHECKING" />
        </div>
      );
    } else {
      return (
        <Switch>
          <Route exact path="/" component={Dashboard} />

          {this.returnRoute('1', '/games', Games)}
          {this.returnRoute('2', '/users', Users)}
          {this.returnRoute('5', '/outlets', Outlets)}
          {this.returnRoute('7', '/transactions', Transactions)}
          {this.returnRoute('8', '/userlogs', Userlogs)}
          <Route exact path="/requests" component={Requests} />
          {this.returnRoute('12', '/games/:id/', Game)}
          {this.returnRoute('6', '/pos', Pos)}

          {this.returnRoute('4', '/permissions', UserPermissions)}
          {this.returnRoute('3', '/roles', UserRoles)}
          {this.returnRoute('14', '/userverification', userVerification)}
          {this.returnRoute('16', '/userAgeVerification', userAgeVerification)}
          {this.returnRoute('15', '/reports', reports)}
          {/*userscreen for dev only */}
          <Route exact path="/screens" component={UserScreens} />

          <Route component={NoMatch} />
        </Switch>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user.user_id,
    user_role: state.auth.user.user_role,
    user_admin: state.auth.user.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (token, user_id) => dispatch(actions.logout(token, user_id)),
    checkAuthAccess: (user_role, screen, action, token) =>
      dispatch(checkAuthAccess(user_role, screen, action, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseRouter);
