import React, { Component } from 'react';

import {
  Button,
  Cascader,
  Checkbox,
  Dropdown,
  Input,
  InputNumber,
  List,
  message,
  Menu,
  Modal,
  PageHeader,
  Pagination,
  Table,
  Select,
  Space,
  Spin,
  Switch,
  Popover,
  Statistic,
  Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import './index.scss';

import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';

import {
  getData,
  addItem,
  editItem,
  deleteItem,
  openEdit,
  getOptions,
  getRangedData_callcard,
  getRangedMonths,
} from '../../../actions';
import axios from 'axios';
import { CSVLink } from 'react-csv';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const Headers = [
  { label: 'id', key: 'id' },
  { label: 'transacted_at', key: 'transacted_at' },
  { label: 'ref_num', key: 'ref_num' },
  { label: 'user_id', key: 'user_id' },
  { label: 'price', key: 'price' },
  { label: 'play_type', key: 'play_type' },
  { label: 'draw_dates', key: 'draw_dates' },
  { label: 'draw_numbers', key: 'draw_numbers' },
  { label: 'bets', key: 'bp_bets' },
];

class Export extends Component {
  state = {
    loading: false,
    visible: false,
    data: [],
    exportlist: [],
  };

  exportData() {
    if (this.props.dateRange.length > 0 && this.state.loading == false) {
      this.setState({ loading: true });
      var moment1 = this.props.dateRange[0];
      var moment2 = this.props.dateRange[1];
      var startDate = moment(moment1).format('YYYY-MM-DD');
      var endDate = moment(moment2).format('YYYY-MM-DD');

      var gtApi = 'api/game/get_transactions_by_month';
      var controllerText = `?start_date=${startDate}&end_date=${endDate}`;
      if (this.props.filter) {
        var filterText = this.props.filter;
        controllerText = controllerText + filterText;
      }

      console.log(this.props.filter);
      this.props
        .getRangedMonths(
          this.props.admin_gateway,
          gtApi,
          this.props.token,
          controllerText
        )
        .then((res) => {
          console.log(res.data);
          this.setState({ data: res.data.transactions_list }, () => {
            this.dataFilter();
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else if (this.props.dateRange.length < 1) {
      message.error('No Dates selected');
    } else if (this.state.loading) {
      message.error('Please wait retreiving data');
    }
  }

  dataFilter() {
    var betsList = [];

    let items = [...this.state.data];

    for (let i = 0; i < this.state.data.length; i++) {
      let item = { ...items[i] };
      console.log(item);
      const bets = item.bp_bets;
      bets.forEach((bp_bet) => {
        console.log(bp_bet);
        betsList.push(bp_bet.bp_bet);
      });
      console.log(betsList);

      item.bp_bets = betsList;
      console.log(item);
      items[i] = item;
      console.log(items);
    }
    this.setState({ items, exportlist: items }, () => this.Export());
  }

  Export() {
    setTimeout(
      function () {
        message.info('exporting');
        console.log(this.state);
        this.csvLink.link.click();
        this.setState({ loading: false });
      }.bind(this),
      2000
    );
  }

  renderMenuItems = () => {
    let menuItems = [];

    menuItems = Headers.map((item) => {
      return (
        <Menu.Item>
          <p style={{ marginBottom: '20px' }}>
            <Checkbox
              checked={this.state.checked}
              disabled={this.state.disabled}
              onChange={this.onChange}
            >
              {item.label}
            </Checkbox>
          </p>
        </Menu.Item>
      );
    });
    return menuItems;
  };

  render() {
    const exportMenu = <Menu>{this.renderMenuItems()}</Menu>;

    return (
      <div>
        <CSVLink
          data={this.state.exportlist}
          headers={Headers}
          filename={`bets.csv`}
          target="_blank"
          className="hiddencsv"
          ref={(r) => (this.csvLink = r)}
        ></CSVLink>

        {/* <Dropdown
          className="add-button"
          overlay={exportMenu}
          placement="bottomLeft"
        >
          <Button loading={this.state.loading}>
            <DownloadOutlined />
            Export all within dates
          </Button>
        </Dropdown> */}

        <Button
          type={this.props.dateRange.length > 0 ? 'primary' : 'dashed'}
          className="add-button"
          onClick={() => {
            this.exportData();
          }}
          loading={this.state.loading}
        >
          <DownloadOutlined />
          Export all within dates
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.crud_data.data,
    editModalOpen: state.crud_data.editModalOpen,
    item: state.crud_data.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRangedData_callcard: (token, data) =>
      dispatch(getRangedData_callcard(token, data)),
    getRangedMonths: (
      gameAdminApi,
      getTransactionsUrl,
      token,
      controllerText
    ) =>
      dispatch(
        getRangedMonths(gameAdminApi, getTransactionsUrl, token, controllerText)
      ),
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    addItem: (token, data, apiurl) => dispatch(addItem(token, data, apiurl)),
    editItem: (token, data, apiurl, id) =>
      dispatch(editItem(token, data, apiurl, id)),
    deleteItem: (token, apiurl, id) => dispatch(deleteItem(token, apiurl, id)),
    openEdit: (item, editModalOpen) => dispatch(openEdit(item, editModalOpen)),
    getOptions: (token, apiurl, controller) =>
      dispatch(getOptions(token, apiurl, controller)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
