import React, { Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PageHeader, Input, Button, Table, DatePicker, message } from 'antd';
import { connect } from 'react-redux';

import Highlighter from 'react-highlight-words';
import './index.scss';

import PreviewDrawer from '../../components/PreviewDrawer';
import {
  getCallCardData,
  searchCallCardData,
  openEdit_callcard,
  openPreview_callcard,
  checkAuthAccess,
  openPreview,
} from '../../actions';
import moment from 'moment';
import ExportSelected from '../../components/transactionsExport/exportSelected_Transactions';
import ExportDated from '../../components/transactionsExport/exportDated_Transactions';

const { RangePicker } = DatePicker;
const { Search } = Input;
const INITIAL_STATE = {
  searchText: '',
};

const API_URL = 'posts';
const PAGENAME = 'Transaction';
const SCREEN_ID = '7';

const MODAL_BLUEPRINT = [
  {
    title: 'ID',
    key: 'transaction_id',
    type: 'dont',
  },
  {
    title: 'created at',
    key: 'created_at',
    type: 'text',
  },
  {
    title: 'Reference',
    key: 'reference',
    type: 'text',
  },
  {
    title: 'claimed',
    key: 'claimed',
    type: 'boolean',
  },
  {
    title: 'claimed at',
    key: 'claimed_at',
    type: 'text',
  },
  {
    title: 'paid',
    key: 'paid',
    type: 'boolean',
  },
  {
    title: 'paid at',
    key: 'paid_at',
    type: 'text',
  },
  {
    title: 'payment gateway',
    key: 'payment_gateway',
    type: 'text',
  },
  {
    title: 'user',
    key: 'user',
    type: 'text',
  },
  {
    title: 'cp num',
    key: 'cp_num',
    type: 'text',
  },
  {
    title: 'void',
    key: 'void',
    type: 'boolean',
  },
  {
    title: 'voided at',
    key: 'void_at',
    type: 'text',
  },
  {
    title: 'void_reason',
    key: 'void_reason',
    type: 'text',
  },
  {
    title: 'updated at',
    key: 'updated_at',
    type: 'text',
  },
];

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      data: [],
      sorter: null,
      filterArray: [],
      filters: null,
      page: 1,
      loading: true,
      searchText: null,
      offset: 0,
      pageSize: 12,
      defaultpageSize: 12,
      range: [1, 12],

      selectedRowKeys: [],
      selectedRowItems: [],

      selectedDateRange: [],
      dateFilterAcivated: false,

      COLUMNS: [
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
          sorter: (a, b) => a.created_at > b.created_at,
          render: (text, record, index) => {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          },
        },
        {
          title: 'User',
          dataIndex: 'user',
          key: 'user',
          sorter: (a, b) => a.user > b.user,
          ...this.getColumnSearchProps('user'),
        },

        {
          title: 'CP num',
          dataIndex: 'cp_num',
          key: 'cp_num',
          sorter: (a, b) => a.cp_num > b.cp_num,
          ...this.getColumnSearchProps('cp_num'),
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          sorter: (a, b) => a.amount > b.amount,
          ...this.getColumnSearchProps('amount'),
        },
        {
          title: 'Payment gateway',
          dataIndex: 'payment_gateway',
          key: 'payment_gateway',
          sorter: (a, b) => a.payment_gateway > b.payment_gateway,
          ...this.getColumnSearchProps('payment_gateway'),
          render: (text, record, index) => {
            return this.filterPaymentgGateway(text);
          },
        },
        {
          title: 'Claimed',
          dataIndex: 'claimed',
          key: 'claimed',
          sorter: (a, b) => a.claimed > b.claimed,
          ...this.getColumnSearchProps('claimed'),
        },
        {
          title: 'Paid',
          dataIndex: 'paid',
          key: 'paid',
          sorter: (a, b) => a.paid > b.paid,
          ...this.getColumnSearchProps('paid'),
        },
        {
          title: 'Void ',
          dataIndex: 'void',
          key: 'void',
          sorter: (a, b) => a.void > b.void,
          ...this.getColumnSearchProps('void'),
        },
        {
          title: 'Reference',
          dataIndex: 'reference',
          key: 'reference',
          sorter: (a, b) => a.reference > b.reference,
          width: 'auto',

          ...this.getColumnSearchProps('reference'),
        },
      ],
    };
  }

  filterPaymentgGateway(id) {
    switch (id) {
      case 2:
        return 'Paymaya';
      case 4:
        return 'GCash';
      case 5:
        return 'Grabpay';
      default:
        return id;
    }
  }

  componentDidMount() {
    this.getTableData(this.state.defaultpageSize, 0, null, null, null);
    this.checkAccess(SCREEN_ID);
  }
  checkAccess(screen) {
    if (this.props.user_admin) {
      Object.entries(this.props.screenActions).map(([key, value]) => {
        var name = value + screen;
        this.setState({ [name]: true });
      });
    } else {
      this.props
        .checkAuthAccess(this.props.user_role, screen, 'read', this.props.token)
        .then((res) => {
          var permission = res.data.results[0];
          Object.entries(this.props.screenActions).map(([key, value]) => {
            var name = value + screen;
            if (permission[value]) {
              this.setState({ [name]: true });
            } else {
              this.setState({ [name]: false });
            }
          });
        })
        .catch((err) => {
          Object.entries(this.props.screenActions).map(([key, value]) => {
            var name = value + screen;
            this.setState({ [name]: false });
          });
        });
    }
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data });
      if (this.props.data) {
        if (this.props.data.length > 0) {
          this.setState({ loading: false });
        } else {
          setTimeout(
            function () {
              //Start the timer
              this.setState({ loading: false }); //After 1 second, set render to true
            }.bind(this),
            1000
          );
        }
      } else {
        setTimeout(
          function () {
            //Start the timer
            this.setState({ loading: false }); //After 1 second, set render to true
          }.bind(this),
          1000
        );
      }
    }
  }

  getTableData(limit, offset, sort, filter, search) {
    this.setState({ loading: true });
    if (this.props.token) {
      var limitoffset = `?limit=${limit}&offset=${offset}&paid=True`;
      var controllerText = limitoffset;
      var searchText = '';
      if (search !== null) {
        searchText = `&search=${search}`;
        controllerText = controllerText + searchText;
      }
      var filterText = '';
      if (filter !== null) {
        filterText = filter;
        controllerText = controllerText + filterText;
      }
      var sortText = '';
      if (sort !== null) {
        sortText = sort;
        controllerText = controllerText + sortText;
      }
      if (this.state.dateFilterAcivated) {
        var moment1 = this.state.selectedDateRange[0];
        var moment2 = this.state.selectedDateRange[1];
        var startDate = moment(moment1).format('YYYY-MM-DD');
        var endDate = moment(moment2).format('YYYY-MM-DD');
        controllerText =
          controllerText + `&start_date=${startDate}&end_date=${endDate}`;
      }
      this.props.getCallCardData(this.props.token, API_URL, controllerText);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.handleSorter(sorter);
  };

  handleSorter(sorter) {
    if (this.state.sorter !== sorter) {
      var sorterValue = null;

      if (sorter.order === 'ascend') {
        sorterValue = `&ordering=${sorter.columnKey}`;
        this.setState({ sorter: sorterValue });
        this.getTableData(
          this.state.pageSize,
          this.state.offset,
          sorterValue,
          this.state.filters,
          this.state.searchText
        );
      } else if (sorter.order === 'descend') {
        sorterValue = `&ordering=-${sorter.columnKey}`;
        this.setState({ sorter: sorterValue });
        this.getTableData(
          this.state.pageSize,
          this.state.offset,
          sorterValue,
          this.state.filters,
          this.state.searchText
        );
      } else if (sorter.order === undefined) {
        sorterValue = null;
        this.setState({ sorter: sorterValue });
      }
    }
  }

  onSearch(value) {
    var searchval = value.toString();
    this.setState({ searchText: searchval, page: 1 });
    if (searchval) {
      this.getTableData(this.state.defaultpageSize, 0, null, null, searchval);
    } else {
      this.getTableData(
        this.state.defaultpageSize,
        0,
        null,
        this.state.filters,
        null
      );
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText, this.state.filterText]}
        autoEscape
        textToHighlight={text ? text.toString() : null}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.addtoFilterArray(selectedKeys[0], dataIndex);
    this.setState({ filterText: selectedKeys[0] });
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();

    this.removeFilterArray(dataIndex);
    this.setState({ filterText: '' });
  };
  generateFilterController(currentArray) {
    var filter = '';
    Object.entries(currentArray).map(([key, value]) => {
      filter = filter + `&${value.dataIndex}=${value.value}`;
    });
    this.setState({ filters: filter });
    this.getTableData(
      this.state.defaultpageSize,
      0,
      null,
      filter,
      this.state.searchText
    );
  }

  addtoFilterArray(value, dataIndex) {
    var newItem = {
      value: value,
      dataIndex: dataIndex,
    };
    var i;
    for (i = 0; i < this.state.filterArray.length; i++) {
      if (this.state.filterArray[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray;
        newArray[i] = newItem;
        return this.setState(
          { filterArray: newArray },
          this.generateFilterController(newArray)
        );
      }
    }
    return this.setState(
      { filterArray: this.state.filterArray.concat(newItem) },
      this.generateFilterController(this.state.filterArray.concat(newItem))
    );
  }

  removeFilterArray(dataIndex) {
    var i;
    for (i = 0; i < this.state.filterArray.length; i++) {
      if (this.state.filterArray[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray;
        newArray.splice(i, 1);
        return this.setState(
          { filterArray: newArray },
          this.generateFilterController(newArray)
        );
      }
    }
    return this.generateFilterController(this.state.filterArray);
  }

  resetTable() {
    this.getTableData(this.state.defaultpageSize, 0, null, null, null);

    this.setState({
      defaultpageSize: 12,
      pageSize: 12,
      range: [1, 12],
      page: 1,
      filterArray: [],
      filters: null,
      filterText: null,
      searchText: null,
      sorter: null,
      loading: true,
    });
  }
  openEditModal(record) {
    if (record && record !== undefined) {
      this.props.openEdit_callcard(record, true);
    }
  }

  onChangePage(page, pageSize) {
    if (this.state.loading === false) {
      this.setState({
        page: page,
      });
    }
  }

  onChangePageSize(currentPage, pageSize) {
    if (this.state.pageSize !== pageSize && this.state.loading === false) {
      this.setState({
        pageSize: pageSize,
        page: 1,
        offset: 0,
      });
      this.getTableData(
        pageSize,
        0,
        this.state.sorter,
        this.state.filters,
        this.state.searchText
      );
    }
  }

  onChangeRange(total, range) {
    if (this.state.range[0] !== range[0] || this.state.range[1] !== range[1]) {
      var offset = range[0] - 1;
      this.setState({ range: range });
      this.getTableData(
        this.state.pageSize,
        offset,
        this.state.sorter,
        this.state.filters,
        this.state.searchText
      );
    }
  }

  columnfilter() {
    var filteredColumn = this.state.COLUMNS.slice();

    return filteredColumn;
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // //console.log('selectedRowchanged: ', selectedRows);
    this.setState({
      selectedRowKeys,
      selectedRowKeys,
      selectedRowItems: selectedRows,
    });
  };

  DoDateFilter() {
    if (this.state.dateFilterAcivated) {
      this.setState(
        { selectedDateRange: [], dateFilterAcivated: false },
        () => {
          this.getTableData(
            this.state.pageSize,
            0,
            this.state.sorter,
            this.state.filters,
            this.state.searchText
          );
        }
      );
    } else if (this.state.selectedDateRange.length > 0) {
      this.setState({ dateFilterAcivated: true }, () => {
        this.getTableData(
          this.state.pageSize,
          0,
          this.state.sorter,
          this.state.filters,
          this.state.searchText
        );
      });
    } else {
      message.error('Filter not applied');
    }
  }

  render() {
    let { sorter, selectedRowKeys } = this.state;
    sorter = sorter || {};
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    var keyedData = [];
    if (this.state.data) {
      keyedData = Object.entries(this.state.data).map(([key, value]) => {
        var newvalue = {
          ...value,
          key: value.id,
        };
        return newvalue;
      });
    }

    if (this.state[`read${SCREEN_ID}`]) {
      return (
        <>
          <div className="generic-container">
            <PageHeader
              title={PAGENAME + 's'}
              subTitle=""
              extra={
                <div className="header">
                  <Button
                    onClick={() => {
                      this.resetTable();
                    }}
                    className="button"
                  >
                    <LegacyIcon type="reload" />
                  </Button>
                  <Search
                    className="search"
                    placeholder="search"
                    allowClear={true}
                    onSearch={(value) => {
                      this.onSearch(value);
                    }}
                  />
                </div>
              }
            >
              <div className="header">
                <Button
                  onClick={() => {
                    this.DoDateFilter();
                  }}
                  type={
                    this.state.selectedDateRange.length > 0
                      ? 'primary'
                      : 'dashed'
                  }
                  danger={this.state.dateFilterAcivated}
                >
                  {this.state.dateFilterAcivated ? 'clear' : 'Filter by date'}
                </Button>
                <RangePicker
                  onChange={(val) => {
                    this.setState({ selectedDateRange: val });
                  }}
                  allowClear={false}
                  disabledDate={(current) => {
                    return current > moment().endOf('day');
                  }}
                  value={this.state.selectedDateRange}
                />
                <ExportDated dateRange={this.state.selectedDateRange} />
                <ExportSelected selectedData={this.state.selectedRowItems} />

                {/* <AddModal headername={PAGENAME} blueprint={MODAL_BLUEPRINT} apiurl={API_URL}/> */}
              </div>
            </PageHeader>
            <div className="content-container">
              <Table
                loading={this.state.loading}
                dataSource={keyedData}
                scroll={{ x: 1300 }}
                columns={this.columnfilter()}
                sorter={true}
                rowSelection={rowSelection}
                pagination={{
                  onChange: (page, pageSize) => {
                    this.onChangePage(page, pageSize);
                  },
                  showTotal: (total, range) => {
                    this.onChangeRange(total, range);
                  },
                  onShowSizeChange: (current, size) => {
                    this.onChangePageSize(current, size);
                  },
                  current: this.state.page,
                  showQuickJumper: true,
                  defaultPageSize: this.state.defaultpageSize,
                  pageSizeOptions: [
                    this.state.defaultpageSize,
                    '20',
                    '50',
                    '100',
                    '1000',
                    '10000',
                  ],
                  showSizeChanger: true,
                  total: this.props.dataCount,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.props.openPreview(record, true);
                    }, // click row
                  };
                }}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
          {/* <EditModal headername={PAGENAME} blueprint={MODAL_BLUEPRINT} screen_id={SCREEN_ID} apiurl={API_URL}/>*/}
          <PreviewDrawer blueprint={MODAL_BLUEPRINT} />
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.callcard_data.data,
    dataCount: state.callcard_data.dataCount,
    dataNext: state.callcard_data.dataNext,
    dataPrevious: state.callcard_data.dataPrevious,

    user_id: state.auth.user.user_id,
    user_role: state.auth.user.user_role,
    user_admin: state.auth.user.admin,
    screenActions: state.auth.screenActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCallCardData: (token, apiurl, contoller) =>
      dispatch(getCallCardData(token, apiurl, contoller)),
    searchCallCardData: (filter, data) =>
      dispatch(searchCallCardData(filter, data)),
    openEdit_callcard: (item, editModalOpen) =>
      dispatch(openEdit_callcard(item, editModalOpen)),
    openPreview_callcard: (item, previewModalOpen) =>
      dispatch(openPreview_callcard(item, previewModalOpen)),
    openPreview: (item, previewModalOpen) =>
      dispatch(openPreview(item, previewModalOpen)),

    checkAuthAccess: (user_role, screen, action, token) =>
      dispatch(checkAuthAccess(user_role, screen, action, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
