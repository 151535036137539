import { combineReducers } from 'redux';

import auth from '../auth/reducers';
import crudReducer from './crudReducer';
import authReducer from './authReducer';
import gameReducer from './gameReducer';
import callCardReducer from './callCardReducer';
import reportReducer from './reportReducer';

const rootReducer = combineReducers({
  auth,
  crud_data: crudReducer,
  auth_data: authReducer,
  game_data: gameReducer,
  callcard_data: callCardReducer,
  report_data: reportReducer,
});

export default rootReducer;
