import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Select, message } from 'antd';
import { connect } from 'react-redux';

import './index.scss';

import {
  getData_AUTH,
  addItem_AUTH,
  getOptions_AUTH,
  logAction,
  userVerify_AUTH,
  ageVerifyAuth,
} from '../../actions';
import 'antd/dist/antd.css';
import { resolve } from 'dns';
import { UserAddOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

class VerifyModal extends Component {
  state = {
    loading: false,
    visible: false,
    selectedUser: null,
  };

  showModal = () => {
    if (this.state.selectedUser == null) {
      this.getnewData();
    }
  };

  componentDidUpdate() {
    if (this.state.selectedUser !== this.props.selectedUser) {
      this.setState({ selectedUser: this.props.selectedUser });
      this.getSpecificUser(this.props.selectedUser);
    }
  }

  getSpecificUser(user_id) {
    this.props
      .getOptions_AUTH(
        this.props.token,
        'userverifyitem',
        `?user_id=${user_id}`
      )
      .then((res) => {
        var userdata = res.data.user_verify;
        var user = res.data.user;
        userdata['email'] = user.email;
        userdata['phone_number'] = user.phone_number;

        this.setState({
          visible: true,
          loading: false,
          userdata,
        });
      })
      .catch((err) => {
        message.error('an error has  occured');
        this.setState({ loading: false });
        this.setState({ selectedUser: null });
        this.props.clearSelectedUser();
      });
  }

  getnewData() {
    if (this.state.loading == false) {
      this.setState({ loading: true });
      this.props
        .getOptions_AUTH(
          this.props.token,
          'userverify/',
          '?is_verified_checked=false&limit=1'
        )
        .then((res) => {
          if (res.data.count > 0) {
            var item = res.data.results[0];
            this.getSpecificUser(item.user_id);
          } else {
            message.error('nothing to verify');
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          message.error('an error has  occured');
          this.setState({ loading: false });
        });
    }
  }

  handleSubmit = (verify) => {
    this.setState({ loading: true });
    this.props
      .userVerify_AUTH(verify, this.state.userdata.user, this.props.token)
      .then((res) => {
        // console.log(res)
        if (res.data.Error) {
          this.setState({
            loading: false,
            userdata: null,
          });
          message.error(res.data.Error);
        } else {
          this.setState(
            {
              loading: false,
              userdata: null,
            },
            () => {
              this.setState({ selectedUser: null });
              this.props.clearSelectedUser();
              this.getnewData();
            }
          );
        }
      })
      .catch((err) => {
        try {
          Object.entries(err.response.data).map(([key, value]) => {
            message.error(key + ': ' + value);
          });
        } catch {
          message.error(err.message);
        }

        this.setState({
          loading: false,
        });
      });
  };

  handleCancel = () => {
    if (!this.state.loading) {
      this.setState({ visible: false });
    }
  };

  render() {
    const { visible, loading } = this.state;
    var imageId = new Image();
    if (this.state.userdata) {
      imageId.src = this.state.userdata.id_photo;
    }

    return (
      <div>
        <Button
          type="primary"
          onClick={() => {
            this.showModal();
          }}
          loading={loading}
          className="add-button"
        >
          <UserAddOutlined />
          Verify Users
        </Button>
        {visible ? (
          <Modal
            className="verify-modal"
            visible={visible}
            title={'Verify User'}
            width={'80vw'}
            onCancel={() => {
              this.handleCancel();
            }}
            footer={
              <>
                <Button
                  key="back"
                  onClick={() => {
                    this.handleCancel();
                  }}
                >
                  Cancel
                </Button>
                {this.props.selectedUserVChecked ? null : (
                  <Button
                    key="fail"
                    type="danger"
                    loading={loading}
                    onClick={() => {
                      this.handleSubmit(false);
                    }}
                  >
                    Fail Verification
                  </Button>
                )}
                {this.props.selectedUserVChecked ? null : (
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      this.handleSubmit(true);
                    }}
                  >
                    Pass Verification
                  </Button>
                )}
              </>
            }
          >
            {this.state.userdata ? (
              <div className="flex-grid-halves">
                <div className="formitem picture-half">
                  <span className="span">Selfie</span>
                  <img
                    className="image"
                    src={this.state.userdata.selfie_photo}
                  />
                </div>
                <div className="formitem picture-half">
                  <span className="span">ID Photo</span>
                  <img className="image" src={this.state.userdata.id_photo} />
                </div>
                <div className="formitem text">
                  <span className="span">ID</span>
                  <span>{this.state.userdata.user}</span>
                </div>
                <div className="formitem text">
                  <span className="span">ID number</span>
                  <span>{this.state.userdata.id_number}</span>
                </div>
                <div className="formitem text">
                  <span className="span">Name</span>
                  <span>{this.state.userdata.name}</span>
                </div>
                <div className="formitem text">
                  <span className="span">email</span>
                  <span>{this.state.userdata.email}</span>
                </div>
                <div className="formitem number">
                  <span className="span">Phone Number</span>
                  <span>{this.state.userdata.phone_number}</span>
                </div>
                <div className="formitem number">
                  <span className="span">Birthday</span>
                  <span>{this.state.userdata.birth_date}</span>
                </div>
                <div className="formitem number">
                  <span className="span">ID type</span>
                  <span>{this.state.userdata.id_type}</span>
                </div>
              </div>
            ) : (
              <div className="formitem number">
                <span className="span">no data</span>
              </div>
            )}
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user.user_id,
    data: state.crud_data.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData_AUTH: (token, apiurl, contoller) =>
      dispatch(getData_AUTH(token, apiurl, contoller)),
    addItem_AUTH: (data, apiurl, token) =>
      dispatch(addItem_AUTH(data, apiurl, token)),
    getOptions_AUTH: (token, apiurl, controller) =>
      dispatch(getOptions_AUTH(token, apiurl, controller)),
    logAction: (data, token) => dispatch(logAction(data, token)),
    userVerify_AUTH: (verify, id, token) =>
      dispatch(userVerify_AUTH(verify, id, token)),
    ageVerifyAuth: (verify, id, token) =>
      dispatch(ageVerifyAuth(verify, id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyModal);
