import * as actionTypes from './actionTypes';
import axios from 'axios';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user: user,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const forgotPassword = (email) => {
  return (dispatch) => {
    return axios.post(
      `${process.env.REACT_APP_AUTH_SERVER}password_reset/reset_password`,
      {
        email: email,
      }
    );
  };
};

export const authLogin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    console.log(`${process.env.REACT_APP_AUTH_SERVER}login`);

    axios.defaults.headers = {
      'Content-Type': 'application/json',
    };

    axios
      .post(`${process.env.REACT_APP_AUTH_SERVER}login`, {
        username: username,
        password: password,
      })
      .then((res) => {
        console.log(res);

        const token = res.data.access;
        var user = res.data.user;

        if (user.user_role === 1) {
          user['admin'] = true;
        } else {
          user['admin'] = false;
        }

        //need to change the condition to staff only
        if (
          user.user_role === 1 ||
          user.user_role === 3 ||
          user.user_role === 4 ||
          user.user_role === 6 ||
          user.user_role === 8
        ) {
          const expirationDate = new Date(
            new Date().getTime() + 3600 * 1000 * 8
          );
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('expirationDate', expirationDate);
          dispatch(authSuccess(token, user));
          checkAuthTimeout(3600);
          this.props.history.push('/');
        } else {
          dispatch(authFail(null));
        }
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const logout = (token = null, user_id = null) => {
  return (dispatch) => {
    if (token !== null && token !== undefined && user_id !== null) {
      dispatch({
        type: actionTypes.AUTH_LOGOUT,
      });
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('expirationDate');
      window.reload('/');
    }
  };
};

export const authSignup = (username, email, password1, password2) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(`${process.env.REACT_APP_AUTH_SERVER}api/auth/registration/`, {
        username: username,
        email: email,
        password1: password1,
        password2: password2,
      })
      .then((res) => {
        const token = res.data.key;
        const user = res.data.user.user_id;
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);

        localStorage.setItem('expirationDate', expirationDate);
        dispatch(authSuccess(token, user));
        checkAuthTimeout(3600);
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = (token, user) => {
  return (dispatch) => {
    var expirationDate = null;
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (token === undefined) dispatch(logout());
    else {
      expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token, user));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

export const validateToken = (token) => {
  return (dispatch) => {
    return axios.post(
      `${process.env.REACT_APP_AUTH_SERVER}api/password_reset/validate_token/`,
      {
        token: token,
      }
    );
  };
};

export const newPassword = (token, password, email) => {
  return (dispatch) => {
    return axios.post(
      `${process.env.REACT_APP_AUTH_SERVER}api/password_reset/reset_password/confirm`,
      {
        token: token,
        email: email,
        password: password,
      }
    );
  };
};
