import {
  GET_DATA,
  SEARCH_DATA,
  OPEN_EDIT,
  OPEN_PREVIEW,
  RESET_DATA,
} from './types';
import axios from 'axios';

export const getData = (token, apiurl, controller) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(
        process.env.REACT_APP_API_URL + apiurl + (controller ? controller : '')
      )
      .then((res) => {
        dispatch({
          type: GET_DATA,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_DATA,
          payload: { data: null },
        });
        console.log(err);
      });
  };
};

export const searchData = (filter, data) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_DATA,
      filter: filter,
      data: data,
    });
  };
};

export const addItem = (data, apiurl, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(process.env.REACT_APP_API_URL + apiurl, data);
  };
};

export const openEdit = (item, editModalOpen) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_EDIT,
      item: item,
      editModalOpen: editModalOpen,
    });
  };
};

export const editItem = (data, apiurl, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios
      .put(process.env.REACT_APP_API_URL + apiurl + `/${id}`, data)
      .then((res) => {
        console.log(res);
      });
  };
};

export const deleteItem = (apiurl, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios
      .delete(process.env.REACT_APP_API_URL + apiurl + `${id}/`)
      .then((res) => {});
  };
};

export const openPreview = (item, previewModalOpen) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_PREVIEW,
      item: item,
      previewModalOpen: previewModalOpen,
    });
  };
};

export const getOptions = (token, apiurl, controller) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios.get(process.env.REACT_APP_API_URL + apiurl + controller);
  };
};

export const logAction = (data, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(
      process.env.REACT_APP_API_URL + 'api/userActionLogs/',
      data
    );
  };
};
