import React, { Component } from 'react';
import { connect } from 'react-redux';
class ImageChecker extends Component {
    state = {
        status: false
    };

    componentDidMount(){
        this.checkImage(this.props.url).then(
            e=>{             
                if(e.status === 'ok'){      
                  this.setState({    
                    status:true
                  })
                }
            }
        )
      }

    checkImage = path =>
      new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve({path, status: 'ok'});
      img.onerror = () => resolve({path, status: 'error'});
  
      img.src = path;
    });

  render() {    
    if(this.state.status){        
        return (<>{this.props.success}</>);
    }else{
        return (<>{this.props.fail}</>);
    }
  }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(ImageChecker);