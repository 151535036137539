import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    PageHeader,
    Modal,
    Pagination,
    Input,
    Button,
    Table,
    Select,
    Spin,
    Switch,
    message,
    Popover,
    List,
    Cascader,
    InputNumber,
    Statistic,
    Popconfirm,
    DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames'
import moment from 'moment'
import './index.scss'

import {getData_AUTH,addItem_AUTH,getOptions_AUTH,logAction,userVerify_AUTH,sendNotification} from '../../actions'
import 'antd/dist/antd.css';
import { resolve } from 'dns';
import {
    UserAddOutlined,
    SendOutlined
  } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

class SendNotificationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false, 
            title:null,
            message:null
        }
    }

    showModal = () => {

        this.setState({
            visible: true,
            loading:false,
        
        });  
        
    };


    handleSubmit = (verify) => {
    
        this.setState({ loading: true });  
        var content ={
            title: this.state.title,
            message:this.state.message
        }
        this.props.sendNotification(content,this.props.token)
        .then(res=>{    
            // console.log(res) 
            if(res.data.Error){
                this.setState({
                    loading: false,
                
                })
                message.error(res.data.Error);
            }else{
                this.setState({
                    loading: false,
                    visible: false,
                })
                message.error("notification sent");
            }
        
        })
        .catch(err=>{
            try{
                Object.entries(err.response.data).map(([key, value]) =>{
                    message.error(key + ': ' + value);
                })
            }
            catch{
                message.error(err.message);  
            }
         
            this.setState({
                loading: false,
            
            })
           
        })
      
        
    };

    handleCancel = () => {
        if(!this.state.loading){
            this.setState({ visible: false });
        }
    };

  
    onChange = (key,value) => {
        this.setState({ [key]: value });  
    };
    
    
  render() {
    const { visible, loading } = this.state;

    
    return (
        <div>
          <Button type="primary" onClick={()=>{this.showModal()}} loading={loading} className="add-button">
                <SendOutlined />
              Send Notification
          </Button>
          {visible?
              <Modal
                  className="verify-modal"
                  visible={visible}
                  title={"Send Notification"}
              
        
                  onCancel={()=>{this.handleCancel()}}
              
                  footer={[
                      <Button key="back" onClick={()=>{this.handleCancel()}}>
                          Cancel
                      </Button>,         
                      <Button key="submit" type="primary" loading={loading} onClick={()=>{this.handleSubmit(true)} } >
                          SEND
                      </Button>,
                  ]}
              >     
                        <div className="formitem text">
                            <span className="span">Title</span>
                            <Input 
                                value={this.state.title}
                                onChange={e =>{this.onChange("title",e.target.value)}} 
                            />
            
                        </div>         
                        <div className="formitem text-area">
                            <span className="span">Message</span>
                            <TextArea 
                                value={this.state.message}
                                onChange={e =>{this.onChange("message",e.target.value)}} 
                            />
            
                        </div>   
    
              </Modal>:
              null    

          }
   
        </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user_id: state.auth.user.user_id,
        data: state.crud_data.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData_AUTH: (token,apiurl,contoller) => dispatch(getData_AUTH(token,apiurl,contoller)),
        addItem_AUTH:  (data,apiurl,token)  => dispatch(addItem_AUTH(data,apiurl,token)),
        getOptions_AUTH: (token, apiurl,controller) => dispatch(getOptions_AUTH(token, apiurl,controller)),
        logAction: (data,token) => dispatch(logAction(data,token)),
        userVerify_AUTH: (verify,id,token) => dispatch(userVerify_AUTH(verify,id,token)),
        sendNotification: (content,token) => dispatch(sendNotification(content,token)),


    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(SendNotificationModal);
          