import {
  GET_DATA,
  SEARCH_DATA,
  OPEN_EDIT,
  OPEN_PREVIEW,
  RESET_DATA,
} from '../actions/types';

const crud_data = {
  data: [],
  dataCount: 0,
  dataNext: null,
  dataPrevious: null,
  editModalOpen: false,
  previewModalOpen: false,
  item: [],
};

export default (state = crud_data, action) => {
  var dataArray;
  switch (action.type) {
    case GET_DATA:
      return {
        origData: action.payload.data.results,
        data: action.payload.data.results,
        dataCount: action.payload.data.count,
        dataNext: action.payload.data.next,
        dataPrevious: action.payload.data.previous,
      };
    case RESET_DATA:
      return {
        data: [],
        dataCount: 0,
        dataNext: null,
        dataPrevious: null,
        editModalOpen: false,
        previewModalOpen: false,
        item: [],
      };

    case SEARCH_DATA:
      if (action.filter) {
        dataArray = Object.values(action.data).filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(action.filter) > -1;
          });
        });
        return {
          ...state,
          data: dataArray,
        };
      } else
        return {
          ...state,
          data: action.data,
        };

    case OPEN_EDIT:
      return {
        ...state,
        item: action.item,
        editModalOpen: action.editModalOpen,
      };
    case OPEN_PREVIEW:
      return {
        ...state,
        item: action.item,
        previewModalOpen: action.previewModalOpen,
      };

    default:
      return state;
  }
};
