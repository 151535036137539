import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BaseRouter from './containers/Routes';
import CustomLayout from './containers/Layout';
import { connect } from 'react-redux';

import * as actions from './auth/actions';
import Login from '../src/containers/Login';

require('dotenv').config();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideLayout: false,
    };
  }

  componentDidMount() {
    this.props.onTryAutoSignup(this.props.token, this.props.user);
  }

  render() {
    if (this.props.isAuthenticated) {
      return (
        <div className="App">
          <Router>
            <CustomLayout {...this.props} hide={this.state.hideLayout}>
              <BaseRouter />
            </CustomLayout>
          </Router>
        </div>
      );
    } else {
      return (
        <div className="App">
          <Router>
            <Route path="/" component={Login} />
          </Router>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: (token, user) =>
      dispatch(actions.authCheckState(token, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
