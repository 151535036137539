export const GET_DATA = 'GET_DATA';
export const SEARCH_DATA = 'SEARCH_DATA';
export const ADD_ITEM = 'ADD_ITEM';
export const OPEN_EDIT = 'OPEN_EDIT';
export const EDIT_ITEM = 'EDIT_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const GET_OPTIONS = 'GET_OPTIONS';
export const OPEN_PREVIEW = 'OPEN_PREVIEW';
export const RESET_DATA = 'RESET_DATA';
export const LOG_ACTION = 'LOG_ACTION';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//Auth server

export const GET_DATA_AUTH = 'GET_DATA_AUTH ';
export const SEARCH_DATA_AUTH = 'SEARCH_DATA_AUTH ';
export const ADD_ITEM_AUTH = 'ADD_ITEM_AUTH ';
export const OPEN_EDIT_AUTH = 'OPEN_EDIT_AUTH ';
export const EDIT_ITEM_AUTH = 'EDIT_ITEM_AUTH ';
export const DELETE_ITEM_AUTH = 'DELETE_ITEM_AUTH ';
export const GET_OPTIONS_AUTH = 'GET_OPTIONS_AUTH ';
export const OPEN_PREVIEW_AUTH = 'OPEN_PREVIEW_AUTH ';
export const RESET_DATA_AUTH = 'RESET_DATA_AUTH ';
export const LOG_ACTION_AUTH = 'LOG_ACTION_AUTH ';
export const VERIFY_PASSWORD_START = 'VERIFY_PASSWORD_START ';
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS ';
export const VERIFY_PASSWORD_FAIL = 'VERIFY_PASSWORD_FAIL ';

//game
export const PASS_CURRENT_GAME = 'PASS_CURRENT_GAME';
export const ADD_DRAW = 'ADD_DRAW';
export const GET_GAME_TRANSACTIONS = 'GET_GAME_TRANSACTIONS';
export const GET_GAME_DRAWS = 'GET_GAME_DRAWS';
export const GET_GAME_NEW_DRAWS = 'GET_GAME_NEW_DRAWS';
export const DRAW_WINNERS = 'DRAW_WINNERS';
export const GET_FILTERED_WINNERS = 'GET_FILTERED_WINNERS';
export const GET_FILTERED_WINNERS_FAIL = 'GET_FILTERED_WINNERS_FAIL';
export const RESET_GAME_DATA = 'RESET_GAME_DATA';
export const GET_TICKET_TRANSACTION = 'GET_TICKET_TRANSACTION';

export const SET_INITIAL_DRAW_START = 'SET_INITIAL_DRAW_START';
export const SET_INITIAL_DRAW_SUCCESS = 'SET_INITIAL_DRAW_SUCCESS';
export const SET_INITIAL_DRAW_FAIL = 'SET_INITIAL_DRAW_FAIL';
//call card

export const GET_DATA_CALLCARD = 'GET_DATA_CALLCARD';
export const SEARCH_DATA_CALLCARD = 'SEARCH_DATA_CALLCARD';
export const ADD_ITEM_CALLCARD = 'ADD_ITEM_CALLCARD';
export const OPEN_EDIT_CALLCARD = 'OPEN_EDIT_CALLCARD';
export const EDIT_ITEM_CALLCARD = 'EDIT_ITEM_CALLCARD';
export const DELETE_ITEM_CALLCARD = 'DELETE_ITEM_CALLCARD';
export const GET_OPTIONS_CALLCARD = 'GET_OPTIONS_CALLCARD';
export const OPEN_PREVIEW_CALLCARD = 'OPEN_PREVIEW_CALLCARD';
export const RESET_DATA_CALLCARD = 'RESET_DATA_CALLCARD';
export const LOG_ACTION_CALLCARD = 'LOG_ACTION_CALLCARD';

//reports card

export const GET_DAILY_USER_CREDITS = 'GET_DAILY_USER_CREDITS';
export const GET_DAILY_USER_CREDITS_START = 'GET_DAILY_USER_CREDITS_START';
export const GET_DAILY_BETS_PRICE_START = 'GET_DAILY_BETS_PRICE_START';
export const GET_DAILY_BETS_PRICE = 'GET_DAILY_BETS_PRICE';
export const GENERATE_DAILY_WALLET_START = 'GENERATE_DAILY_WALLET_START';
export const GENERATE_DAILY_WALLET_COUNT = 'GENERATE_DAILY_WALLET_COUNT';
export const GET_DAILY_BETS_COUNT = 'GET_DAILY_BETS_COUNT';
export const GET_DAILY_BETS_COUNT_START = 'GET_DAILY_BETS_COUNT_START';

export const GET_RANGED_USER_CREDITS = 'GET_RANGED_USER_CREDITS';
export const GET_RANGED_BETS_PRICE_START = 'GET_RANGED_BETS_PRICE_START';
export const GET_RANGED_BETS_PRICE = 'GET_RANGED_BETS_PRICE';
export const GENERATE_RANGED_WALLET_COUNT = 'GENERATE_RANGED_WALLET_COUNT';
export const GENERATE_RANGED_WALLET_START = 'GENERATE_RANGED_WALLET_START';
export const GET_RANGED_BETS_COUNT = 'GET_RANGED_BETS_COUNT';
export const GET_RANGED_BETS_COUNT_START = 'GET_RANGED_BETS_COUNT_START';
export const GET_RANGED_WALLET_IN = 'GET_RANGED_WALLET_IN';
export const GET_RANGED_WALLET_IN_START = 'GET_RANGED_WALLET_IN_START';
export const GET_STARTING_WALLET_IN = 'GET_STARTING_WALLET_IN';
export const GET_STARTING_WALLET_IN_START = 'GET_STARTING_WALLET_IN_START';
export const GET_STARTING_WALLET_OUT = 'GET_STARTING_WALLET_OUT';
export const GET_STARTING_WALLET_OUT_START = 'GET_STARTING_WALLET_OUT_START';

export const GET_ALL_BETS_PER_DRAW_ID_START = 'GET_ALL_BETS_PER_DRAW_ID_START';
export const GET_ALL_BETS_PER_DRAW_ID = 'GET_ALL_BETS_PER_DRAW_ID';
export const GET_DAILY_GCASH_PAYMENTS = 'GET_DAILY_GCASH_PAYMENTS';
export const GET_DAILY_PAYMAYA_PAYMENTS = 'GET_DAILY_PAYMAYA_PAYMENTS';
export const GET_DAILY_REPORTS = 'GET_DATA_CALLCARD';
export const GET_DAILY_GRABPAY_PAYMENTS = 'GET_DAILY_GRABPAY_PAYMENTS';

export const GET_WILL_DRAW_COUNT = 'GET_WILL_DRAW_COUNT';
export const GET_DAILY_UNDRAWN_COUNT = 'GET_DAILY_UNDRAWN_COUNT';

export const GET_ALL_BETS_PER_DRAW = 'GET_ALL_BETS_PER_DRAW';
export const GET_CURRENT_WALLET_PER_USER = 'GET_CURRENT_WALLET_PER_USER';
export const GET_ALL_WALLET_IN = 'GET_ALL_WALLET_IN';
export const GET_ALL_WALLET_OUT = 'GET_ALL_WALLET_OUT';
export const GET_GATEWAY_CHARGES = 'GET_GATEWAY_CHARGES';
export const GET_GATEWAY_CHARGES_START = 'GET_GATEWAY_CHARGES_START';
export const GET_RANGED_GATEWAY_CHARGES = 'GET_RANGED_GATEWAY_CHARGES';
export const GET_RANGED_GATEWAY_CHARGES_START =
  'GET_RANGED_GATEWAY_CHARGES_START';
export const RESET_REPORTS_DATA = 'RESET_REPORTS_DATA';

export const SET_DRAW_FILTER = 'SET_DRAW_FILTER';
