import React, { useState, useEffect } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, message } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { connect } from 'react-redux';
import * as actions from '../../auth/actions';
import moment from 'moment';

import * as reportActions from '../../actions/reportActions';
import './index.scss';
import { CSVLink } from 'react-csv';

const Reports = (props) => {
  const { RangePicker } = DatePicker;
  const { grabPayData } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [csvLink, setCsvLink] = useState(null);

  const [dailyCashData, setDailyCashData] = useState([]);
  const [isClicked, setClicked] = useState(null);

  const [betPerDrawCSV, setBetPerDrawCSV] = useState(null);
  const [betperDrawData, setBetPerDrawData] = useState([]);
  const [betClick, setBetClick] = useState(null);

  const [walletTrackingCSV, setWalletTrackingCSV] = useState(null);
  const [walletTrackingData, setWalletTrackingData] = useState([]);
  const [walletClick, setWalletClick] = useState(null);

  const [payoutCSV, setPayoutCSV] = useState(null);
  const [payoutFinalData, setPayoutData] = useState([]);
  const [payoutClick, setPayoutClick] = useState(null);

  const [managementCSV, setManagementCSV] = useState(null);
  const [managementFinalData, setManagementData] = useState([]);
  const [managementClick, setManagementClick] = useState(null);

  const [rangedCSV, setRangedCSV] = useState(null);
  const [rangedFinalData, setRangedFinalData] = useState([]);
  const [rangedClick, setRangedClick] = useState(null);

  const [rangedPayoutCsv, setRangedPayoutCsv] = useState(null);
  const [rangedPayoutData, setRangedPayoutData] = useState([]);
  const [rangedPayoutClick, setRangedPayoutClick] = useState(null);

  const [rangedDates, setRangedDates] = useState([]);
  var daily_betCounter = 0;
  var daily_transactionCounter = 0;
  var ranged_betCounter = 0;
  var max_bet_counter = 0;
  var final_max_bet_counter = 0;
  let created_at = [];
  useEffect(() => {
    if (props.dailyTransactionsData.length > 0) {
      props.dailyTransactionsData.forEach((entry) => {
        if (daily_betCounter === 0)
          theDATA.splice(
            19 + daily_transactionCounter + 3 + daily_betCounter,
            1,
            entry
          );
        else
          theDATA.splice(
            19 + daily_transactionCounter + 4 + daily_betCounter,
            0,
            entry
          );
        daily_betCounter += 1;
      });
    }

    if (
      props.dailyTransactionsData.length !== 0 &&
      props.betsData.length !== 0 &&
      props.dailyWalletCount.length !== 0 &&
      props.startingWalletIn.length !== 0 &&
      props.startingWalletOut.length !== 0 &&
      props.userData !== 0
    ) {
      theDATA[18].push(props.startingWalletOut[0].total_bets_amount);
      theDATA[19].push(
        props.startingWalletIn.total_amount[0] -
          props.startingWalletOut[0].total_bets_amount
      );

      if (!isClicked) {
        setDailyCashData(theDATA);
        exportData();
        setClicked(true);
      }
    }

    if (props.gatewayCharges.length !== 0 && payoutClick) {
      setPayoutClick(false);

      setPayoutData(payoutData);
      exportPayoutData();
    }
    if (props.betsPerDraw.length !== 0 && betClick) {
      setBetClick(false);
      props.betsPerDraw.forEach((entry) => {
        betdata.push(entry);
      });
      setBetPerDrawData(betdata);
      exportBetData();
    }

    if (
      props.walletIn.length !== 0 &&
      props.walletOut.length !== 0 &&
      props.currentWallet.length !== 0 &&
      walletClick
    ) {
      setWalletClick(false);
      var o2;
      var finalWalletTransactions = [];
      props.walletIn.forEach(function (o1) {
        const found = props.walletOut.some((el) => {
          if (el[0] === o1[0]) {
            o2 = el;
            return el;
          }
        });

        if (found) {
          finalWalletTransactions.push([
            o2[0],
            o1[1],
            parseFloat(o2[1]),
            o1[1] - parseFloat(o2[1]),
          ]);
        } else {
          finalWalletTransactions.push([o1[0], o1[1], 0, o1[1]]);
        }
      });
      console.log(finalWalletTransactions);

      var totalCurrent = 0;
      var totalCashedIn = 0;
      var totalCashedOut = 0;
      props.currentWallet.forEach(function (o1) {
        var test = finalWalletTransactions.find((o2) => {
          if (o1[0] === o2[0] && o1[1] === o2[1]) {
            return o2;
          } else if (o1[0] === o2[0] && o1[1] !== o2[1]) return o2;
        });
        totalCurrent += o1[1];

        if (test === undefined) walletData.push([o1[0], o1[1], 0, 0, 'TRUE']);
        else if (test[3] === o1[1]) {
          totalCashedIn += test[1];
          totalCashedOut += test[2];
          walletData.push([o1[0], o1[1], test[1], test[2], 'TRUE']);
        } else if (test[3] !== o1[1]) {
          totalCashedIn += test[1];
          totalCashedOut += test[2];
          walletData.push([o1[0], o1[1], test[1], test[2], 'FALSE']);
        }
      });

      walletData.push(['Total', totalCurrent, totalCashedIn, totalCashedOut]);

      setWalletTrackingData(walletData);
      exportWalletData();
    }

    if (
      rangedClick &&
      props.rangedBetData.length !== 0 &&
      props.rangedTransactionsData.length !== 0 &&
      props.rangedUserData.length !== 0 &&
      props.rangedWalletCount.length !== 0 &&
      props.startingWalletIn.length !== 0 &&
      props.startingWalletOut.length !== 0
    ) {
      var i;
      var totalUserWallet = props.rangedUserData[0];
      var totalGCash = 0;
      var totalGrab = 0;
      var totalPaymaya = 0;
      var totalReceipts = 0;
      var totalBets = 0;
      var totalAdvanceBets = 0;

      var totalNet = 0;
      var totalOutStanding = 0;
      var totalStartIn = 0;
      var totalStartOut = 0;
      for (i = 0; i < props.rangedUserData.length; i++) {
        if (i === 0) rangedData[2].push('Date');

        rangedData[2].push(moment(rangedDates[i]).format('dddd, MMMM DD YYYY'));
        rangedData[2].push(null);
        rangedData[2].push(null);
        if (i === 0) rangedData[3].push('Total User Wallet');
        rangedData[3].push(props.rangedUserData[i]);
        rangedData[3].push(null);
        rangedData[3].push(null);
        if (i === 0) rangedData[4].push('Add');
        rangedData[4].push(null);
        rangedData[4].push(null);

        rangedData[4].push(null);
      }

      for (i = 0; i < props.rangedWalletCount.gcash_total_amount.length; i++) {
        if (i === 0) rangedData[5].push('GCash');
        rangedData[5].push(props.rangedWalletCount.gcash_total_amount[i]);
        rangedData[5].push(null);
        rangedData[5].push(null);
        totalGCash += props.rangedWalletCount.gcash_total_amount[i];
        if (i === 0) rangedData[6].push('GRABPAY');
        rangedData[6].push(props.rangedWalletCount.grab_total_amount[i]);
        rangedData[6].push(null);
        rangedData[6].push(null);
        totalGrab += props.rangedWalletCount.grab_total_amount[i];
        if (i === 0) rangedData[7].push('PAYMAYA');
        rangedData[7].push(props.rangedWalletCount.paymaya_total_coins[i]);
        rangedData[7].push(null);
        rangedData[7].push(null);
        totalPaymaya += props.rangedWalletCount.paymaya_total_coins[i];
        if (i === 0) rangedData[8].push('Total Receipts');
        console.log(
          props.rangedWalletCount.gcash_total_amount[i] +
            props.rangedWalletCount.grab_total_amount[i] +
            props.rangedWalletCount.paymaya_total_coins[i]
        );
        rangedData[8].push(
          props.rangedWalletCount.gcash_total_amount[i] +
            props.rangedWalletCount.grab_total_amount[i] +
            props.rangedWalletCount.paymaya_total_coins[i]
        );

        rangedData[8].push(null);
        rangedData[8].push(null);

        if (i === 0) rangedData[9].push('LESS');
        rangedData[9].push(null);
        rangedData[9].push(null);

        if (i === 0) rangedData[14].push('Net Receipts');
        rangedData[14].push(
          props.rangedWalletCount.gcash_total_amount[i] +
            props.rangedWalletCount.grab_total_amount[i] +
            props.rangedWalletCount.paymaya_total_coins[i] -
            props.rangedBetData[i].total_bets
        );
        rangedData[14].push(null);
        rangedData[14].push(null);
        totalNet +=
          props.rangedWalletCount.gcash_total_amount[i] +
          props.rangedWalletCount.grab_total_amount[i] +
          props.rangedWalletCount.paymaya_total_coins[i] -
          props.rangedBetData[i].total_bets;
        if (i === 0) rangedData[15].push('Total Outstanding Balance');
        rangedData[15].push(
          props.rangedUserData[i] -
            props.rangedBetData[i].total_bets +
            (props.rangedWalletCount.gcash_total_amount[i] +
              props.rangedWalletCount.grab_total_amount[i] +
              props.rangedWalletCount.paymaya_total_coins[i])
        );
        totalOutStanding +=
          props.rangedUserData[i] -
          props.rangedBetData[i].total_bets +
          (props.rangedWalletCount.gcash_total_amount[i] +
            props.rangedWalletCount.grab_total_amount[i] +
            props.rangedWalletCount.paymaya_total_coins[i]);

        rangedData[15].push(null);
        rangedData[15].push(null);
      }
      totalReceipts += totalGCash + totalPaymaya + totalGrab;

      for (i = 0; i < props.rangedBetData.length; i++) {
        if (i === 0) rangedData[10].push('Bet Counts For the Day');
        rangedData[10].push(props.rangedBetData[i].present_bets);
        rangedData[10].push(null);
        rangedData[10].push(null);
        totalBets += props.rangedBetData[i].present_bets;
        if (i === 0) rangedData[11].push('Advanced Bets Count');
        rangedData[11].push(props.rangedBetData[i].advanced_bets);
        rangedData[11].push(null);
        rangedData[11].push(null);
        totalAdvanceBets += props.rangedBetData[i].advanced_bets;
        if (i === 0) rangedData[12].push('Total Bets');
        rangedData[12].push(props.rangedBetData[i].total_bets);
        rangedData[12].push(null);
        rangedData[12].push(null);
      }

      for (i = 0; i < props.startingWalletIn.total_amount.length; i++) {
        rangedData[16].push(null);
        rangedData[16].push(null);
        if (i === 0) rangedData[17].push('Total Receipts to Date');
        rangedData[17].push(props.startingWalletIn.total_amount[i]);
        rangedData[17].push(null);
        rangedData[17].push(null);

        if (i === 0) rangedData[18].push('Total Bets to Date');
        rangedData[18].push(props.startingWalletOut[i].total_bets_amount);
        rangedData[18].push(null);
        rangedData[18].push(null);

        if (i === 0) rangedData[19].push('Net Receipts to Date');
        rangedData[19].push(
          props.startingWalletIn.total_amount[i] -
            props.startingWalletOut[i].total_bets_amount
        );
        rangedData[19].push(null);
        rangedData[19].push(null);
      }
      totalStartIn =
        props.startingWalletIn.total_amount[
          props.startingWalletIn.total_amount.length - 1
        ];
      totalStartOut +=
        props.startingWalletOut[props.startingWalletIn.total_amount.length - 1]
          .total_bets_amount;
      var longest = 0;
      var longestOrcs = [];
      props.rangedTransactionsData.forEach(function (orc) {
        if (orc.length > longest) {
          longestOrcs = [orc];
          longest = orc.length;
        } else if (orc.length == longest) {
          longestOrcs.push(orc);
        }
      });
      var toPad = longest - 64;

      for (i = 0; i < props.rangedTransactionsData.length; i++) {
        if (i === 0) rangedData[20].push('Retail Receipts For The Day');
        rangedData[20].push(null);

        if (i + 1 !== props.rangedTransactionsData.length)
          rangedData[21].push(['Draw Date'], ['Amount'], [null]);
        else rangedData[21].push(['Draw Date']);
        ranged_betCounter = 0;

        if (props.rangedTransactionsData[i].length === 0) {
          if (i === 0) {
            while (ranged_betCounter < longest) {
              if (ranged_betCounter === 0)
                rangedData.splice(22 + ranged_betCounter, 0, [
                  ['N/A'],
                  ['N/A'],
                  ['N/A'],
                ]);
              else
                rangedData.splice(22 + ranged_betCounter, 0, [
                  [null],
                  [null],
                  [null],
                ]);
              ranged_betCounter += 1;
            }
          } else {
            while (ranged_betCounter < longest) {
              if (ranged_betCounter === 0)
                rangedData[22 + ranged_betCounter].push(
                  ['N/A'],
                  ['N/A'],
                  ['N/A']
                );
              else
                rangedData[22 + ranged_betCounter].push([null], [null], [null]);
              ranged_betCounter += 1;
            }
          }
        } else {
          var min = props.rangedTransactionsData[i].length - 64;
          console.log(min, 'min');
          props.rangedTransactionsData[i].forEach((entry) => {
            var j;
            if (i === 0) {
              if (ranged_betCounter < min)
                rangedData.splice(22 + ranged_betCounter, 0, entry);
              else if (ranged_betCounter === min) {
                while (ranged_betCounter < toPad) {
                  rangedData.splice(22 + ranged_betCounter, 0, [
                    [null],
                    [null],
                    [null],
                  ]);
                  ranged_betCounter += 1;
                }
              } else if (min < 0 && ranged_betCounter === 0) {
                while (ranged_betCounter < toPad + 1) {
                  if (ranged_betCounter === 0)
                    rangedData.splice(22 + ranged_betCounter, 0, [
                      ['N/A'],
                      ['N/A'],
                      ['N/A'],
                    ]);
                  else
                    rangedData.splice(22 + ranged_betCounter, 0, [
                      [null],
                      [null],
                      [null],
                    ]);
                  ranged_betCounter += 1;
                }
                rangedData.splice(22 + ranged_betCounter, 0, entry);
              } else rangedData.splice(22 + ranged_betCounter, 0, entry);
            } else {
              if (ranged_betCounter < min)
                entry.forEach((item) =>
                  rangedData[22 + ranged_betCounter].push(item)
                );
              else if (ranged_betCounter === min) {
                while (ranged_betCounter < toPad) {
                  rangedData[22 + ranged_betCounter].push(
                    [null],
                    [null],
                    [null]
                  );
                  ranged_betCounter += 1;
                }
              } else if (min < 0 && ranged_betCounter === 0) {
                while (ranged_betCounter < toPad + 1) {
                  if (ranged_betCounter === 0)
                    rangedData[22 + ranged_betCounter].push(
                      ['N/A'],
                      ['N/A'],
                      ['N/A']
                    );
                  else
                    rangedData[22 + ranged_betCounter].push(
                      [null],
                      [null],
                      [null]
                    );
                  ranged_betCounter += 1;
                }
              } else
                entry.forEach((item) =>
                  rangedData[22 + ranged_betCounter].push(item)
                );
            }
            ranged_betCounter += 1;
            max_bet_counter = ranged_betCounter;
          });
        }
      }

      rangedData[2].push('Total');
      rangedData[3].push(totalUserWallet);
      rangedData[5].push(totalGCash);
      rangedData[6].push(totalGrab);
      rangedData[7].push(totalPaymaya);
      rangedData[8].push(totalReceipts);
      rangedData[10].push(totalBets);
      rangedData[11].push(totalAdvanceBets);
      rangedData[12].push(totalAdvanceBets + totalBets);
      rangedData[14].push(totalNet);
      rangedData[15].push(totalUserWallet + totalNet);
      rangedData[17].push(totalStartIn);
      rangedData[18].push(totalStartOut);
      rangedData[19].push(totalStartIn - totalStartOut);

      setRangedClick(false);
      setRangedFinalData(rangedData);
      exportRangedData();
    }

    if (
      rangedPayoutClick &&
      props.rangedGatewayCharges.length !== 0 &&
      props.rangedWalletCount.length !== 0
    ) {
      var totalBalance = 0;
      var totalGCash = 0;
      var totalGrab = 0;
      var totalPaymaya = 0;
      var totalGCashCharge = 0;
      var totalGrabCharge = 0;
      var totalPaymayaCharge = 0;
      var totalAll = 0;
      var totalCharge = 0;
      var totalNetPayout = 0;
      var totalNetPaymaya = 0;
      var totalNetPaymongo = 0;
      var totalPayout = 0;

      var i;
      totalBalance = props.rangedGatewayCharges.previous_total[0];

      for (i = 0; i < props.rangedWalletCount.gcash_total_amount.length; i++) {
        if (i === 0) finalRangedPayoutData[2].push('Date');
        else finalRangedPayoutData[2].push(null);
        finalRangedPayoutData[2].push(
          moment(rangedDates[i]).format('dddd, MMMM DD YYYY')
        );

        if (i == 0) finalRangedPayoutData[3].push('Beginning Balance');
        else finalRangedPayoutData[3].push(null);

        finalRangedPayoutData[3].push(
          props.rangedGatewayCharges.previous_total[i]
        );

        if (i == 0) finalRangedPayoutData[4].push('Add');
        else finalRangedPayoutData[4].push(null);

        if (i == 0) finalRangedPayoutData[5].push('Receipts');
        else finalRangedPayoutData[5].push(null);

        if (i == 0) finalRangedPayoutData[6].push('GCASH');
        else finalRangedPayoutData[6].push(null);
        finalRangedPayoutData[6].push(
          props.rangedWalletCount.gcash_total_amount[i]
        );
        totalGCash += props.rangedWalletCount.gcash_total_amount[i];

        if (i == 0) finalRangedPayoutData[7].push('GRABPAY');
        else finalRangedPayoutData[7].push(null);
        finalRangedPayoutData[7].push(
          props.rangedWalletCount.grab_total_amount[i]
        );
        totalGrab += props.rangedWalletCount.grab_total_amount[i];

        if (i == 0) finalRangedPayoutData[8].push('PAYMAYA');
        else finalRangedPayoutData[8].push(null);
        finalRangedPayoutData[8].push(
          props.rangedWalletCount.paymaya_total_coins[i]
        );
        totalPaymaya += props.rangedWalletCount.paymaya_total_coins[i];

        if (i == 0) finalRangedPayoutData[9].push('Total Receipts');
        else finalRangedPayoutData[9].push(null);

        if (i == 0) finalRangedPayoutData[10].push('Less');
        else finalRangedPayoutData[10].push(null);

        if (i == 0) finalRangedPayoutData[11].push('Charges');
        else finalRangedPayoutData[11].push(null);

        if (i == 0) finalRangedPayoutData[12].push('GCASH - 2%');
        else finalRangedPayoutData[12].push(null);
        finalRangedPayoutData[12].push(
          props.rangedGatewayCharges.gcash_total_charge[i]
        );
        totalGCashCharge += props.rangedGatewayCharges.gcash_total_charge[i];

        if (i == 0) finalRangedPayoutData[13].push('GRABPAY - 2%');
        else finalRangedPayoutData[13].push(null);
        finalRangedPayoutData[13].push(
          props.rangedGatewayCharges.grab_total_charge[i]
        );
        totalGrabCharge += props.rangedGatewayCharges.grab_total_charge[i];

        if (i == 0) finalRangedPayoutData[14].push('PAYMAYA');
        else finalRangedPayoutData[14].push(null);
        finalRangedPayoutData[14].push(
          props.rangedGatewayCharges.paymaya_total_charge[i]
        );
        totalPaymayaCharge +=
          props.rangedGatewayCharges.paymaya_total_charge[i];

        if (i == 0) finalRangedPayoutData[15].push('Total');
        else finalRangedPayoutData[15].push(null);
        finalRangedPayoutData[15].push(
          props.rangedGatewayCharges.gcash_total_charge[i] +
            props.rangedGatewayCharges.grab_total_charge[i] +
            props.rangedGatewayCharges.paymaya_total_charge[i]
        );

        totalCharge +=
          props.rangedGatewayCharges.gcash_total_charge[i] +
          props.rangedGatewayCharges.grab_total_charge[i] +
          props.rangedGatewayCharges.paymaya_total_charge[i];

        if (i == 0) finalRangedPayoutData[16].push('Net Payout for the day');
        else finalRangedPayoutData[16].push(null);
        finalRangedPayoutData[16].push(
          props.rangedWalletCount.gcash_total_amount[i] +
            props.rangedWalletCount.grab_total_amount[i] +
            props.rangedWalletCount.paymaya_total_coins[i] -
            (props.rangedGatewayCharges.gcash_total_charge[i] +
              props.rangedGatewayCharges.grab_total_charge[i] +
              props.rangedGatewayCharges.paymaya_total_charge[i])
        );

        totalNetPayout +=
          props.rangedWalletCount.gcash_total_amount[i] +
          props.rangedWalletCount.grab_total_amount[i] +
          props.rangedWalletCount.paymaya_total_coins[i] -
          (props.rangedGatewayCharges.gcash_total_charge[i] +
            props.rangedGatewayCharges.grab_total_charge[i] +
            props.rangedGatewayCharges.paymaya_total_charge[i]);

        if (i == 0) finalRangedPayoutData[17].push('Paymaya');
        else finalRangedPayoutData[17].push(null);
        finalRangedPayoutData[17].push(
          props.rangedWalletCount.paymaya_total_coins[i] -
            props.rangedGatewayCharges.paymaya_total_charge[i]
        );

        totalNetPaymaya +=
          props.rangedWalletCount.paymaya_total_coins[i] -
          props.rangedGatewayCharges.paymaya_total_charge[i];
        if (i == 0) finalRangedPayoutData[18].push('Paymongo');
        else finalRangedPayoutData[18].push(null);
        finalRangedPayoutData[18].push(
          props.rangedWalletCount.gcash_total_amount[i] +
            props.rangedWalletCount.grab_total_amount[i] -
            (props.rangedGatewayCharges.gcash_total_charge[i] +
              props.rangedGatewayCharges.grab_total_charge[i])
        );

        totalNetPaymongo +=
          props.rangedWalletCount.gcash_total_amount[i] +
          props.rangedWalletCount.grab_total_amount[i] -
          (props.rangedGatewayCharges.gcash_total_charge[i] +
            props.rangedGatewayCharges.grab_total_charge[i]);

        if (i == 0) finalRangedPayoutData[19].push('Total payout balance');
        else finalRangedPayoutData[19].push(null);
        finalRangedPayoutData[19].push(
          props.rangedGatewayCharges.previous_total[i] +
            (props.rangedWalletCount.gcash_total_amount[i] +
              props.rangedWalletCount.grab_total_amount[i] +
              props.rangedWalletCount.paymaya_total_coins[i] -
              (props.rangedGatewayCharges.gcash_total_charge[i] +
                props.rangedGatewayCharges.grab_total_charge[i] +
                props.rangedGatewayCharges.paymaya_total_charge[i]))
        );
      }

      totalPayout =
        props.rangedGatewayCharges.previous_total[
          props.rangedGatewayCharges.previous_total.length - 1
        ] +
        (props.rangedWalletCount.gcash_total_amount[
          props.rangedGatewayCharges.previous_total.length - 1
        ] +
          props.rangedWalletCount.grab_total_amount[
            props.rangedGatewayCharges.previous_total.length - 1
          ] +
          props.rangedWalletCount.paymaya_total_coins[
            props.rangedGatewayCharges.previous_total.length - 1
          ] -
          (props.rangedGatewayCharges.gcash_total_charge[
            props.rangedGatewayCharges.previous_total.length - 1
          ] +
            props.rangedGatewayCharges.grab_total_charge[
              props.rangedGatewayCharges.previous_total.length - 1
            ] +
            props.rangedGatewayCharges.paymaya_total_charge[
              props.rangedGatewayCharges.previous_total.length - 1
            ]));

      finalRangedPayoutData[2].push('Total');
      finalRangedPayoutData[3].push(totalBalance);
      finalRangedPayoutData[6].push(totalGCash);
      finalRangedPayoutData[7].push(totalGrab);
      finalRangedPayoutData[8].push(totalPaymaya);
      finalRangedPayoutData[12].push(totalGCashCharge);
      finalRangedPayoutData[13].push(totalGrabCharge);
      finalRangedPayoutData[14].push(totalPaymayaCharge);
      finalRangedPayoutData[15].push(totalCharge);
      finalRangedPayoutData[16].push(totalNetPayout);
      finalRangedPayoutData[17].push(totalNetPaymaya);
      finalRangedPayoutData[18].push(totalNetPaymongo);
      finalRangedPayoutData[19].push(totalPayout);

      setRangedPayoutClick(false);
      setRangedPayoutData(finalRangedPayoutData);
      exportRangedPayoutData();
    }
  }, [
    props.userData,
    props.betsData,
    props.dailyTransactionsData,
    props.betsPerDraw,
    props.walletIn,
    props.walletOut,
    props.currentWallet,
    props.gatewayCharges,
    props.dailyWalletCount,
    props.rangedBetData,
    props.rangedTransactionsData,
    props.rangedUserData,
    props.rangedWalletCount,
    props.rangedGatewayCharges,
    props.startingWalletIn,
    props.startingWalletOut,
  ]);

  var theDATA = [
    ['SALES REPORT AND WALLET (SW)'],
    ['Daily User Wallet', moment(new Date()).format('LLLL')],
    ['Date', moment(selectedDate).format('dddd, MMMM DD YYYY')],
    ['Total User wallet', props.userData],
    ['Add'],
    ['GCASH', props.dailyWalletCount.gcash_total_amount],
    ['GRAPBAY', props.dailyWalletCount.grab_total_amount],
    ['PAYMAYA', props.dailyWalletCount.paymaya_total_coins],
    [
      'Total Receipts',
      props.dailyWalletCount.gcash_total_amount +
        props.dailyWalletCount.grab_total_amount +
        props.dailyWalletCount.paymaya_total_coins,
    ],
    ['Less'],
    ['Bet Counts for the day', props.betsData.present_bets], // bets for the day
    ['Advanced Bets count', props.betsData.advanced_bets], // advance bets within the day
    ['Total Bets   ', props.betsData.total_bets],
    [],
    [
      'Net Receipts',
      props.dailyWalletCount.gcash_total_amount +
        props.dailyWalletCount.grab_total_amount +
        props.dailyWalletCount.paymaya_total_coins -
        props.betsData.total_bets,
    ],

    [
      'Total Outstanding Balance',

      props.userData -
        props.betsData.total_bets +
        (props.dailyWalletCount.gcash_total_amount +
          props.dailyWalletCount.grab_total_amount +
          props.dailyWalletCount.paymaya_total_coins),
    ],
    [],
    ['Total Receipts to Date', props.startingWalletIn.total_amount],
    ['Total Bets to Date'],
    ['Net Receipts to Date'],

    ['Retail Receipt Of the Day', ''],
    ['Draw Date', 'Amount'],
    ['N/A', 'N/A', 'N/A'],
  ];

  var rangedData = [
    ['SALES REPORT AND WALLET (SW)'],
    ['Daily User Wallet', moment(new Date()).format('LLLL')],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  var finalRangedPayoutData = [
    ['DAILY CASH FLOWS (CF)'],
    ['Payout Report', moment(new Date()).format('LLLL')],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  var payoutData = [
    ['DAILY CASH FLOWS (CF)'],
    ['Daily Payout Report', moment(new Date()).format('LLLL')],
    ['Date', moment(selectedDate).format('dddd, MMMM DD YYYY')],
    ['Beginning Balance', props.gatewayCharges.previous_total],
    ['Add'],
    ['Receipts'],
    ['GCASH', props.dailyWalletCount.gcash_total_amount],
    ['GRAPBAY', props.dailyWalletCount.grab_total_amount],
    ['PAYMAYA', props.dailyWalletCount.paymaya_total_coins],
    ['Total Receipts'],
    ['Less'],
    ['Charges'],
    ['GCASH - 2%', props.gatewayCharges.gcash_total_charge],
    ['GRAPBAY - 2%', props.gatewayCharges.grab_total_charge],
    ['PAYMAYA ', props.gatewayCharges.paymaya_total_charge],
    [
      '',
      props.gatewayCharges.gcash_total_charge +
        props.gatewayCharges.grab_total_charge +
        props.gatewayCharges.paymaya_total_charge,
    ],
    [
      'Net Payout for the day',
      props.dailyWalletCount.gcash_total_amount +
        props.dailyWalletCount.grab_total_amount +
        props.dailyWalletCount.paymaya_total_coins -
        (props.gatewayCharges.gcash_total_charge +
          props.gatewayCharges.grab_total_charge +
          props.gatewayCharges.paymaya_total_charge),
    ],
    [
      'Paymaya',
      props.dailyWalletCount.paymaya_total_coins -
        props.gatewayCharges.paymaya_total_charge,
    ],
    [
      'Paymongo',
      props.dailyWalletCount.gcash_total_amount +
        props.dailyWalletCount.grab_total_amount -
        (props.gatewayCharges.gcash_total_charge +
          props.gatewayCharges.grab_total_charge),
    ],
    [
      'Total payout balance',

      props.gatewayCharges.previous_total +
        (props.dailyWalletCount.gcash_total_amount +
          props.dailyWalletCount.grab_total_amount +
          props.dailyWalletCount.paymaya_total_coins) -
        (props.gatewayCharges.gcash_total_charge +
          props.gatewayCharges.grab_total_charge +
          props.gatewayCharges.paymaya_total_charge),
    ],
  ];

  var betdata = [
    ['Total Bets Count Per Draw Date', moment(new Date()).format('LLLL')],
    ['Date', moment(new Date()).format('LLLL')],
    ['Draw Date', 'Bets Count', 'Number of Winners'],
  ];

  var walletData = [
    ['User Coins Tracking', moment(new Date()).format('LLLL')],
    ['Date', moment(new Date()).format('LLLL')],
    [
      'User ID',
      'Current Wallet',
      'Total Cashed In',
      'Total Cashed Out',
      'Matched',
    ],
  ];

  const generateDailyReport = () => {
    if (selectedDate === null) {
      message.error('No Dates selected');
    } else {
      const payload = {
        token: props.token,
        date: moment(selectedDate).format('MMDDYY'),
        created_at: moment(selectedDate).format('YYYY-MM-DD'),
      };

      props.getBetsPrice(payload);
      const paymongoPayload = {
        token: props.token,
        date: moment(selectedDate).format('YYYY-MM-DD'),
      };
      props.getDailyCredits(paymongoPayload);
      props.generateDailyWalletCount(paymongoPayload);
      props.getBetsDailyByTransaction(paymongoPayload);
      const walletPayload = {
        token: props.token,
        dates: [moment(selectedDate).format('YYYY-MM-DD')],
      };
      props.getStartingWalletIn(walletPayload);
      props.getStartingWalletOut(walletPayload);
      setClicked(false);
    }
  };

  const exportData = () => {
    setTimeout(
      function () {
        csvLink.link.click();
      }.bind(this),
      2000
    );
  };

  const exportBetData = () => {
    setTimeout(
      function () {
        betPerDrawCSV.link.click();
      }.bind(this),
      2000
    );
  };

  const exportRangedData = () => {
    setTimeout(
      function () {
        rangedCSV.link.click();
      }.bind(this),
      2000
    );
  };
  const exportRangedPayoutData = () => {
    setTimeout(
      function () {
        rangedPayoutCsv.link.click();
      }.bind(this),
      2000
    );
  };
  const exportWalletData = () => {
    setTimeout(
      function () {
        walletTrackingCSV.link.click();
      }.bind(this),
      2000
    );
  };

  const exportPayoutData = () => {
    setTimeout(
      function () {
        payoutCSV.link.click();
      }.bind(this),
      2000
    );
  };
  const generateBetPerDraw = () => {
    props.getAllBetsByDrawDate(props.token);
    setBetClick(true);
  };

  const generateWalletTraking = () => {
    props.getCurrentWalletPerUser(props.token);
    props.getWalletInTransactions(props.token);
    props.getWalletOutTransactions(props.token);
    setWalletClick(true);
  };

  const generatePayoutReport = () => {
    if (selectedDate === null) {
      message.error('No Dates selected');
    } else {
      const paymongoPayload = {
        token: props.token,
        date: moment(selectedDate).format('YYYY-MM-DD'),
      };
      props.getDailyCredits(paymongoPayload);
      props.generateDailyWalletCount(paymongoPayload);
      props.getGatewayCharges(paymongoPayload);
      setPayoutClick(true);
    }
  };

  const generateRangedPayoutReport = () => {
    if (selectedDateRange === null) {
      message.error('No Dates selected');
    } else {
      var dates = [];

      var i = 0;
      var startDate = moment(selectedDateRange[0], 'YYYY-MM-DD');
      while (!startDate.isAfter(moment(selectedDateRange[1], 'YYYY-MM-DD'))) {
        dates.push(moment(startDate).format('MMDDYY'));
        created_at.push(startDate.format('YYYY-MM-DD'));

        startDate = startDate.add(1, 'days');
      }

      const paymongoPayload = {
        token: props.token,
        dates: created_at,
      };

      props.generateRangeWalletCount(paymongoPayload);
      props.getRangedGatewayCharges(paymongoPayload);
      setRangedPayoutClick(true);
      setRangedDates(created_at);
    }
  };

  const generateRangedReport = () => {
    if (selectedDateRange === null) {
      message.error('No Dates selected');
    } else {
      var dates = [];

      var i = 0;
      var startDate = moment(selectedDateRange[0], 'YYYY-MM-DD');
      while (!startDate.isAfter(moment(selectedDateRange[1], 'YYYY-MM-DD'))) {
        dates.push(moment(startDate).format('MMDDYY'));
        created_at.push(startDate.format('YYYY-MM-DD'));

        startDate = startDate.add(1, 'days');
      }
      const payload = {
        token: props.token,
        dates: dates,
        created_at: created_at,
      };

      props.getBetsPriceRange(payload);
      const paymongoPayload = {
        token: props.token,
        dates: created_at,
      };
      props.getRangedCredits(paymongoPayload);
      props.generateRangeWalletCount(paymongoPayload);
      props.getBetsRangedByTransaction(paymongoPayload);
      props.getStartingWalletIn(paymongoPayload);
      props.getStartingWalletOut(paymongoPayload);
      setRangedClick(true);
      setRangedDates(created_at);
    }
  };

  return (
    <div className="reports-container">
      <div className="reports-text-row ">
        <a className="reports-text">REPORTS</a>
        <RangePicker
          onChange={(val) => {
            setSelectedDateRange(val);
          }}
          allowClear={true}
          disabledDate={(current) => {
            return current > moment().endOf('day');
          }}
          value={selectedDateRange}
        />
      </div>
      <div className="report-buttons-row">
        <div className="reports-button-container ">
          <CSVLink
            data={rangedFinalData}
            target="_blank"
            className="hiddencsv"
            filename={`Wallet Report.csv`}
            ref={(r) => setRangedCSV(r)}
          ></CSVLink>
          <Button onClick={generateRangedReport} className="button">
            <LegacyIcon type="file" /> Generate Wallet Report
          </Button>
        </div>
        <div className="reports-button-container ">
          <CSVLink
            data={rangedPayoutData}
            target="_blank"
            className="hiddencsv"
            filename={`Payout Report.csv`}
            ref={(r) => setRangedPayoutCsv(r)}
          ></CSVLink>
          <Button onClick={generateRangedPayoutReport} className="button">
            <LegacyIcon type="file" /> Generate Payout Report
          </Button>
        </div>
      </div>
      <div className="reports-text-row  report-with-margin-top">
        <a className="reports-text">DAILY REPORTS</a>
        <DatePicker
          onChange={(val) => {
            setSelectedDate(val);
          }}
          allowClear={true}
          disabledDate={(current) => {
            return current > moment().endOf('day');
          }}
          value={selectedDate}
        />
      </div>
      <div className="report-buttons-row ">
        <div className="reports-button-container ">
          <CSVLink
            data={dailyCashData}
            target="_blank"
            className="hiddencsv"
            filename={`daily_cash_report.csv`}
            ref={(r) => setCsvLink(r)}
          ></CSVLink>
          <Button onClick={generateDailyReport} className="button">
            <LegacyIcon type="file" /> Generate Daily Report
          </Button>
        </div>
        <div className="reports-button-container ">
          <CSVLink
            data={payoutFinalData}
            target="_blank"
            className="hiddencsv"
            filename={`Daily Payout.csv`}
            ref={(r) => setPayoutCSV(r)}
          ></CSVLink>
          <Button onClick={generatePayoutReport} className="button">
            <LegacyIcon type="file" /> Generate Daily Payout
          </Button>
        </div>
      </div>
      <div className="reports-text-row report-with-margin-top">
        <a className="reports-text">GENERAL REPORTS</a>
      </div>
      <div className="report-buttons-row ">
        <div className="reports-button-container ">
          <CSVLink
            data={betperDrawData}
            target="_blank"
            className="hiddencsv"
            filename={`bets_count_per_drawdate.csv`}
            ref={(r) => setBetPerDrawCSV(r)}
          ></CSVLink>
          <Button onClick={generateBetPerDraw} className="button">
            <LegacyIcon type="file" /> Generate Total Bets Count Per Draw Date
          </Button>
        </div>
        <div className="reports-button-container ">
          <CSVLink
            data={walletTrackingData}
            target="_blank"
            className="hiddencsv"
            filename={`User Wallet Tracking Data.csv`}
            ref={(r) => setWalletTrackingCSV(r)}
          ></CSVLink>
          <Button onClick={generateWalletTraking} className="button">
            <LegacyIcon type="file" /> Generate User Wallet Tracking Data
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.report_data.userData,
    betsData: state.report_data.betsData,
    dailyWalletCount: state.report_data.dailyWalletCount,
    dailyTransactionsData: state.report_data.dailyTransactionsData,
    rangedUserData: state.report_data.rangedUserData,
    rangedBetData: state.report_data.rangedBetData,
    rangedWalletCount: state.report_data.rangedWalletCount,
    rangedTransactionsData: state.report_data.rangedTransactionsData,
    betsPerDraw: state.report_data.betsPerDraw,
    gatewayCharges: state.report_data.gatewayCharges,
    currentWallet: state.report_data.currentWallet,
    walletIn: state.report_data.walletIn,
    walletOut: state.report_data.walletOut,
    rangedGatewayCharges: state.report_data.rangedGatewayCharges,
    startingWalletIn: state.report_data.startingWalletIn,
    startingWalletOut: state.report_data.startingWalletOut,

    token: state.auth.token,
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBetsPrice: (payload) => dispatch(reportActions.getBetsPrice(payload)),
    getDailyCredits: (payload) =>
      dispatch(reportActions.getDailyCredits(payload)),
    getGCashTransactions: (payload) =>
      dispatch(reportActions.getGCashTransactions(payload)),
    getPaymayaTransactions: (payload) =>
      dispatch(reportActions.getPaymayaTransactions(payload)),
    getGrabPayTransactions: (payload) =>
      dispatch(reportActions.getGrabPayTransactions(payload)),
    getBetsDailyByTransaction: (payload) =>
      dispatch(reportActions.getBetsDailyByTransaction(payload)),
    getUnDrawnCount: (payload) =>
      dispatch(reportActions.getUnDrawnCount(payload)),
    getDrawnTodayCount: (payload) =>
      dispatch(reportActions.getDrawnTodayCount(payload)),
    generateDailyWalletCount: (payload) =>
      dispatch(reportActions.generateDailyWalletCount(payload)),
    getGatewayCharges: (payload) =>
      dispatch(reportActions.getGatewayCharges(payload)),
    getAllBetsByDrawDate: (token) =>
      dispatch(reportActions.getAllBetsByDrawDate(token)),
    getCurrentWalletPerUser: (token) =>
      dispatch(reportActions.getCurrentWalletPerUser(token)),
    getWalletOutTransactions: (token) =>
      dispatch(reportActions.getWalletOutTransactions(token)),
    getWalletInTransactions: (token) =>
      dispatch(reportActions.getWalletInTransactions(token)),

    getRangedCredits: (payload) =>
      dispatch(reportActions.getRangedCredits(payload)),
    getBetsPriceRange: (payload) =>
      dispatch(reportActions.getBetsPriceRange(payload)),
    generateRangeWalletCount: (payload) =>
      dispatch(reportActions.generateRangeWalletCount(payload)),
    getBetsRangedByTransaction: (payload) =>
      dispatch(reportActions.getBetsRangedByTransaction(payload)),

    getStartingWalletOut: (payload) =>
      dispatch(reportActions.getStartingWalletOut(payload)),
    getStartingWalletIn: (payload) =>
      dispatch(reportActions.getStartingWalletIn(payload)),
    getRangedGatewayCharges: (payload) =>
      dispatch(reportActions.getRangedGatewayCharges(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
