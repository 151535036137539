import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  PageHeader,
  Pagination,
  Input,
  Button,
  Table,
  Spin,
  Switch,
} from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import './index.scss';
import VerifyModal from '../../components/VerifyModal';
import EditModal from '../../components/EditModal';

import PreviewDrawer from '../../components/PreviewDrawer';
import {
  getData_AUTH,
  searchData_AUTH,
  openEdit_AUTH,
  openPreview,
  checkAuthAccess,
} from '../../actions';

const { Search } = Input;
const INITIAL_STATE = {
  searchText: '',
};
const API_URL = 'userverify/';
const PAGENAME = 'User Verification';
const SCREEN_ID = '14';

const MODAL_BLUEPRINT = [
  {
    title: 'ID',
    key: 'user_id',
    type: 'dont',
  },
  {
    title: 'Name',
    key: 'name',
    type: 'text',
  },
  {
    title: 'Birthday',
    key: 'birth_date',
    type: 'text',
  },
  {
    title: 'Phone number',
    key: 'phone_number',
    type: 'text',
  },
  {
    title: 'email',
    key: 'email',
    type: 'text',
  },
  {
    title: 'id number',
    key: 'id_number',
    type: 'text',
  },
  {
    title: 'id type',
    key: 'id_type',
    type: 'text',
  },
  {
    title: 'checked',
    key: 'is_verified_checked',
    type: 'boolean',
  },
  {
    title: 'verified',
    key: 'is_verified',
    type: 'boolean',
  },
];
class userVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      data: [],
      sorter: null,
      filterArray: [],
      filters: null,
      page: 1,
      loading: true,
      searchText: null,
      offset: 0,
      pageSize: 10,
      defaultpageSize: 10,
      range: [1, 10],
      filterchecked: true,

      selectedUser: null,
      selectedUserVChecked: false,
      COLUMNS: [
        {
          title: 'id',
          dataIndex: 'user_id',
          key: 'user_id',
          sorter: (a, b) => a.user_id > b.user_id,
          ...this.getColumnSearchProps('user_id'), //add this to backend
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone number',
          dataIndex: 'phone_number',
          key: 'phone_number',
        },
        {
          title: 'id number',
          dataIndex: 'id_number',
          key: 'id_number',
        },
        {
          title: 'id type',
          dataIndex: 'id_type',
          key: 'id_type',
        },
        {
          title: 'checked',
          dataIndex: 'is_verified_checked',
          key: 'is_verified_checked',
          render: (row) => {
            var editItem = row;
            if (editItem === true) {
              return <LegacyIcon type="check" />;
            } else {
              return <LegacyIcon type="close" />;
            }
          },
        },
        {
          title: 'verified',
          dataIndex: 'is_verified',
          key: 'is_verified',
          render: (row) => {
            var editItem = row;
            if (editItem === true) {
              return <LegacyIcon type="check" />;
            } else {
              return <LegacyIcon type="close" />;
            }
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getTableData(this.state.defaultpageSize, 0, null, null, null);
    this.checkAccess(SCREEN_ID);
  }
  checkAccess(screen) {
    if (this.props.user_admin) {
      Object.entries(this.props.screenActions).map(([key, value]) => {
        var name = value + screen;
        this.setState({ [name]: true });
      });
    } else {
      var a = this.props
        .checkAuthAccess(this.props.user_role, screen, 'read', this.props.token)
        .then((res) => {
          var permission = res.data.results[0];

          Object.entries(this.props.screenActions).map(([key, value]) => {
            var name = value + screen;

            if (permission[value]) {
              this.setState({ [name]: true });
            } else {
              this.setState({ [name]: false });
            }
          });
        })
        .catch((err) => {
          Object.entries(this.props.screenActions).map(([key, value]) => {
            var name = value + screen;
            this.setState({ [name]: false });
          });
        });
    }
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data });

      if (this.props.data.length > 0) {
        this.setState({ loading: false });
      } else {
        setTimeout(
          function () {
            //Start the timer
            this.setState({ loading: false }); //After 1 second, set render to true
          }.bind(this),
          1000
        );
      }
    }
  }

  getTableData(limit, offset, sort, filter, search) {
    this.setState({ loading: true });
    if (this.props.token) {
      var limitoffset = `?limit=${limit}&offset=${offset}`;
      var controllerText = limitoffset;
      var searchText = '';
      if (search !== null) {
        searchText = `&search=${search}`;
        controllerText = controllerText + searchText;
      }
      var filterText = '';
      if (filter !== null) {
        filterText = filter;
        controllerText = controllerText + filterText;
      }
      var sortText = '';
      if (sort !== null) {
        sortText = sort;
        controllerText = controllerText + sortText;
      }
      if (this.state.filterchecked) {
        controllerText = controllerText + '&is_verified_checked=false';
      }
      this.props.getData_AUTH(this.props.token, API_URL, controllerText);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.handleSorter(sorter);
  };

  handleSorter(sorter) {
    if (this.state.sorter !== sorter) {
      var sorterValue = null;
      //console.log(sorter)
      if (sorter.order === 'ascend') {
        //console.log('ASC',sorter.columnKey )
        sorterValue = `&ordering=${sorter.columnKey}`;
        this.setState({ sorter: sorterValue });
        this.getTableData(
          this.state.pageSize,
          this.state.offset,
          sorterValue,
          this.state.filters,
          this.state.searchText
        );
      } else if (sorter.order === 'descend') {
        //console.log('DCS',sorter.columnKey)
        sorterValue = `&ordering=-${sorter.columnKey}`;
        this.setState({ sorter: sorterValue });
        this.getTableData(
          this.state.pageSize,
          this.state.offset,
          sorterValue,
          this.state.filters,
          this.state.searchText
        );
      } else if (sorter.order === undefined) {
        sorterValue = null;
        this.setState({ sorter: sorterValue });
      }
    }
  }

  onSearch(value) {
    var searchval = value.toString();
    this.setState({ searchText: searchval, page: 1 });
    if (searchval) {
      this.getTableData(this.state.defaultpageSize, 0, null, null, searchval);
    } else {
      this.getTableData(
        this.state.defaultpageSize,
        0,
        null,
        this.state.filters,
        null
      );
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText, this.state.filterText]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.addtoFilterArray(selectedKeys[0], dataIndex);
    this.setState({ filterText: selectedKeys[0] });
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();

    this.removeFilterArray(dataIndex);
    this.setState({ filterText: '' });
  };
  generateFilterController(currentArray) {
    var filter = '';
    Object.entries(currentArray).map(([key, value]) => {
      filter = filter + `&${value.dataIndex}=${value.value}`;
    });
    this.setState({ filters: filter });
    this.getTableData(
      this.state.defaultpageSize,
      0,
      null,
      filter,
      this.state.searchText
    );
  }

  addtoFilterArray(value, dataIndex) {
    //console.log(value,dataIndex)
    var newItem = {
      value: value,
      dataIndex: dataIndex,
    };
    var i;
    for (i = 0; i < this.state.filterArray.length; i++) {
      if (this.state.filterArray[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray;
        newArray[i] = newItem;
        return this.setState(
          { filterArray: newArray },
          this.generateFilterController(newArray)
        );
      }
    }
    return this.setState(
      { filterArray: this.state.filterArray.concat(newItem) },
      this.generateFilterController(this.state.filterArray.concat(newItem))
    );
  }

  removeFilterArray(dataIndex) {
    var i;
    for (i = 0; i < this.state.filterArray.length; i++) {
      if (this.state.filterArray[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray;
        newArray.splice(i, 1);
        return this.setState(
          { filterArray: newArray },
          this.generateFilterController(newArray)
        );
      }
    }
    return this.generateFilterController(this.state.filterArray);
  }

  resetTable() {
    this.getTableData(this.state.defaultpageSize, 0, null, null, null);
    //console.log(this.state)
    this.setState({
      defaultpageSize: 10,
      pageSize: 10,
      range: [1, 10],
      page: 1,
      filterArray: [],
      filters: null,
      filterText: null,
      searchText: null,
      sorter: null,
      loading: true,
    });
  }
  openEditModal(record) {
    if (record && record !== undefined) {
      this.props.openEdit_AUTH(record, true);
    }
  }

  onChangePage(page, pageSize) {
    if (this.state.loading == false) {
      this.setState({
        page: page,
      });
    }
  }

  onChangePageSize(currentPage, pageSize) {
    if (this.state.pageSize !== pageSize && this.state.loading == false) {
      this.setState({
        pageSize: pageSize,
        page: 1,
        offset: 0,
      });
      this.getTableData(
        pageSize,
        0,
        this.state.sorter,
        this.state.filters,
        this.state.searchText
      );
    }
  }

  onChangeRange(total, range) {
    if (this.state.range[0] !== range[0] || this.state.range[1] !== range[1]) {
      var offset = range[0] - 1;
      this.setState({ range: range });
      this.getTableData(
        this.state.pageSize,
        offset,
        this.state.sorter,
        this.state.filters,
        this.state.searchText
      );
    }
  }

  togglechecked() {
    this.setState({ filterchecked: !this.state.filterchecked }, () => {
      this.getTableData(this.state.defaultpageSize, 0, null, null, null);
    });
  }
  columnfilter() {
    var filteredColumn = this.state.COLUMNS.slice();
    const editpart = [
      // {
      //     title:'Edit',
      //     render: row =>{
      //         var editItem = row
      //         return (
      //             <div onClick={(e)=>{e.stopPropagation();this.openEditModal(editItem);  }}>
      //                 <LegacyIcon type="edit" />
      //             </div>
      //         );
      //     }
      // }
    ];
    if (this.state[`update${SCREEN_ID}`]) {
      filteredColumn = filteredColumn.concat(editpart);
    }
    return filteredColumn;
  }

  render() {
    let { sorter } = this.state;
    sorter = sorter || {};
    if (this.state[`read${SCREEN_ID}`]) {
      return (
        <>
          <div className="generic-container">
            <PageHeader
              title={PAGENAME + 's'}
              subTitle=""
              extra={
                <Search
                  className="search"
                  placeholder="search"
                  allowClear={true}
                  onSearch={(value) => {
                    this.onSearch(value);
                  }}
                />
              }
            >
              <div className="header">
                <Button
                  onClick={() => {
                    this.resetTable();
                  }}
                  className="button"
                >
                  <LegacyIcon type="reload" />
                </Button>
                {this.state.activate14 && this.state.deactivate14 ? (
                  <VerifyModal
                    selectedUserVChecked={this.state.selectedUserVChecked}
                    selectedUser={this.state.selectedUser}
                    clearSelectedUser={() => {
                      this.setState({
                        selectedUserVChecked: null,
                        selectedUser: null,
                      });
                    }}
                  />
                ) : null}
                <div className="item">
                  <Switch
                    checkedChildren="unchecked only"
                    checked={this.state.filterchecked}
                    unCheckedChildren="unchecked only"
                    onChange={() => this.togglechecked()}
                  />
                </div>
              </div>
            </PageHeader>
            <div className="content-container">
              <Table
                loading={this.state.loading}
                dataSource={this.state.data}
                columns={this.columnfilter()}
                sorter={true}
                pagination={{
                  onChange: (page, pageSize) => {
                    this.onChangePage(page, pageSize);
                  },
                  showTotal: (total, range) => {
                    this.onChangeRange(total, range);
                  },
                  onShowSizeChange: (current, size) => {
                    this.onChangePageSize(current, size);
                  },
                  current: this.state.page,
                  showQuickJumper: true,
                  defaultPageSize: this.state.defaultpageSize,
                  pageSizeOptions: [
                    this.state.defaultpageSize,
                    '20',
                    '50',
                    '100',
                  ],
                  showSizeChanger: true,
                  total: this.props.dataCount,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedUser: record.user_id,
                        selectedUserVChecked: record.is_verified_checked,
                      });
                    }, // click row
                  };
                }}
                onChange={this.handleTableChange}
              />
            </div>
          </div>

          <PreviewDrawer blueprint={MODAL_BLUEPRINT} />
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.auth_data.data,
    dataCount: state.auth_data.dataCount,
    dataNext: state.auth_data.dataNext,
    dataPrevious: state.auth_data.dataPrevious,

    user_id: state.auth.user.user_id,
    user_role: state.auth.user.user_role,
    user_admin: state.auth.user.admin,
    screenActions: state.auth.screenActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData_AUTH: (token, apiurl, contoller) =>
      dispatch(getData_AUTH(token, apiurl, contoller)),
    searchData_AUTH: (filter, data) => dispatch(searchData_AUTH(filter, data)),
    openEdit_AUTH: (item, editModalOpen) =>
      dispatch(openEdit_AUTH(item, editModalOpen)),
    openPreview: (item, previewModalOpen) =>
      dispatch(openPreview(item, previewModalOpen)),
    checkAuthAccess: (user_role, screen, action, token) =>
      dispatch(checkAuthAccess(user_role, screen, action, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(userVerification);
