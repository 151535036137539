import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  PageHeader,
  Modal,
  Pagination,
  Input,
  Button,
  Table,
  Select,
  Spin,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
} from 'antd';

import { connect } from 'react-redux';
import moment from 'moment';

import { DownloadOutlined } from '@ant-design/icons';
import './index.scss';
import qr from 'qr-image-color';
import {
  getData_AUTH,
  addItem_AUTH,
  getOptions_AUTH,
  logAction,
  userVerify_AUTH,
} from '../../../../actions';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  BlobProvider,
  pdf,
  Image,
} from '@react-pdf/renderer';
import 'antd/dist/antd.css';

const { Option } = Select;
const { confirm } = Modal;

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    flexDirection: 'row',
  },
  section1: {
    flex: 6,
  },
  section2: {
    flex: 4,
  },
  text: {
    fontSize: 14,
    margin: 12,
  },
  qr: {
    height: 120,
    width: 120,
    right: 0,
  },
  image: {
    height: 200,
    width: 200,
    backgroundColor: '#eee',
    objectFit: 'contain',
    margin: 12,
  },
  imageBig: {
    height: 270,
    width: 270,
    backgroundColor: '#eee',
    objectFit: 'contain',
    margin: 10,
  },
});

class ExportWinnertoPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      selectedUser: null,
      blobURL: null,
      pdf: null,
    };
    this.clickPDF = React.createRef();
  }

  renderPage(userdata, user) {
    const svgStr = qr.imageSync(this.props.record.ref_num, {
      type: 'png',
      text: '',
      ecLevel: 'M', // Error correction level
      size: 5, // Size of one module in pixels
      margin: 2, // White space around QR image in modules
      transparent: false,
      background: undefined, // Color for code background
      color: '#000', //  Color for code blocks
    });
    console.log(`data:image/png;base64,${svgStr.toString('base64')}`);
    var url = `data:image/png;base64,${svgStr.toString('base64')}`;
    return (
      <Document>
        <Page size="LETTER" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.section1}>
              <Text
                style={styles.text}
              >{`ref#: ${this.props.record.ref_num.toString()}`}</Text>
              <Text
                style={styles.text}
              >{`id#: ${this.props.record.user_id.toString()}`}</Text>
              <Text style={styles.text}>{`name: ${userdata.name}`}</Text>
              <Text style={styles.text}>{`email: ${user.email}`}</Text>
              <Text
                style={styles.text}
              >{`birth date: ${userdata.birth_date}`}</Text>
              <Text
                style={styles.text}
              >{`id number: ${userdata.id_number}`}</Text>
              <Text style={styles.text}>{`id type: ${userdata.id_type}`}</Text>
              <Text
                style={styles.text}
              >{`verified: ${userdata.is_verified_checked}`}</Text>
              <Text
                style={styles.text}
              >{`phone num: ${user.phone_number}`}</Text>
              <Text
                style={styles.text}
              >{`prize: PHP ${this.props.record.prize.toString()}`}</Text>
            </View>
            <View style={styles.section2}>
              <Image style={styles.image} src={userdata.id_photo + ''} />
              <Image style={styles.qr} src={url} />
            </View>
          </View>
          <View style={styles.section}>
            <Image style={styles.imageBig} src={userdata.selfie_photo} />
            <Image style={styles.imageBig} src={userdata.selfie_verification} />
          </View>
        </Page>
      </Document>
    );
  }

  getSpecificUser(user_id) {
    console.log('calling user_id', user_id);
    var a = null;
    this.props
      .getOptions_AUTH(
        this.props.token,
        'userverifyitem',
        `?user_id=${user_id}`
      )
      .then((res) => {
        var userdata = res.data.user_verify;
        var user = res.data.user;

        console.log(userdata);
        this.setState({
          visible: true,
          loading: false,
          user,
        });

        a = this.renderPage(userdata, user);
        this.setState({ pdf: a });
      })
      .catch((err) => {
        console.log(err);
        message.error('an error has  occured');
        this.setState({ loading: false });
        this.setState({ selectedUser: null });

        a = (
          <Document>
            <Page size="LETTER" style={styles.page}>
              <View style={styles.section}>
                <Text>ERROR</Text>
              </View>
            </Page>
          </Document>
        );

        this.setState({ pdf: a });
      });
    return a;
  }

  getPdfDocument() {
    return this.state.pdf;
  }

  render() {
    return (
      <>
        {this.state.pdf ? (
          <PDFDownloadLink
            document={this.getPdfDocument()}
            fileName={`${this.props.record.ref_num}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button type="primary" loading={loading} className="add-button">
                <DownloadOutlined />
                Download Now!
              </Button>
            )}
          </PDFDownloadLink>
        ) : (
          <Button
            type="dashed"
            onClick={() => {
              this.getSpecificUser(this.props.user_id);
            }}
            loading={this.state.loading}
            className="add-button"
          >
            <DownloadOutlined />
            Generate PDF
          </Button>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData_AUTH: (token, apiurl, contoller) =>
      dispatch(getData_AUTH(token, apiurl, contoller)),
    addItem_AUTH: (data, apiurl, token) =>
      dispatch(addItem_AUTH(data, apiurl, token)),
    getOptions_AUTH: (token, apiurl, controller) =>
      dispatch(getOptions_AUTH(token, apiurl, controller)),
    logAction: (data, token) => dispatch(logAction(data, token)),
    userVerify_AUTH: (verify, id, token) =>
      dispatch(userVerify_AUTH(verify, id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportWinnertoPDF);

// birth_date: "1964-01-31"
// email: null
// id: 38
// id_number: "11234"
// id_photo: "https://pcso.s3.amazonaws.com/staging-verification/fb5f49f7-b37d-4551-b308-d1134769a64a_id_photo.jpg"
// id_type: 1
// is_verified_checked: false
// name: "Micoh  Alvarez "
// phone_number: null
// selfie_photo: "https://pcso.s3.amazonaws.com/staging-verification/fb5f49f7-b37d-4551-b308-d1134769a64a_selfie_photo.jpg"
// selfie_verification: "https://pcso.s3.amazonaws.com/staging-verification/fb5f49f7-b37d-4551-b308-d1134769a64a_selfie_verification.jpg"
// user: "fb5f49f7-b37d-4551-b308-d1134769a64a"
