import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  PageHeader,
  Modal,
  Pagination,
  Input,
  Button,
  Table,
  Select,
  Spin,
  Switch,
  message,
  Popover,
  List,
  Cascader,
  InputNumber,
  Statistic,
  Popconfirm,
  DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import './index.scss';

import {
  getData,
  editItem,
  deleteItem,
  openEdit,
  getOptions,
  logAction,
  checkAuthAccess,
} from '../../actions';
import 'antd/dist/antd.css';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

class EditModal extends Component {
  state = {
    loading: false,
    visible: false,
  };

  componentDidUpdate() {
    if (this.state.visible !== this.props.editModalOpen) {
      this.setState({
        visible: this.props.editModalOpen,

        ...this.props.item,
      });
      this.checkAccess(this.props.screen_id);
    }
  }

  checkAccess(screen) {
    if (this.props.user_admin) {
      Object.entries(this.props.screenActions).map(([key, value]) => {
        var name = value + screen;
        this.setState({ [name]: true });
      });
    } else {
      var a = this.props
        .checkAuthAccess(this.props.user_role, screen, 'read', this.props.token)
        .then((res) => {
          var permission = res.data.results[0];
          Object.entries(this.props.screenActions).map(([key, value]) => {
            var name = value + screen;
            if (permission[value]) {
              this.setState({ [name]: true });
            } else {
              this.setState({ [name]: false });
            }
          });
        })
        .catch((err) => {
          Object.entries(this.props.screenActions).map(([key, value]) => {
            var name = value + screen;
            this.setState({ [name]: false });
          });
        });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onClose() {}

  submitConfirm() {
    confirm({
      title: 'Are you sure want to edit this item?',
      content: 'please double check',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        this.handleSubmit();
      },
      onCancel() {},
    });
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    var submitObject = {};
    var id = '';
    Object.entries(this.props.blueprint).map(([key, value]) => {
      if (key === '0') {
        id = this.state[value.key];
      } else if (value.key !== 'last_login') {
        submitObject[value.key] = this.state[value.key];
      }
    });
    this.props
      .editItem(submitObject, this.props.apiurl, id, this.props.token)
      .then((res) => {
        var log = {
          user_id: this.props.user_id,
          action: `put ${this.props.headername} - ${id}`,
        };
        this.props
          .logAction(log, this.props.token)
          .then(() => {
            this.setState({
              loading: false,
              visible: false,
            });

            if (this.props.token) {
              this.props.getData(
                this.props.token,
                this.props.apiurl,
                `?limit=${6}&offset=0`
              );
            }
            this.props.openEdit(null, false);
          })
          .catch((err) => {
            message.error(err.message);
            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        Object.entries(err.response.data).map(([key, value]) => {
          message.error(key + ': ' + value);
        });
        this.setState({
          loading: false,
        });
      });
  };

  deleteConfirm() {
    confirm({
      title: 'Are you sure want to delete this item?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        this.handleDelete();
      },
      onCancel() {},
    });
  }

  handleDelete = () => {
    this.setState({ loading: true });
    var submitObject = {};
    var id = '';
    Object.entries(this.props.blueprint).map(([key, value]) => {
      if (key === '0') {
        id = this.state[value.key];
      } else if (value.key !== 'date') {
        submitObject[value.key] = this.state[value.key];
      }
    });

    this.props
      .deleteItem(this.props.apiurl, id, this.props.token)
      .then((res) => {
        var log = {
          user_id: this.props.user_id,
          action: `delete ${this.props.headername} - ${id}`,
        };
        this.props
          .logAction(log, this.props.token)
          .then(() => {
            this.setState({
              loading: false,
              visible: false,
            });

            if (this.props.token) {
              this.props.getData(
                this.props.token,
                this.props.apiurl,
                `?limit=${6}&offset=0`
              );
            }
            this.props.openEdit(null, false);
          })
          .catch((err) => {
            message.error(err.message);
            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        message.error(err.message);
        this.setState({
          loading: false,
        });
      });
  };

  handleCancel = () => {
    if (!this.state.loading) {
      this.setState({ visible: false });
      this.props.openEdit(null, false);
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeNumber = (key, value) => {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.setState({ [key]: value });
    }
  };

  getOptions(key, api, search) {
    var apiurl = api;
    var optionsName = key.split('_id')[0] + 'Options';
    var limitoffset = `?limit=${500}&offset=0`;
    var controllerText = limitoffset;
    var searchText = '';
    if (search !== null) {
      searchText = `&search=${search}`;
      controllerText = controllerText + searchText;
    }
    this.props
      .getOptions(this.props.token, apiurl, controllerText)
      .then((res) => {
        this.setState({ [optionsName]: res.data.results });
      });
  }
  renderOptions(key, api) {
    var optionsName = key.split('_id')[0] + 'Options';

    if (this.state[optionsName]) {
      var options = Object.entries(this.state[optionsName]).map(
        ([key, value]) => {
          var optionValue = value.id;
          var optionName = '';
          if (value.name) {
            optionName = value.name;
          } else if (value.game_name) {
            optionName = value.game_name;
          } else if (value.serial_number) {
            optionName = value.serial_number;
          }

          if (optionsName === 'vendorOptions') {
            if (value.is_supplier === true) {
              return <Option value={optionValue}>{optionName}</Option>;
            }
          } else {
            return <Option value={optionValue}>{optionName}</Option>;
          }
        }
      );
      return options;
    } else {
      this.getOptions(key, api, null);
    }
  }
  renderform() {
    var renderform = Object.entries(this.props.blueprint).map(
      ([key, value]) => {
        if (
          key !== '0' &&
          value.key !== 'created_at' &&
          value.key !== 'date' &&
          value.key !== 'last_login'
        ) {
          if (value.type === 'options') {
            var optionsName = value.key.split('_id')[0] + 'Options';
            var found = null;
            if (
              this.state[optionsName] !== undefined &&
              this.state[value.key] !== undefined
            ) {
              found = this.state[optionsName]
                .map((element) => {
                  if (
                    element[Object.keys(element)[0]] === this.state[value.key]
                  ) {
                    return element.name;
                  }
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
            }
            return (
              <div className="formitem text">
                <span className="span">{value.title}</span>
                <Select
                  showSearch
                  allowClear={true}
                  placeholder={'Select a ' + value.title}
                  optionFilterProp="children"
                  value={found ? found : this.state[value.key]}
                  onChange={(e) => {
                    this.onChange(value.key, e);
                    if (
                      value.name_variable !== undefined &&
                      value.name_variable !== null &&
                      found
                    ) {
                      this.onChange(value.name_variable, found);
                    }
                  }}
                  onSearch={(e) => {
                    this.getOptions(value.key, value.api, e);
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.renderOptions(value.key, value.api)}
                </Select>
              </div>
            );
          } else if (value.type === 'text-area') {
            return (
              <div className="formitem text-area">
                <span className="span">{value.title}</span>
                <TextArea
                  value={this.state[value.key]}
                  onChange={(e) => {
                    this.onChange(value.key, e.target.value);
                  }}
                />
              </div>
            );
          } else if (value.type === 'boolean') {
            return (
              <div className="formitem boolean">
                <span className="span">{value.title}</span>
                <Switch
                  checked={this.state[value.key]}
                  onChange={(e) => {
                    this.onChange(value.key, e);
                  }}
                />
              </div>
            );
          } else if (
            value.type === 'boolean-activate' &&
            this.props.item !== undefined &&
            this.props.item !== null
          ) {
            var origvalue = this.props.item[value.key];
            var activate =
              this.state[value.key] == false &&
              this.state[`activate${this.props.screen_id}`];
            var deactivate =
              this.state[value.key] == true &&
              this.state[`deactivate${this.props.screen_id}`];
            var access_active = !(
              activate ||
              deactivate ||
              this.state[value.key] !== origvalue
            );

            return (
              <div className="formitem boolean">
                <span className="span">{value.title}</span>
                <Switch
                  checked={this.state[value.key]}
                  onChange={(e) => {
                    this.onChange(value.key, e);
                  }}
                  disabled={access_active}
                />
              </div>
            );
          } else if (value.type === 'picture') {
            return <div className="formitem picture"></div>;
          } else if (value.type === 'date') {
            return (
              <div className="formitem text">
                <span className="span">{value.title}</span>
                <DatePicker
                  value={moment(this.state[value.key])}
                  showTime
                  placeholder="Select Date"
                  onChange={(e) => {
                    if (e) {
                      this.onChange(value.key, e.toISOString());
                    } else {
                      this.onChange(value.key, null);
                    }
                  }}
                  onOk={(e) => {
                    if (e) {
                      this.onChange(value.key, e.toISOString());
                    } else {
                      this.onChange(value.key, null);
                    }
                  }}
                />
              </div>
            );
          } else if (value.type === 'number') {
            return (
              <div className="formitem number">
                <span className="span">{value.title}</span>
                <InputNumber
                  className="inputPart"
                  min={0}
                  value={this.state[value.key]}
                  onChange={(e) => {
                    this.onChangeNumber(value.key, e);
                  }}
                />
              </div>
            );
          } else {
            return (
              <div className="formitem text">
                <span className="span">{value.title}</span>
                <Input
                  value={this.state[value.key]}
                  onChange={(e) => {
                    this.onChange(value.key, e.target.value);
                  }}
                />
              </div>
            );
          }
        }
      }
    );
    return renderform;
  }

  render() {
    const { visible, loading } = this.state;
    if (visible) {
      return (
        <div>
          <Modal
            className="add-modal"
            visible={visible}
            title={'Edit ' + this.props.headername}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            afterClose={this.onClose}
            footer={
              <>
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>
                {this.state[`delete${this.props.screen_id}`] ? (
                  <Popconfirm
                    title="Are you sure delete this item?"
                    onConfirm={this.handleDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button key="submit" type="danger" loading={loading}>
                      Delete
                    </Button>
                  </Popconfirm>
                ) : null}
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </>
            }
          >
            <div className="flex-grid-halves">{this.renderform()}</div>
          </Modal>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user.user_id,
    data: state.crud_data.data,
    editModalOpen: state.crud_data.editModalOpen,
    item: state.crud_data.item,

    user_role: state.auth.user.user_role,
    user_admin: state.auth.user.admin,
    screenActions: state.auth.screenActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    editItem: (data, apiurl, id, token) =>
      dispatch(editItem(data, apiurl, id, token)),
    deleteItem: (apiurl, id, token) => dispatch(deleteItem(apiurl, id, token)),
    openEdit: (item, editModalOpen) => dispatch(openEdit(item, editModalOpen)),
    getOptions: (token, apiurl, controller) =>
      dispatch(getOptions(token, apiurl, controller)),
    logAction: (data, token) => dispatch(logAction(data, token)),
    checkAuthAccess: (user_role, screen, action, token) =>
      dispatch(checkAuthAccess(user_role, screen, action, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
