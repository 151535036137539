import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PageHeader, Pagination, Input, Button, Table, Spin } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import './index.scss';
import AddModal from '../../components/AddModal';
import EditModal from '../../components/EditModal';
import { getData, searchData, openEdit, passCurrentGame } from '../../actions';

const { Search } = Input;
const INITIAL_STATE = {
  searchText: '',
};
const API_URL = 'api/admin/games';
const PAGENAME = 'Game';
const MODAL_BLUEPRINT = [
  {
    title: 'ID',
    key: 'game_id',
  },
  {
    title: 'Name',
    key: 'game_name',
  },
  {
    title: 'Wallet Amount',
    key: 'wallet_amount',
  },
  {
    title: 'Created at',
    key: 'created_at',
  },
  {
    title: 'Public API',
    key: 'api_gateway_public',
  },
  {
    title: 'Admin API',
    key: 'api_gateway_admin',
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      data: [],
      COLUMNS: [
        {
          title: 'Name',
          dataIndex: 'game_name',
          key: 'game_name',
          sorter: (a, b) => a.game_name > b.game_name,
          ...this.getColumnSearchProps('game_name'),
        },
        {
          title: 'Wallet Amount',
          dataIndex: 'wallet_amount',
          key: 'wallet_amount',
          sorter: (a, b) => a.wallet_amount > b.wallet_amount,
          ...this.getColumnSearchProps('wallet_amount'),
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          sorter: (a, b) => a.api_gateway_admin > b.api_gateway_admin,
          ...this.getColumnSearchProps('created_at'),
        },
        {
          title: 'Public API',
          dataIndex: 'api_gateway_public',
          key: 'api_gateway_public',
          sorter: (a, b) => a.api_gateway_public > b.api_gateway_public,
          ...this.getColumnSearchProps('api_gateway_public'),
        },
        {
          title: 'Admin API',
          dataIndex: 'api_gateway_admin',
          key: 'api_gateway_admin',
          sorter: (a, b) => a.api_gateway_admin > b.api_gateway_admin,
          ...this.getColumnSearchProps('api_gateway_admin'),
        },
        {
          title: 'Edit',
          fixed: 'right',
          render: (item) => {
            var editItem = item;
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.openEditModal(editItem);
                }}
              >
                <LegacyIcon type="edit" />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  getTableData() {
    if (this.props.token) {
      this.props.getData(this.props.token, API_URL);
    }
  }

  onSearch(value) {
    var searchval = value.toString();
    this.setState({ searchText: searchval });
    if (searchval) {
      this.props.searchData(searchval, this.props.data);
    } else {
      this.getTableData();
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        className="edit-icon"
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  resetTable() {
    this.getTableData();
  }
  openEditModal(record) {
    if (record && record !== undefined) {
      this.props.openEdit(record, true);
    }
  }

  gotoGame(game) {
    this.props.passCurrentGame(game, this.props.token).then(() => {
      this.props.history.push(`/games/${game.game_id}/`);
    });
  }

  render() {
    return (
      <div className="generic-container">
        <iframe
          className="inserted-site"
          src="https://www.birthdaypanalo.com/"
        ></iframe>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.crud_data.data,
    editModalOpen: state.crud_data.editModalOpen,
    dataCount: state.crud_data.dataCount,
    dataNext: state.crud_data.dataNext,
    dataPrevious: state.crud_data.dataPrevious,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    searchData: (filter, data) => dispatch(searchData(filter, data)),
    openEdit: (item, editModalOpen) => dispatch(openEdit(item, editModalOpen)),
    passCurrentGame: (currentGame, token) =>
      dispatch(passCurrentGame(currentGame, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

//http://localhost:8000/api/filteredWinners/{drawid}
