import React, { Component } from 'react';
import {Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Spin, Popconfirm, message, Modal,Form } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import * as actions from '../../auth/actions';
import classNames from 'classnames'
import './index.scss'


const antIcon = <LegacyIcon type="loading" style={{ fontSize: 24 }} spin />;


class Login extends Component {


    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            email:null, 
            submitforgotpass:false,
            captcha:true,
            captcha2:true,
        }
    }


  handleSubmit = (values) => {
 
    this.props.authLogin(values.username, values.password);
  
  }


  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handlesubmitforgotPass = e => {
    this.setState({submitforgotpass:true})
    if (this.state.email) {
        this.props.forgotPassword(this.state.email)
        message.success(`email has been sent to ${this.state.email}`);  
        this.setState({
            visible: false,
            submitforgotpass:false
        });
    }else{
        message.error('input email');  
        this.setState({submitforgotpass:false})
    }
  };

  handleCancel = e => {
    //console.log(e);
 
    this.setState({
    visible: false,
    submitforgotpass:false
    });
  
  };

  onChange = (key,value) => {
    this.setState({ [key]: value });      
    };

    onChangeCaptchaLogin(value) {      
        this.setState({captcha:false})      
    }
    onChangeCaptchaLogin2(value) {      
        this.setState({captcha2:false})      
    }

    onFinish = values => {
        console.log('Success:', values);
      };

  render() {
    let errorMessage = null;
    if (this.props.error) {
        errorMessage = (
            <p>{this.props.error.message}</p>
        );
    }

  
    
    if(this.state.captcha){
        return(
            <div className='login-container'>
                <Form className={classNames("login-form","main-form")}>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_REACTAPP_SITE_KEY}
                        onChange={e=>this.onChangeCaptchaLogin(e)}
                        theme="dark"
                                               
                    /> 
                </Form>  
            </div>    
        )
    }else{
        return (
            <div className='login-container'>       
                {
                    this.props.loading ?
    
                    <Spin indicator={antIcon} />
                    :          
                    <Form 
                        onFinish={this.handleSubmit}   
                        className={classNames("login-form","main-form")}
                    >
    
                        <Form.Item name="username" className={classNames("form-item")} 
                    
                            rules={ [ {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                              },
                              { required: true, message: 'Please input your email!' }]
                            }

                        >
                            <Input prefix={<LegacyIcon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="email" />
                        
                        </Form.Item>
    
                        <Form.Item name="password" className={classNames("form-item")} 
                       
                            rules={[{ required: true, message: 'Please input your Password!' }]
                            }
                        >
                            <Input prefix={<LegacyIcon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                        
                        </Form.Item>                    
        
                        <Form.Item className={classNames("form-item")} >             
                            <Button type="primary" htmlType="submit" className="login-button">
                                Login
                            </Button>                    
                        </Form.Item>
                        <Form.Item className={classNames("form-item")} >
                            <label style={{color:'gray'}} onClick={this.showModal}>
                                Forgot Password?
                            </label>                    
                        </Form.Item>
                    </Form>
                }
                <Modal
                    title="Forgot Password"
                    visible={this.state.visible}
                    onOk={()=>{this.handlesubmitforgotPass()}}
                    okButtonProps={{loading: this.state.submitforgotpass}}
                    onCancel={this.handleCancel}
                    >
                    {this.state.captcha2?
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_REACTAPP_SITE_KEY}
                            onChange={e=>this.onChangeCaptchaLogin2(e)}
                            theme="dark"
                                                  
                        />   :
                              
                        <Input
                            placeholder="Enter your email"
                            prefix={<LegacyIcon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            value={this.state.email}
                            onChange={e =>{this.onChange('email',e.target.value)}} 
                        />
                    }        
                </Modal>
          </div>
        );

    }
        

  }
}



const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error:  state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        authLogin: (username, password) => dispatch(actions.authLogin(username, password)),
        forgotPassword:(email) => dispatch(actions.forgotPassword(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);