import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  PageHeader,
  Pagination,
  Input,
  Button,
  Table,
  Spin,
  Statistic,
  Drawer,
  Descriptions,
  Tag,
  Tabs,
  DatePicker,
  message,
} from 'antd';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import Highlighter from 'react-highlight-words';
import './index.scss';

import AddDraw from './components/AddDraw';
import ExportWinnertoPDF from './components/ExportWinnertoPDF';
import AddDrawDate from './components/AddDrawDate';

import SendNotificationModal from '../../components/SendNotificationModal';
import {
  getData,
  searchData,
  openEdit,
  passCurrentGame,
  getGameTransactions,
  getGameDraws,
  getFilteredWinners,
  checkAuthAccess,
  getTicketTransaction,
  getGameNewDraws,
  getAllBetsByDrawDateById,
} from '../../actions';

import ExportDated from '../../components/betsExport/ExportDatesBets';
import moment from 'moment';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Search } = Input;
const INITIAL_STATE = {
  searchText: '',
};
const API_URL = 'api/admin/games';

const PAGENAME = 'Game';
const MODAL_BLUEPRINT = [
  {
    title: 'ID',
    key: 'game_id',
  },
  {
    title: 'Name',
    key: 'game_name',
  },
  {
    title: 'Wallet Amount',
    key: 'wallet_amount',
  },
  {
    title: 'Created at',
    key: 'created_at',
  },
  {
    title: 'Public API',
    key: 'api_gateway_public',
  },
  {
    title: 'Admin API',
    key: 'api_gateway_admin',
  },
];
console.log();

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      data: [],
      currentGame: [],
      gameTransactions: [],
      draws: [],
      newdraws: [],
      drawerShow: false,
      selectedDraw: '',
      filteredWinners: [],

      sorter_gameTransactions: null,
      filterArray_gameTransactions: [],
      filters_gameTransactions: null,
      page_gameTransactions: 1,
      searchText_gameTransactions: null,
      offset_gameTransactions: 0,
      pageSize_gameTransactions: 8,
      defaultpageSize_gameTransactions: 8,
      range_gameTransactions: [1, 8],

      sorter_draws: null,
      filterArray_draws: [],
      filters_draws: null,
      page_draws: 1,
      searchText_draws: null,
      offset_draws: 0,
      pageSize_draws: 8,
      defaultpageSize_draws: 8,
      range_draws: [1, 8],

      sorter_new_draws: null,
      filterArray_new_draws: [],
      filters_new_draws: null,
      page_new_draws: 1,
      searchText_new_draws: null,
      offset_new_draws: 0,
      pageSize_new_draws: 8,
      defaultpageSize_new_draws: 8,
      range_new_draws: [1, 8],
      selectedDrawDate: null,

      sorter_filteredWinners: null,
      filterArray_filteredWinners: [],
      filters_filteredWinners: null,
      page_filteredWinners: 1,
      searchText_filteredWinners: null,
      offset_filteredWinners: 0,
      pageSize_filteredWinners: 8,
      defaultpageSize_filteredWinners: 8,
      range_filteredWinners: [1, 8],

      loading: false,
      loading_gameTransactions: true,
      loading_draws: true,
      loading__new_draws: true,
      loading_filteredWinners: true,
      selectedDateRange: [],
      dateFilterAcivated: false,
      selectedRowKeys: [],
      selectedRowItems: [],

      payoutClick: false,

      exportlist: [
        ['Bets Per Draw Report '],
        ['Date Today', moment(new Date()).format('LLLL')],
        ['Draw Date', 'Bet Combination', 'Number of Winners', 'Number of Bets'],
      ],

      TRANSACTION_COLUMNS: [
        {
          title: 'DATE TIME',
          dataIndex: 'transacted_at',
          key: 'transacted_at',
          sorter: (a, b) => a.ref_num > b.ref_num,
          ...this.getColumnSearchProps_gameTransactions('ref_num'),
        },
        {
          title: 'Transaction ID',
          dataIndex: 'ref_num',
          key: 'ref_num',
          sorter: (a, b) => a.ref_num > b.ref_num,
          ...this.getColumnSearchProps_gameTransactions('ref_num'),
        },
        {
          title: 'Play type',
          dataIndex: 'play_type',
          key: 'play_type',
          sorter: (a, b) => a.play_type > b.play_type,
          ...this.getColumnSearchProps_gameTransactions('play_type'),
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          sorter: (a, b) => a.price > b.price,
          ...this.getColumnSearchProps_gameTransactions('price'),
        },
      ],
      DRAW_COLUMNS: [
        {
          title: 'Draw Date',
          dataIndex: 'date_stamp',
          key: 'date_stamp',
        },
        {
          title: 'Combination',
          dataIndex: 'bet_combination',
          key: 'bet_combination',
        },
        {
          title: 'Generate Bets',
          dataIndex: 'id',
          key: 'bet_combination',
          render: (text, record) => {
            return (
              <Button
                type="primary"
                onClick={(e) => {
                  props.getAllBetsByDrawDateById(
                    this.props.token,
                    record.draw_id
                  );
                  this.setState({ payoutClick: true });
                  e.stopPropagation();
                }}
                size="small"
                style={{ width: 110, marginRight: 8 }}
              >
                Download CSV
              </Button>
            );
          },
        },
      ],
      NEW_DRAW_COLUMNS: [
        {
          title: 'Draw Date',
          dataIndex: 'draw_date',
          key: 'draw_date',
        },
        {
          title: 'Draw',
          dataIndex: 'Draw',
          render: (text, record) => {
            return (
              <AddDraw
                digitCount={6}
                drawdateID={record.id}
                drawdate={record.draw_date}
                drawObject={record}
              />
            );
          },
        },
        {
          title: 'First Check',
          key: 'first_set',
          type: 'boolean',
          render: (row) => {
            if (row.first_set === true) {
              return <LegacyIcon type="check" />;
            } else {
              return <LegacyIcon type="close" />;
            }
          },
        },
        {
          title: 'Second Check',
          key: 'second_set',
          type: 'boolean',
          render: (row) => {
            if (row.second_set === true) {
              return <LegacyIcon type="check" />;
            } else {
              return <LegacyIcon type="close" />;
            }
          },
        },
      ],

      WINNERS_COLUMNS: [
        {
          title: 'Player',
          dataIndex: 'user_id',
          key: 'user_id',
        },
        {
          title: 'Prize',
          dataIndex: 'prize',
          key: 'prize',
        },
        {
          title: 'Ref #',
          dataIndex: 'ref_num',
          key: 'ref_num',
        },

        {
          title: 'Claimed',
          dataIndex: 'is_claimed',
          key: 'is_claimed',
          sorter: (a, b) => {
            return a.is_claimed > b.is_claimed;
          },
          ...this.getColumnSearchProps_filteredWinners('is_claimed'),
          render: (row) => {
            var editItem = row;

            if (editItem == true) {
              return <LegacyIcon type="check" />;
            } else {
              return <LegacyIcon type="close" />;
            }
          },
        },
        {
          title: 'Export',
          render: (text, record, index) => {
            return (
              <ExportWinnertoPDF user_id={record.user_id} record={record} />
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getTableData();
    this.getcurrentGame();
    this.loadTransactions(
      this.state.defaultpageSize_gameTransactions,
      0,
      null,
      null,
      null,
      1
    );
    this.accessInitialize();
    this.loadDraws(this.state.defaultpageSize_draws, 0, null, null, null, 1);
    this.loadNewDraws(
      this.state.defaultpageSize_new_draws,
      0,
      null,
      null,
      null,
      1
    );

    if (!this.props.currentGame.game_id) {
      this.props.history.push('/games');
    }
  }

  accessInitialize() {
    this.checkAccess('13', 'read');
    this.checkAccess('13', 'create');
    this.checkAccess('12', 'read');
  }

  checkAccess(screen, action) {
    var name = action + screen;
    if (this.props.user_admin) {
      this.setState({ [name]: true });
    } else {
      this.setState({ [name]: false });
      var a = this.props
        .checkAuthAccess(this.props.user_role, screen, action, this.props.token)
        .then((res) => {
          var permission = res.data.results[0];
          if (permission[action]) {
            this.setState({ [name]: true });
          } else {
            this.setState({ [name]: false });
          }
        })
        .catch((err) => {
          this.setState({ [name]: false });
        });
    }
  }

  componentDidUpdate() {
    if (this.state.currentGame !== this.props.currentGame) {
      this.setState({ currentGame: this.props.currentGame });
      if (this.props.currentGame.length > 0) {
        this.setState({ loading: false });
      } else {
        setTimeout(
          function () {
            this.setState({ loading: false });
          }.bind(this),
          1000
        );
      }
    }
    if (this.state.gameTransactions !== this.props.gameTransactions) {
      this.setState({ gameTransactions: this.props.gameTransactions });
      console.log(this.props.gameTransactions);
      if (this.props.gameTransactions.length > 0) {
        this.setState({ loading_gameTransactions: false });
      } else {
        setTimeout(
          function () {
            this.setState({ loading_gameTransactions: false });
          }.bind(this),
          1000
        );
      }
    }
    if (this.state.draws !== this.props.draws) {
      this.setState({ draws: this.props.draws });

      if (this.props.draws.length > 0) {
        this.setState({ loading_draws: false });
      } else {
        setTimeout(
          function () {
            this.setState({ loading_draws: false });
          }.bind(this),
          1000
        );
      }
    }
    if (this.state.newdraws !== this.props.newdraws) {
      if (this.props.newdraws) {
        this.setState({ newdraws: this.props.newdraws });

        if (this.props.newdraws.length > 0) {
          this.setState({ loading_new_draws: false });
        } else {
          setTimeout(
            function () {
              this.setState({ loading_new_draws: false });
            }.bind(this),
            1000
          );
        }
      }
    }
    if (this.state.filteredWinners !== this.props.filteredWinners) {
      this.setState({ filteredWinners: this.props.filteredWinners });
      // console.log('filteredWinners',this.props.filteredWinners)
      // console.log('currentgame',this.props.currentGame)
      if (this.props.filteredWinners.length > 0) {
        this.setState({ loading_filteredWinners: false });
      } else {
        setTimeout(
          function () {
            this.setState({ loading_filteredWinners: false });
          }.bind(this),
          1000
        );
      }
    }
    if (this.state.payoutClick && this.props.betsPerDrawId.length !== 0) {
      console.log('HELLO WORLD');
      this.setState({
        payoutClick: false,
      });
      var test = [...this.state.exportlist];
      this.props.betsPerDrawId.forEach((entry) => {
        test.push(entry);
      });
      this.setState({
        exportlist: test,
      });
      this.Export();
    }
  }

  getcurrentGame() {
    if (this.props.token && this.props.currentGame) {
      this.props.passCurrentGame(this.props.currentGame, this.props.token);
    }
  }

  ///////////// TRANSACTIONS /////////////////////////////////////////////////////////////////////////////////////
  loadTransactions(limit, offset, sort, filter, search, page_num) {
    var page = page_num - 1;
    console.log(limit, offset, sort, filter, search, page_num);
    this.setState({ loading_gameTransactions: true });
    if (this.props.token && this.props.currentGame) {
      var gtApi = 'api/game/get_transactions';
      var limitoffset = `?page_num=${page}&page_size=${limit}`;
      var controllerText = limitoffset;
      var searchText = '';

      if (search !== null) {
        searchText = `&search=${search}`;
        controllerText = controllerText + searchText;
      }
      var filterText = '';
      if (filter !== null) {
        filterText = filter;
        controllerText = controllerText + filterText;
      }
      var sortText = '';
      if (sort !== null) {
        sortText = sort;
        controllerText = controllerText + sortText;
      }

      if (this.props.token && this.props.currentGame.api_gateway_admin) {
        this.props.getGameDraws(
          this.props.currentGame.api_gateway_admin,
          this.props.token,
          controllerText,
          page,
          limit
        );
      }

      if (this.state.dateFilterAcivated) {
        var moment1 = this.state.selectedDateRange[0];
        var moment2 = this.state.selectedDateRange[1];
        var startDate = moment(moment1).format('YYYY-MM-DD');
        var endDate = moment(moment2).format('YYYY-MM-DD');
        controllerText =
          controllerText + `&start_date=${startDate}&end_date=${endDate}`;
      }
    }
    console.log(controllerText);
    if (this.props.token && this.props.currentGame.api_gateway_admin) {
      this.props.getGameTransactions(
        this.props.currentGame.api_gateway_admin,
        gtApi,
        this.props.token,
        controllerText
      );
    }
  }

  getColumnSearchProps_gameTransactions = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch_gameTransactions(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            this.handleSearch_gameTransactions(selectedKeys, confirm, dataIndex)
          }
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() =>
            this.handleReset_gameTransactions(clearFilters, dataIndex)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[
          this.state.searchText_gameTransactions,
          this.state.filterText_gameTransactions,
        ]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch_gameTransactions = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.addtoFilterArray_gameTransactions(selectedKeys[0], dataIndex);
  };

  handleReset_gameTransactions = (clearFilters, dataIndex) => {
    clearFilters();

    this.removeFilterArray(dataIndex);
    this.setState({ filterText_gameTransactions: '' });
  };
  generateFilterController_gameTransactions = (currentArray) => {
    var filter = '';
    Object.entries(currentArray).map(([key, value]) => {
      filter = filter + `&${value.dataIndex}=${value.value}`;
    });
    this.setState({ filters_gameTransactions: filter });
    this.loadTransactions(
      this.state.defaultpageSize_gameTransactions,
      0,
      null,
      filter,
      this.state.searchText_gameTransactions,
      this.state.page_gameTransactions
    );
  };

  addtoFilterArray_gameTransactions(value, dataIndex) {
    var newItem = {
      value: value,
      dataIndex: dataIndex,
    };
    var i;
    for (i = 0; i < this.state.filterArray_gameTransactions.length; i++) {
      if (
        this.state.filterArray_gameTransactions[i]['dataIndex'] === dataIndex
      ) {
        var newArray = this.state.filterArray_gameTransactions;
        newArray[i] = newItem;
        return this.setState(
          { filterArray_gameTransactions: newArray },
          this.generateFilterController_gameTransactions(newArray)
        );
      }
    }
    return this.setState(
      {
        filterArray_gameTransactions: this.state.filterArray_gameTransactions.concat(
          newItem
        ),
      },
      this.generateFilterController_gameTransactions(
        this.state.filterArray_gameTransactions.concat(newItem)
      )
    );
  }

  removeFilterArray_gameTransactions(dataIndex) {
    var i;
    for (i = 0; i < this.state.filterArray_gameTransactions.length; i++) {
      if (
        this.state.filterArray_gameTransactions[i]['dataIndex'] === dataIndex
      ) {
        var newArray = this.state.filterArray_gameTransactions;
        newArray.splice(i, 1);
        return this.setState(
          { filterArray_gameTransactions: newArray },
          this.generateFilterController_gameTransactions(newArray)
        );
      }
    }
    return this.generateFilterController_gameTransactions(
      this.state.filterArray_gameTransactions
    );
  }

  resetTable_gameTransactions() {
    this.loadTransactions(
      this.state.defaultpageSize_gameTransactions,
      0,
      null,
      null,
      null,
      1
    );

    this.setState({
      defaultpageSize_gameTransactions: 8,
      pageSize_gameTransactions: 8,
      range_gameTransactions: [1, 8],
      page_gameTransactions: 1,
      filterArray_gameTransactions: [],
      filters_gameTransactions: null,
      filterText_gameTransactions: null,
      searchText_gameTransactions: null,
      sorter_gameTransactions: null,
    });
  }

  onChangePage_gameTransactions(page, pageSize) {
    if (this.state.loading_gameTransactions == false) {
      this.setState({
        page_gameTransactions: page,
      });

      this.loadTransactions(
        pageSize,
        0,
        this.state.sorter_draws,
        this.state.filters_draws,
        this.state.searchText_draws,
        page
      );
    }
  }

  onChangePageSize_gameTransactions(currentPage, pageSize) {
    if (
      this.state.pageSize_gameTransactions !== pageSize &&
      this.state.loading_gameTransactions == false
    ) {
      this.setState({
        pageSize_gameTransactions: pageSize,
        page_gameTransactions: 1,
        offset_gameTransactions: 0,
      });
      this.loadTransactions(
        1,
        0,
        this.state.sorter_draws,
        this.state.filters_draws,
        this.state.searchText_draws,
        currentPage
      );
    }
  }

  ////get data by refrerence number

  onRefNumberSearch(ref_number) {
    // console.log(ref_number)
    this.props.getTicketTransaction(
      ref_number,
      this.props.currentGame.api_gateway_admin,
      this.props.token
    );
  }

  ///////////// END TRANSACTIONS /////////////////////////////////////////////////////////////////////////////////////

  ///////////// DRAWS /////////////////////////////////////////////////////////////////////////////////////
  loadDraws(limit, offset, sort, filter, search, page_num) {
    var page = page_num - 1;
    this.setState({ loading_draws: true });
    if (this.props.token && this.props.currentGame) {
      var limitoffset = `?limit=${limit}&offset=${offset}`;
      var controllerText = limitoffset;
      var searchText = '';
      if (search !== null) {
        searchText = `&search=${search}`;
        controllerText = controllerText + searchText;
      }
      var filterText = '';
      if (filter !== null) {
        filterText = filter;
        controllerText = controllerText + filterText;
      }
      var sortText = '';
      if (sort !== null) {
        sortText = sort;
        controllerText = controllerText + sortText;
      }

      if (this.props.token && this.props.currentGame.api_gateway_admin) {
        this.props.getGameDraws(
          this.props.currentGame.api_gateway_admin,
          this.props.token,
          controllerText,
          page,
          limit
        );
      }
      // this.props.getGameDraws(this.props.currentGame, this.props.token,controllerText,page);
    }
  }

  handleTableChange_draws = (pagination, filters, sorter) => {
    this.handleSorter_draws(sorter);
  };
  handleSorter_draws(sorter) {
    if (this.state.sorter_draws !== sorter) {
      var sorterValue = null;

      if (sorter.order === 'ascend') {
        sorterValue = `&ordering=${sorter.columnKey}`;
        this.setState({ sorter_draws: sorterValue });
        this.loadDraws(
          this.state.pageSize_draws,
          this.state.offset_draws,
          sorterValue,
          this.state.filters_draws,
          this.state.searchText_draws,
          1
        );
      } else if (sorter.order === 'descend') {
        sorterValue = `&ordering=-${sorter.columnKey}`;
        this.setState({ sorter_draws: sorterValue });
        this.loadDraws(
          this.state.pageSize_draws,
          this.state.offset_draws,
          sorterValue,
          this.state.filters_draws,
          this.state.searchText_draws,
          1
        );
      } else {
        this.setState({ sorter_draws: null });
      }
    }
  }

  getColumnSearchProps_draws = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch_draws(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            this.handleSearch_draws(selectedKeys, confirm, dataIndex)
          }
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset_draws(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText_draws, this.state.filterText_draws]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch_draws = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.addtoFilterArray_draws(selectedKeys[0], dataIndex);
  };

  handleReset_draws = (clearFilters, dataIndex) => {
    clearFilters();

    this.removeFilterArray(dataIndex);
    this.setState({ filterText_draws: '' });
  };
  generateFilterController_draws(currentArray) {
    var filter = '';
    Object.entries(currentArray).map(([key, value]) => {
      filter = filter + `&${value.dataIndex}=${value.value}`;
    });
    this.setState({ filters_draws: filter });
    this.loadDraws(
      this.state.defaultpageSize_draws,
      0,
      null,
      filter,
      this.state.searchText_draws,
      this.state.page_draws
    );
  }

  addtoFilterArray_draws(value, dataIndex) {
    var newItem = {
      value: value,
      dataIndex: dataIndex,
    };
    var i;
    for (i = 0; i < this.state.filterArray_draws.length; i++) {
      if (this.state.filterArray_draws[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray_draws;
        newArray[i] = newItem;
        return this.setState(
          { filterArray_draws: newArray },
          this.generateFilterController_draws(newArray)
        );
      }
    }
    return this.setState(
      { filterArray_draws: this.state.filterArray_draws.concat(newItem) },
      this.generateFilterController_draws(
        this.state.filterArray_draws.concat(newItem)
      )
    );
  }

  removeFilterArray_draws(dataIndex) {
    var i;
    for (i = 0; i < this.state.filterArray_draws.length; i++) {
      if (this.state.filterArray_draws[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray_draws;
        newArray.splice(i, 1);
        return this.setState(
          { filterArray_draws: newArray },
          this.generateFilterController_draws(newArray)
        );
      }
    }
    return this.generateFilterController_draws(this.state.filterArray_draws);
  }

  resetTable_draws() {
    this.loadDraws(this.state.defaultpageSize_draws, 0, null, null, null, 1);

    this.setState({
      defaultpageSize_draws: 8,
      pageSize_draws: 8,
      range_draws: [1, 8],
      page_draws: 1,
      filterArray_draws: [],
      filters_draws: null,
      filterText_draws: null,
      searchText_draws: null,
      sorter_draws: null,
    });
  }

  onChangePage_draws(page, pageSize) {
    if (this.state.loading_draws == false) {
      this.setState({
        page_draws: page,
      });
      this.loadDraws(
        pageSize,
        0,
        this.state.sorter_draws,
        this.state.filters_draws,
        this.state.searchText_draws,
        page
      );
    }
  }

  onChangePageSize_draws(currentPage, pageSize) {
    if (
      this.state.pageSize_draws !== pageSize &&
      this.state.loading_draws == false
    ) {
      this.setState({
        pageSize_draws: pageSize,
        page_draws: 1,
        offset_draws: 0,
      });
      this.loadDraws(
        pageSize,
        0,
        this.state.sorter_draws,
        this.state.filters_draws,
        this.state.searchText_draws,
        currentPage
      );
    }
  }

  onChangeRange_draws(total, range) {
    // if(this.state.range_draws[0] !== range[0] || this.state.range_draws[1] !== range[1] ){
    //     var offset = range[0]-1
    //     this.setState({range_draws:range})
    //     this.loadDraws(this.state.pageSize_draws,offset,this.state.sorter_draws,this.state.filters_draws,this.state.searchText_draws)
    // }
  }

  ////////////////////////////////////DRAWS  END |||||||||||||||||||||||||||||||||||||||||||||||
  ///////////// NEW DRAWS /////////////////////////////////////////////////////////////////////////////////////
  loadNewDraws(limit, offset, sort, filter, search, page_num) {
    var page = page_num - 1;
    this.setState({ loading_new_draws: true });
    if (this.props.token && this.props.currentGame) {
      var limitoffset = `?limit=${limit}&offset=${offset}`;
      var controllerText = limitoffset;
      var searchText = '';
      if (search !== null) {
        searchText = `&search=${search}`;
        controllerText = controllerText + searchText;
      }
      var filterText = '';
      if (filter !== null) {
        filterText = filter;
        controllerText = controllerText + filterText;
      }
      var sortText = '';
      if (sort !== null) {
        sortText = sort;
        controllerText = controllerText + sortText;
      }
      if (this.props.token && this.props.currentGame.api_gateway_admin) {
        this.props.getGameNewDraws(
          this.props.currentGame.api_gateway_admin,
          this.props.token,
          controllerText,
          page,
          limit
        );
      }
      // this.props.getGameDraws(this.props.currentGame, this.props.token,controllerText,page);
    }
  }

  handleTableChange_new_draws = (pagination, filters, sorter) => {
    this.handleSorter_draws(sorter);
  };
  handleSorter_new_draws(sorter) {
    if (this.state.sorter_new_draws !== sorter) {
      var sorterValue = null;

      if (sorter.order === 'ascend') {
        sorterValue = `&ordering=${sorter.columnKey}`;
        this.setState({ sorter_new_draws: sorterValue });
        this.loadNewDraws(
          this.state.pageSize_new_draws,
          this.state.offset_new_draws,
          sorterValue,
          this.state.filters_new_draws,
          this.state.searchText_new_draws,
          1
        );
      } else if (sorter.order === 'descend') {
        sorterValue = `&ordering=-${sorter.columnKey}`;
        this.setState({ sorter_new_draws: sorterValue });
        this.loadNewDraws(
          this.state.pageSize_new_draws,
          this.state.offset_new_draws,
          sorterValue,
          this.state.filters_new_draws,
          this.state.searchText_new_draws,
          1
        );
      } else {
        this.setState({ sorter_new_draws: null });
      }
    }
  }

  getColumnSearchProps_new_draws = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch_new_draws(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            this.handleSearch_new_draws(selectedKeys, confirm, dataIndex)
          }
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset_new_draws(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[
          this.state.searchText_new_draws,
          this.state.filterText_new_draws,
        ]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch_new_draws = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.addtoFilterArray_new_draws(selectedKeys[0], dataIndex);
    this.setState({ filterText_new_draws: selectedKeys[0] });
  };

  handleReset_new_draws = (clearFilters, dataIndex) => {
    clearFilters();

    this.removeFilterArray_draws(dataIndex);
    this.setState({ filterText_new_draws: '' });
  };
  generateFilterController_new_draws(currentArray) {
    var filter = '';
    Object.entries(currentArray).map(([key, value]) => {
      filter = filter + `&${value.dataIndex}=${value.value}`;
    });
    this.setState({ filters_new_draws: filter });
    this.loadNewDraws(
      this.state.defaultpageSize_new_draws,
      0,
      null,
      filter,
      this.state.searchText_new_draws,
      this.state.page_new_draws
    );
  }

  addtoFilterArray_new_draws(value, dataIndex) {
    var newItem = {
      value: value,
      dataIndex: dataIndex,
    };
    var i;
    for (i = 0; i < this.state.filterArray_new_draws.length; i++) {
      if (this.state.filterArray_new_draws[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray_new_draws;
        newArray[i] = newItem;
        return this.setState(
          { filterArray_new_draws: newArray },
          this.generateFilterController_new_draws(newArray)
        );
      }
    }
    return this.setState(
      {
        filterArray_new_draws: this.state.filterArray_new_draws.concat(newItem),
      },
      this.generateFilterController_new_draws(
        this.state.filterArray_new_draws.concat(newItem)
      )
    );
  }

  removeFilterArray_new_draws(dataIndex) {
    var i;
    for (i = 0; i < this.state.filterArray_new_draws.length; i++) {
      if (this.state.filterArray_new_draws[i]['dataIndex'] === dataIndex) {
        var newArray = this.state.filterArray_new_draws;
        newArray.splice(i, 1);
        return this.setState(
          { filterArray_new_draws: newArray },
          this.generateFilterController_new_draws(newArray)
        );
      }
    }
    return this.generateFilterController_new_draws(
      this.state.filterArray_new_draws
    );
  }

  resetTable_new_draws() {
    this.loadNewDraws(
      this.state.defaultpageSize_new_draws,
      0,
      null,
      null,
      null,
      1
    );

    this.setState({
      defaultpageSize_new_draws: 8,
      pageSize_new_draws: 8,
      range_new_draws: [1, 8],
      page_new_draws: 1,
      filterArray_new_draws: [],
      filters_new_draws: null,
      filterText_new_draws: null,
      searchText_new_draws: null,
      sorter_new_draws: null,
    });
  }

  onChangePage_new_draws(page, pageSize) {
    if (this.state.loading_new_draws == false) {
      this.setState({
        page_new_draws: page,
      });
      this.loadNewDraws(
        pageSize,
        0,
        this.state.sorter_new_draws,
        this.state.filters_new_draws,
        this.state.searchText_new_draws,
        page
      );
    }
  }

  onChangePageSize_new_draws(currentPage, pageSize) {
    if (
      this.state.pageSize_new_draws !== pageSize &&
      this.state.loading_new_draws == false
    ) {
      this.setState({
        pageSize_new_draws: pageSize,
        page_new_draws: 1,
        offset_new_draws: 0,
      });
      this.loadNewDraws(
        pageSize,
        0,
        this.state.sorter_new_draws,
        this.state.filters_new_draws,
        this.state.searchText_new_draws,
        currentPage
      );
    }
  }

  onChangeRange_new_draws(total, range) {
    // if(this.state.range_draws[0] !== range[0] || this.state.range_draws[1] !== range[1] ){
    //     var offset = range[0]-1
    //     this.setState({range_draws:range})
    //     this.loadNewDraws(this.state.pageSize_draws,offset,this.state.sorter_draws,this.state.filters_draws,this.state.searchText_draws)
    // }
  }

  //////////////////////////////////// NEW DRAWS  END |||||||||||||||||||||||||||||||||||||||||||||||

  getTableData() {
    this.setState({ loading: true });
    if (this.props.token) {
      this.props.getData(this.props.token, API_URL);
    }
  }

  onSearch(value) {
    var searchval = value.toString();
    this.setState({ searchText: searchval });
    if (searchval) {
      this.getTableData();
    } else {
      this.getTableData();
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();

    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  resetTable() {
    this.getcurrentGame();
  }
  openEditModal(record) {
    if (record && record !== undefined) {
      this.props.openEdit(record, true);
    }
  }

  ////////////  FILTERED WINNERS /////////////////////////////////////////////////////////////////////////////////////

  openDrawer(selectedDraw) {
    this.setState({
      drawerShow: true,
      selectedDraw: selectedDraw,
    });
    if (selectedDraw.draw_id) {
      this.getFilteredWinners(
        selectedDraw.draw_id,
        this.state.defaultpageSize_filteredWinners,
        0,
        null,
        null,
        null
      );
    }
  }
  onFail = () => {
    this.setState({ loading_filteredWinners: false });
  };

  closeDrawer = () => {
    this.setState({
      drawerShow: false,
      selectedDraw: '',
      filteredWinners: [],

      defaultpageSize_filteredWinners: 8,
      pageSize_filteredWinners: 8,
      range_filteredWinners: [1, 8],
      page_filteredWinners: 1,
      filterArray_filteredWinners: [],
      filters_filteredWinners: null,
      filterText_filteredWinners: null,
      searchText_filteredWinners: null,
      sorter_filteredWinners: null,
    });
  };

  getFilteredWinners(draw_id, limit, offset, sort, filter, search) {
    this.setState({ loading_filteredWinners: true });
    if (this.props.token && this.props.currentGame) {
      var limitoffset = `?limit=${limit}&offset=${offset}`;
      var controllerText = limitoffset;
      var searchText = '';
      if (search !== null) {
        searchText = `&search=${search}`;
        controllerText = controllerText + searchText;
      }
      var filterText = '';
      if (filter !== null) {
        filterText = filter;
        controllerText = controllerText + filterText;
      }
      var sortText = '';
      if (sort !== null) {
        sortText = sort;
        controllerText = controllerText + sortText;
      }
      controllerText =
        controllerText + `&game=${this.props.currentGame.game_id}`;
      this.props.getFilteredWinners(
        draw_id,
        this.props.token,
        this.props.currentGame.api_gateway_admin,
        this.onFail
      );
    }
  }

  handleTableChange_filteredWinners = (pagination, filters, sorter) => {
    this.handleSorter_filteredWinners(sorter);
  };
  handleSorter_filteredWinners(sorter) {
    if (this.state.sorter_filteredWinners !== sorter) {
      var sorterValue = null;

      if (sorter.order === 'ascend') {
        sorterValue = `&ordering=${sorter.columnKey}`;
        this.setState({ sorter_filteredWinners: sorterValue });
        this.getFilteredWinners(
          this.state.selectedDraw.draw_id,
          this.state.pageSize_filteredWinners,
          this.state.offset_filteredWinners,
          sorterValue,
          this.state.filters_filteredWinners,
          this.state.searchText_filteredWinners
        );
      } else if (sorter.order === 'descend') {
        sorterValue = `&ordering=-${sorter.columnKey}`;
        this.setState({ sorter_filteredWinners: sorterValue });
        this.getFilteredWinners(
          this.state.selectedDraw.draw_id,
          this.state.pageSize_filteredWinners,
          this.state.offset_filteredWinners,
          sorterValue,
          this.state.filters_filteredWinners,
          this.state.searchText_filteredWinners
        );
      } else {
        this.setState({ sorter_filteredWinners: null });
      }
    }
  }

  getColumnSearchProps_filteredWinners = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch_filteredWinners(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            this.handleSearch_filteredWinners(selectedKeys, confirm, dataIndex)
          }
          icon={<LegacyIcon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() =>
            this.handleReset_filteredWinners(clearFilters, dataIndex)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <LegacyIcon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[
          this.state.searchText_filteredWinners,
          this.state.filterText_filteredWinners,
        ]}
        autoEscape
        textToHighlight={'' + text}
      />
    ),
  });

  handleSearch_filteredWinners = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.addtoFilterArray_filteredWinners(selectedKeys[0], dataIndex);
  };

  handleReset_filteredWinners = (clearFilters, dataIndex) => {
    clearFilters();

    this.removeFilterArray(dataIndex);
    this.setState({ filterText_filteredWinners: '' });
  };
  generateFilterController_filteredWinners(currentArray) {
    var filter = '';
    Object.entries(currentArray).map(([key, value]) => {
      filter = filter + `&${value.dataIndex}=${value.value}`;
    });
    this.setState({ filters_filteredWinners: filter });
    this.getFilteredWinners(
      this.state.selectedDraw.draw_id,
      this.state.defaultpageSize_filteredWinners,
      0,
      null,
      filter,
      this.state.searchText_filteredWinners
    );
  }

  addtoFilterArray_filteredWinners(value, dataIndex) {
    var newItem = {
      value: value,
      dataIndex: dataIndex,
    };
    var i;
    for (i = 0; i < this.state.filterArray_filteredWinners.length; i++) {
      if (
        this.state.filterArray_filteredWinners[i]['dataIndex'] === dataIndex
      ) {
        var newArray = this.state.filterArray_filteredWinners;
        newArray[i] = newItem;
        return this.setState(
          { filterArray_filteredWinners: newArray },
          this.generateFilterController_filteredWinners(newArray)
        );
      }
    }
    return this.setState(
      {
        filterArray_filteredWinners: this.state.filterArray_filteredWinners.concat(
          newItem
        ),
      },
      this.generateFilterController_filteredWinners(
        this.state.filterArray_filteredWinners.concat(newItem)
      )
    );
  }

  removeFilterArray_filteredWinners(dataIndex) {
    var i;
    for (i = 0; i < this.state.filterArray_filteredWinners.length; i++) {
      if (
        this.state.filterArray_filteredWinners[i]['dataIndex'] === dataIndex
      ) {
        var newArray = this.state.filterArray_filteredWinners;
        newArray.splice(i, 1);
        return this.setState(
          { filterArray_filteredWinners: newArray },
          this.generateFilterController_filteredWinners(newArray)
        );
      }
    }
    return this.generateFilterController_filteredWinners(
      this.state.filterArray_filteredWinners
    );
  }

  resetTable_filteredWinners() {
    this.loadDraws(
      this.state.defaultpageSize_filteredWinners,
      0,
      null,
      null,
      null,
      1
    );

    this.setState({
      defaultpageSize_filteredWinners: 8,
      pageSize_filteredWinners: 8,
      range_filteredWinners: [1, 8],
      page_filteredWinners: 1,
      filterArray_filteredWinners: [],
      filters_filteredWinners: null,
      filterText_filteredWinners: null,
      searchText_filteredWinners: null,
      sorter_filteredWinners: null,
    });
  }

  onChangePage_filteredWinners(page, pageSize) {
    if (this.state.loading_filteredWinners == false) {
      this.setState({
        page_filteredWinners: page,
      });
    }
  }

  onChangePageSize_filteredWinners(currentPage, pageSize) {
    if (
      this.state.pageSize_filteredWinners !== pageSize &&
      this.state.loading_filteredWinners == false
    ) {
      this.setState({
        pageSize_filteredWinners: pageSize,
        page_filteredWinners: 1,
        offset_filteredWinners: 0,
      });
      this.getFilteredWinners(
        pageSize,
        0,
        this.state.sorter_filteredWinners,
        this.state.filters_filteredWinners,
        this.state.searchText_filteredWinners
      );
    }
  }

  onChangeRange_filteredWinners(total, range) {
    console.log(this.state.range_filteredWinners, range);
    if (
      this.state.range_filteredWinners[0] !== range[0] ||
      this.state.range_filteredWinners[1] !== range[1]
    ) {
      var offset = range[0] - 1;
      this.setState({ range_filteredWinners: range });
      this.getFilteredWinners(
        this.state.pageSize_filteredWinners,
        offset,
        this.state.sorter_filteredWinners,
        this.state.filters_filteredWinners,
        this.state.searchText_filteredWinners
      );
    }
  }

  DoDateFilter() {
    if (this.state.dateFilterAcivated) {
      this.setState(
        { selectedDateRange: [], dateFilterAcivated: false },
        () => {
          this.loadTransactions(
            this.state.defaultpageSize_gameTransactions,
            0,
            this.state.sorter_gameTransactions,
            this.state.filters_gameTransactions,
            this.state.searchText_gameTransactions,
            1
          );
        }
      );
    } else if (this.state.selectedDateRange.length > 0) {
      this.setState({ dateFilterAcivated: true }, () => {
        this.loadTransactions(
          this.state.defaultpageSize_gameTransactions,
          0,
          this.state.sorter_gameTransactions,
          this.state.filters_gameTransactions,
          this.state.searchText_gameTransactions,
          1
        );
      });
    } else {
      message.error('Filter not applied');
    }
  }
  //////////////////////////////////// FILTERED WINNERS  END |||||||||||||||||||||||||||||||||||||||||||||||
  //////////////////////////////////// DRAWER |||||||||||||||||||||||||||||||||||||||||||||||

  renderDrawer() {
    return (
      <Drawer
        title="Winners"
        placement="right"
        closable={false}
        width={1080}
        onClose={() => {
          this.closeDrawer();
        }}
        visible={this.state.drawerShow}
      >
        <Statistic
          decimalSeparator=""
          title="Winning Combination"
          groupSeparator=""
          value={this.state.selectedDraw.bet_combination}
          style={{
            margin: '0 32px',
          }}
        />
        <Statistic
          decimalSeparator=""
          title="Draw Date"
          groupSeparator=""
          value={this.state.selectedDraw.draw_date}
          style={{
            margin: '0 32px',
          }}
        />

        <Table
          loading={this.state.loading_filteredWinners}
          dataSource={this.state.filteredWinners}
          columns={this.state.WINNERS_COLUMNS}
          sorter={true}
          pagination={{
            onChange: (page, pageSize) => {
              this.onChangePage_filteredWinners(page, pageSize);
            },

            onShowSizeChange: (current, size) => {
              this.onChangePageSize_filteredWinners(current, size);
            },
            current: this.state.page_filteredWinners,
            showQuickJumper: true,
            defaultPageSize: this.state.defaultpageSize_filteredWinners,
            pageSizeOptions: [
              this.state.defaultpageSize_filteredWinners,
              '20',
              '50',
              '100',
            ],
            showSizeChanger: true,
            total: this.props.filteredWinnersCount,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {}, // click row
            };
          }}
        />
      </Drawer>
    );
  }

  //////////////////////////////////// DRAWER END |||||||||||||||||||||||||||||||||||||||||||||||
  renderTransaction(record) {
    const columns = [
      {
        title: 'Bet Combination',
        dataIndex: 'bp_bet',
        key: 'bp_bet',
      },
      {
        title: 'Drawn',
        dataIndex: 'is_drawn',
        key: 'is_drawn',
        render: (text, record) => {
          console.log(
            record.draw_date,
            this.state.selectedDrawDate,
            record.is_drawn
          );
          if (
            record.draw_date === this.state.selectedDrawDate &&
            record.is_drawn
          ) {
            return <Tag color="green">Yes</Tag>;
          } else {
            return <Tag color="gray">No</Tag>;
          }
        },
      },
      {
        title: 'Win',
        dataIndex: 'is_win',
        key: 'is_win',
        render: (text, record) => {
          if (
            record.draw_date === this.props.selectedDrawDate &&
            record.is_win
          ) {
            return <Tag color="green">Yes</Tag>;
          } else {
            return <Tag color="gray">No</Tag>;
          }
        },
      },
    ];
    return (
      <div className="transaction_expand">
        <Descriptions>
          <Descriptions.Item label="User id:">
            {record.user_id}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label="Draw Date/s:">
            {Object.entries(record.draw_dates).map(([key, value]) => {
              return (
                <Tag
                  onClick={() => {
                    this.resetTable();
                    if (this.state.selectedDrawDate === value)
                      this.state.selectedDrawDate = '';
                    else this.state.selectedDrawDate = value;
                  }}
                  color={this.state.selectedDrawDate === value ? 'green' : null}
                >
                  {value}
                </Tag>
              );
            })}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label="Bets:" />
        </Descriptions>
        <Table
          columns={columns}
          dataSource={record.bp_bets}
          pagination={false}
        />
      </div>
    );
  }

  Export() {
    setTimeout(
      function () {
        message.info('exporting');

        this.csvLink.link.click();
        this.setState({
          loading: false,
          exportlist: [
            ['Bets Per Draw Report '],
            ['Date Today', moment(new Date()).format('LLLL')],
            [
              'Draw Date',
              'Bet Combination',
              'Number of Winners',
              'Number of Bets',
            ],
          ],
        });
      }.bind(this),
      2000
    );
  }

  render() {
    return (
      <>
        <div className="generic-container-game">
          <CSVLink
            data={this.state.exportlist}
            filename={`Bets Per Draw.csv`}
            target="_blank"
            className="hiddencsv"
            ref={(r) => (this.csvLink = r)}
          ></CSVLink>
          <PageHeader
            title={this.props.currentGame.game_name}
            onBack={() => window.history.back()}
            subTitle=""
            extra={
              <div className="header">
                <SendNotificationModal />
              </div>
            }
          ></PageHeader>
          <div className="content-container-game">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Bets" disabled={!this.state.read12} key="1">
                <PageHeader
                  title="Bets"
                  subTitle=""
                  extra={
                    <div className="header">
                      <Button
                        onClick={() => {
                          this.DoDateFilter();
                        }}
                        type={
                          this.state.selectedDateRange.length > 0
                            ? 'primary'
                            : 'dashed'
                        }
                        danger={this.state.dateFilterAcivated}
                      >
                        {this.state.dateFilterAcivated
                          ? 'clear'
                          : 'Filter by date'}
                      </Button>
                      <RangePicker
                        onChange={(val) => {
                          this.setState({ selectedDateRange: val });
                        }}
                        allowClear={false}
                        disabledDate={(current) => {
                          return current > moment().endOf('day');
                        }}
                        value={this.state.selectedDateRange}
                      />
                      <ExportDated
                        admin_gateway={this.props.currentGame.api_gateway_admin}
                        dateRange={this.state.selectedDateRange}
                        filter={this.state.filters_gameTransactions}
                      />

                      {/* <Search
                        placeholder="Search Reference number"
                        onSearch={(value) => this.onRefNumberSearch(value)}
                        style={{ width: 300, marginLeft: '0.5vmax' }}
                      /> */}
                      <Button
                        onClick={() => {
                          this.loadTransactions(
                            this.state.defaultpageSize_gameTransactions,
                            this.state.page_gameTransactions,
                            this.state.sorter_gameTransactions,
                            this.state.filters_gameTransactions,
                            this.state.searchText_gameTransactions,
                            this.state.pageSize_gameTransactions
                          );
                        }}
                        className="button"
                      >
                        <LegacyIcon type="reload" /> RELOAD
                      </Button>
                    </div>
                  }
                ></PageHeader>
                {this.state.read12 ? (
                  <Table
                    loading={this.state.loading_gameTransactions}
                    dataSource={this.state.gameTransactions}
                    columns={this.state.TRANSACTION_COLUMNS}
                    sorter={true}
                    expandRowByClick={true}
                    expandedRowRender={(record) =>
                      this.renderTransaction(record)
                    }
                    pagination={{
                      onChange: (page, pageSize) => {
                        this.onChangePage_gameTransactions(page, pageSize);
                      },
                      onShowSizeChange: (current, size) => {
                        this.onChangePageSize_gameTransactions(current, size);
                      },
                      current: this.state.page_gameTransactions,
                      showQuickJumper: true,
                      defaultPageSize: this.state
                        .defaultpageSize_gameTransactions,
                      pageSizeOptions: [
                        this.state.defaultpageSize_gameTransactions,
                        '20',
                        '50',
                        '100',
                      ],
                      showSizeChanger: true,
                      total: this.props.gameTransactionsCount,
                    }}
                    scroll={{ x: 'auto' }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {}, // click row
                      };
                    }}
                  />
                ) : null}
              </TabPane>

              <TabPane
                tab="Previous Draws"
                disabled={!this.state.read13}
                key="2"
              >
                <PageHeader
                  title="Draws"
                  subTitle=""
                  extra={
                    <div className="header">
                      <Button
                        onClick={() => {
                          this.loadDraws(
                            this.state.defaultpageSize,
                            0,
                            null,
                            null,
                            null,
                            1
                          );
                        }}
                        className="button"
                      >
                        <LegacyIcon type="reload" /> RELOAD
                      </Button>
                    </div>
                  }
                ></PageHeader>
                <Table
                  loading={this.state.loading_draws}
                  dataSource={this.state.draws}
                  columns={this.state.DRAW_COLUMNS}
                  sorter={true}
                  pagination={{
                    onChange: (page, pageSize) => {
                      this.onChangePage_draws(page, pageSize);
                    },
                    showTotal: (total, range) => {
                      this.onChangeRange_draws(total, range);
                    },
                    onShowSizeChange: (current, size) => {
                      this.onChangePageSize_draws(current, size);
                    },
                    current: this.state.page_draws,
                    showQuickJumper: true,
                    defaultPageSize: this.state.defaultpageSize_draws,
                    pageSizeOptions: [
                      this.state.defaultpageSize_draws,
                      '20',
                      '50',
                      '100',
                    ],
                    showSizeChanger: true,
                    total: this.props.drawsCount,
                  }}
                  scroll={{ x: 'auto' }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (e) => {
                        this.openDrawer(record);
                        e.stopPropagation();
                      }, // click row
                    };
                  }}
                  onChange={this.handleTableChange_draws}
                />
              </TabPane>

              <TabPane tab="New Draws" disabled={!this.state.create13} key="3">
                <PageHeader
                  title="New Draws"
                  subTitle=""
                  extra={
                    <div className="header">
                      {this.state.create13 ? (
                        <AddDrawDate
                          currentgame_api={
                            this.props.currentGame.api_gateway_admin
                          }
                        />
                      ) : null}
                      <Button
                        onClick={() => {
                          this.loadNewDraws(
                            this.state.defaultpageSize,
                            0,
                            null,
                            null,
                            null,
                            1
                          );
                        }}
                        className="button"
                      >
                        <LegacyIcon type="reload" /> RELOAD
                      </Button>
                    </div>
                  }
                ></PageHeader>
                <Table
                  loading={this.state.loading_new_draws}
                  dataSource={this.state.newdraws}
                  columns={this.state.NEW_DRAW_COLUMNS}
                  sorter={true}
                  pagination={{
                    onChange: (page, pageSize) => {
                      this.onChangePage_new_draws(page, pageSize);
                    },
                    showTotal: (total, range) => {
                      this.onChangeRange_new_draws(total, range);
                    },
                    onShowSizeChange: (current, size) => {
                      this.onChangePageSize_new_draws(current, size);
                    },
                    current: this.state.page_new_draws,
                    showQuickJumper: true,
                    defaultPageSize: this.state.defaultpageSize_new_draws,
                    pageSizeOptions: [
                      this.state.defaultpageSize_new_draws,
                      '20',
                      '50',
                      '100',
                    ],
                    showSizeChanger: true,
                    total: this.props.newdrawsCount,
                  }}
                  scroll={{ x: 'auto' }}
                  onChange={this.handleTableChange_new_draws}
                />
              </TabPane>
            </Tabs>
          </div>

          {this.state.read13 ? this.renderDrawer() : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.crud_data.data,
    currentGame: state.game_data.currentGame,
    gameTransactions: state.game_data.gameTransactions,
    gameTransactionsCount: state.game_data.gameTransactionsCount,
    draws: state.game_data.draws,
    drawsCount: state.game_data.drawsCount,
    newdraws: state.game_data.newdraws,
    newdrawsCount: state.game_data.newdrawsCount,

    filteredWinners: state.game_data.filteredWinners,
    filteredWinnersCount: state.game_data.filteredWinnersCount,

    user_id: state.auth.user.user_id,
    user_role: state.auth.user.user_role,
    user_admin: state.auth.user.admin,

    draw_filter: state.draw_filter,

    betsPerDrawId: state.report_data.betsPerDrawId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    searchData: (filter, data) => dispatch(searchData(filter, data)),
    openEdit: (item, editModalOpen) => dispatch(openEdit(item, editModalOpen)),
    passCurrentGame: (currentGame, token) =>
      dispatch(passCurrentGame(currentGame, token)),
    getGameTransactions: (
      gameAdminApi,
      getTransactionsUrl,
      token,
      controllerText
    ) =>
      dispatch(
        getGameTransactions(
          gameAdminApi,
          getTransactionsUrl,
          token,
          controllerText
        )
      ),
    getGameDraws: (currentGame, token, controller, page, size) =>
      dispatch(getGameDraws(currentGame, token, controller, page, size)),
    getGameNewDraws: (currentGame, token, controller, page, size) =>
      dispatch(getGameNewDraws(currentGame, token, controller, page, size)),
    getFilteredWinners: (draw_id, token, controller, onFail) =>
      dispatch(getFilteredWinners(draw_id, token, controller, onFail)),
    checkAuthAccess: (user_role, screen, action, token) =>
      dispatch(checkAuthAccess(user_role, screen, action, token)),
    getTicketTransaction: (ref_number, gameAdminApi, token) =>
      dispatch(getTicketTransaction(ref_number, gameAdminApi, token)),
    getAllBetsByDrawDateById: (token, drawId) =>
      dispatch(getAllBetsByDrawDateById(token, drawId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);
