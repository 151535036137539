import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../auth/actions';
import { checkAuthAccess } from '../../actions';
import './index.scss';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { OneToOneOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

const { Sider } = Layout;
const { SubMenu } = Menu;

class CustomLayout extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
    //console.log(this.state)
  };

  componentDidMount() {
    this.checkAccess('1', 'read');
    this.checkAccess('2', 'read');
    this.checkAccess('3', 'read');
    this.checkAccess('4', 'read');
    this.checkAccess('5', 'read');
    this.checkAccess('6', 'read');
    this.checkAccess('7', 'read');
    this.checkAccess('8', 'read');
    this.checkAccess('9', 'read');
    this.checkAccess('10', 'read');
    this.checkAccess('11', 'read');
    this.checkAccess('14', 'read');
    this.checkAccess('15', 'read');
  }

  checkAccess(screen, action) {
    var name = action + screen;

    if (this.props.user_admin) {
      this.setState({ [name]: true });
    } else {
      this.setState({ [name]: false });
      this.props
        .checkAuthAccess(this.props.user_role, screen, action, this.props.token)
        .then((res) => {
          var permission = res.data.results[0];
          if (permission[action] && action) {
            this.setState({ [name]: true });
          } else {
            this.setState({ [name]: false });
          }
        })
        .catch((err) => {
          this.setState({ [name]: false });
        });
    }
  }
  render() {
    if (this.props.hide) {
      return this.props.children;
    } else {
      return (
        <Layout className="layout">
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            className="layout-sidebar"
          >
            <div className="flipsidebar">
              <div className="logo" />
              <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                <Menu.Item key="1">
                  <Link to="/">
                    <LegacyIcon type="home" />
                    <span>Home</span>
                  </Link>
                </Menu.Item>
                {this.state.read1 ? (
                  <Menu.Item key="games">
                    <Link to="/games">
                      <LegacyIcon type="appstore" />
                      <span>Games</span>
                    </Link>
                  </Menu.Item>
                ) : null}
                {this.state.read2 ? (
                  <Menu.Item key="3">
                    <Link to="/users">
                      <LegacyIcon type="team" />
                      <span>Users</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                {this.state.read14 ? (
                  <Menu.Item key="14">
                    <Link to="/userverification">
                      <OneToOneOutlined />
                      <span>User Verification</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                {this.state.read14 ? (
                  <Menu.Item key="15">
                    <Link to="/userAgeVerification">
                      <OneToOneOutlined />
                      <span>User Age Verification</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                {this.state.read9 ? (
                  <SubMenu
                    key="Access"
                    title={
                      <span>
                        <LegacyIcon type="lock" />
                        <span>Access</span>
                      </span>
                    }
                  >
                    {this.state.read3 ? (
                      <Menu.Item key="roles">
                        <Link to="/roles">
                          <span>Roles</span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.user_admin ? (
                      <Menu.Item key="screens">
                        <Link to="/screens">
                          <span>Screens</span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {this.state.read4 ? (
                      <Menu.Item key="permissions">
                        <Link to="/permissions">
                          <span>Permissions</span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : null}
                {this.state.read10 ? (
                  <SubMenu
                    key="outlets"
                    defaultSelectedKeys={['outlets']}
                    title={
                      <span>
                        <LegacyIcon type="global" />
                        <span>Outlets</span>
                      </span>
                    }
                  >
                    {this.state.read5 ? (
                      <Menu.Item key="outlets">
                        <Link to="/outlets">
                          <span>Outlets</span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                    {this.state.read6 ? (
                      <Menu.Item key="pos">
                        <Link to="/pos">
                          <span>POS</span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : null}
                {this.state.read7 ? (
                  <Menu.Item key="5">
                    <Link to="/transactions">
                      <LegacyIcon type="container" />
                      <span>Transactions</span>
                    </Link>
                  </Menu.Item>
                ) : null}
                {this.state.read11 ? (
                  <SubMenu
                    defaultSelectedKeys={['userlogs']}
                    key="logs"
                    title={
                      <span>
                        <LegacyIcon type="bars" />
                        <span>Logs</span>
                      </span>
                    }
                  >
                    {this.state.read8 ? (
                      <Menu.Item key="userlogs">
                        <Link to="/userlogs">
                          <span>Admin User Logs</span>
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : null}

                {/* {this.state.read11? 
              <SubMenu
                defaultSelectedKeys ={['userlogs']}
                key="serverhealth"
                title={
                  <span>
                    <Icon type="dashboard" />
                    <span>Server Monitoring</span>
                  </span>
                }
              >
                {this.state.read8? 
                <Menu.Item key="authhealth"> 
                  <Link to ="/authhealth">           
                    <span>Auth Server</span>
                  </Link>
                </Menu.Item>
                :null}
                
              </SubMenu>   
            :null} */}
                {this.state.read15 ? (
                  <Menu.Item key="reports">
                    <Link to="/reports">
                      <LegacyIcon type="book" />
                      <span>Reports</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                <SubMenu
                  key="settings"
                  title={
                    <span>
                      <LegacyIcon type="setting" />
                      <span>Settings</span>
                    </span>
                  }
                >
                  {/* <Menu.Item key="7">
                  <Link to ="/account"> 
                    <Icon type="user" />
                    <span>Account</span>
                  </Link> 
                </Menu.Item> */}
                  <Menu.Item key="8">
                    <Link to="/requests">
                      <LegacyIcon type="customer-service" />
                      <span>Support</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="9">
                    <Link
                      to="/"
                      onClick={() => {
                        this.props.logout(this.props.token, this.props.user_id);
                      }}
                    >
                      <LegacyIcon type="logout" />
                      <span>Logout</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Sider>
          <Layout
            className={
              this.state.collapsed
                ? 'layout-content-collapsed'
                : 'layout-content'
            }
          >
            {this.props.children}
          </Layout>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    user_id: state.auth.user.user_id,
    user_role: state.auth.user.user_role,
    user_admin: state.auth.user.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (token, user_id) => dispatch(actions.logout(token, user_id)),
    checkAuthAccess: (user_role, screen, action, token) =>
      dispatch(checkAuthAccess(user_role, screen, action, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayout);

/* 
<Layout className="layout">
<Header>
  <div className="logo" />
  <Menu
    theme="dark"
    mode="horizontal"
    defaultSelectedKeys={['1']}
    style={{ lineHeight: '64px' }}
  >
    <Menu.Item key="1">    <Link to ="/">Home</Link>     </Menu.Item> 
    <Menu.Item key="2">    <Link to ="/" onClick={this.props.logout}>Logout</Link></Menu.Item>
      
    
  </Menu>
</Header>
    
<Content style={{ padding: '0 50px' }}>            
  <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
      {this.props.children}
  </div>
</Content>
</Layout>




'api/requests'
'api/outlets'
'api/userlogs'
'api/transactions'
'api/gameslist'
'api/games'
'api/users'

*/
