import {
  GET_DAILY_USER_CREDITS,
  GET_DAILY_USER_CREDITS_START,
  GET_DAILY_BETS_PRICE,
  GET_DAILY_PAYMAYA_PAYMENTS,
  GET_DAILY_GRABPAY_PAYMENTS,
  GET_DAILY_GCASH_PAYMENTS,
  GET_DAILY_UNDRAWN_COUNT,
  GET_DAILY_BETS_COUNT,
  GET_WILL_DRAW_COUNT,
  RESET_REPORTS_DATA,
  GENERATE_DAILY_WALLET_COUNT,
  GENERATE_DAILY_WALLET_START,
  GET_DAILY_BETS_PRICE_START,
  GET_DAILY_BETS_COUNT_START,
  GET_ALL_BETS_PER_DRAW,
  GET_ALL_BETS_PER_DRAW_ID_START,
  GET_ALL_BETS_PER_DRAW_ID,
  GET_ALL_WALLET_IN,
  GET_ALL_WALLET_OUT,
  GET_CURRENT_WALLET_PER_USER,
  GET_GATEWAY_CHARGES,
  GET_GATEWAY_CHARGES_START,
  GET_RANGED_USER_CREDITS,
  GET_RANGED_BETS_PRICE_START,
  GET_RANGED_BETS_PRICE,
  GENERATE_RANGED_WALLET_COUNT,
  GENERATE_RANGED_WALLET_START,
  GET_RANGED_BETS_COUNT,
  GET_RANGED_BETS_COUNT_START,
  GET_STARTING_WALLET_IN,
  GET_STARTING_WALLET_IN_START,
  GET_STARTING_WALLET_OUT,
  GET_STARTING_WALLET_OUT_START,
  GET_RANGED_GATEWAY_CHARGES_START,
  GET_RANGED_GATEWAY_CHARGES,
} from '../actions/types';

const game_data = {
  userData: [],
  betsData: [],
  walletData: [],
  undrawnData: [],
  willDrawData: [],
  dailyWalletCount: [],
  dailyTransactionsData: [],
  betsPerDraw: [],
  currentWallet: [],
  walletIn: [],
  walletOut: [],
  gatewayCharges: [],

  rangedBetData: [],
  rangedUserData: [],
  rangedWalletCount: [],

  rangedTransactionsData: [],
  rangedGatewayCharges: [],
  startingWalletIn: [],
  startingWalletOut: [],

  betsPerDrawId: [],
};

export default (state = game_data, action) => {
  switch (action.type) {
    case GET_DAILY_USER_CREDITS:
      return {
        ...state,
        userData: action.payload.data,
      };
    case GET_DAILY_USER_CREDITS_START:
      return {
        ...state,
        userData: [],
      };
    case GET_DAILY_BETS_PRICE_START:
      return {
        ...state,
        betsData: [],
      };
    case GET_DAILY_BETS_PRICE:
      return {
        ...state,
        betsData: action.payload.data,
      };
    case GENERATE_DAILY_WALLET_START:
      return {
        ...state,
        dailyWalletCount: [],
      };
    case GENERATE_DAILY_WALLET_COUNT:
      return {
        ...state,
        dailyWalletCount: action.payload.data,
      };
    case GET_DAILY_BETS_COUNT_START:
      return {
        ...state,
        dailyTransactionsData: [],
      };
    case GET_DAILY_BETS_COUNT:
      if (action.payload.data.length > 0)
        return {
          ...state,
          dailyTransactionsData: action.payload.data,
        };
      else
        return {
          ...state,
          dailyTransactionsData: 0,
        };
    case GET_RANGED_USER_CREDITS:
      return {
        ...state,
        rangedUserData: action.payload.data,
      };
    case GET_RANGED_BETS_PRICE_START:
      return {
        ...state,
        rangedBetData: [],
      };
    case GET_RANGED_BETS_PRICE:
      return {
        ...state,
        rangedBetData: action.payload.data,
      };

    case GET_RANGED_GATEWAY_CHARGES_START:
      return {
        ...state,
        rangedGatewayCharges: [],
      };
    case GET_RANGED_GATEWAY_CHARGES:
      return {
        ...state,
        rangedGatewayCharges: action.payload.data,
      };
    case GET_STARTING_WALLET_OUT_START:
      return {
        ...state,
        startingWalletOut: [],
      };
    case GET_STARTING_WALLET_OUT:
      return {
        ...state,
        startingWalletOut: action.payload.data,
      };
    case GET_STARTING_WALLET_IN_START:
      return {
        ...state,
        startingWalletIn: [],
      };
    case GET_STARTING_WALLET_IN:
      return {
        ...state,
        startingWalletIn: action.payload.data,
      };
    case GENERATE_RANGED_WALLET_COUNT:
      return {
        ...state,
        rangedWalletCount: action.payload.data,
      };
    case GENERATE_RANGED_WALLET_START:
      return {
        ...state,
        rangedWalletCount: [],
      };
    case GET_RANGED_BETS_COUNT_START:
      return {
        ...state,
        rangedTransactionsData: [],
      };
    case GET_RANGED_BETS_COUNT:
      if (action.payload.data.length > 0)
        return {
          ...state,
          rangedTransactionsData: action.payload.data,
        };
      else
        return {
          ...state,
          rangedTransactionsData: 0,
        };
    case GET_DAILY_PAYMAYA_PAYMENTS:
      return {
        ...state,
        paymayaData: action.payload.data,
      };
    case GET_DAILY_GRABPAY_PAYMENTS:
      return {
        ...state,
        grabpayData: action.payload.data,
      };
    case GET_GATEWAY_CHARGES_START:
      return {
        ...state,
        gatewayCharges: [],
      };
    case GET_GATEWAY_CHARGES:
      return {
        ...state,
        gatewayCharges: action.payload.data,
      };
    case GET_ALL_BETS_PER_DRAW:
      return {
        ...state,
        betsPerDraw: action.payload.data,
      };
    case GET_ALL_BETS_PER_DRAW_ID_START:
      return {
        ...state,
        betsPerDrawId: [],
      };
    case GET_ALL_BETS_PER_DRAW_ID:
      return {
        ...state,
        betsPerDrawId: action.payload.data,
      };

    case GET_DAILY_GCASH_PAYMENTS:
      return {
        ...state,
        gcashData: action.payload.data,
      };

    case GET_DAILY_UNDRAWN_COUNT:
      if (action.payload.data.length > 0)
        return {
          ...state,
          undrawnData: action.payload.data,
        };
      else
        return {
          ...state,
          undrawnData: 0,
        };

    case GET_WILL_DRAW_COUNT:
      if (action.payload.data.length > 0)
        return {
          ...state,
          willDrawData: action.payload.data,
        };
      else
        return {
          ...state,
          willDrawData: 0,
        };
    case GET_CURRENT_WALLET_PER_USER:
      return {
        ...state,
        currentWallet: action.payload.data,
      };
    case GET_ALL_WALLET_IN:
      return {
        ...state,
        walletIn: action.payload.data,
      };
    case GET_ALL_WALLET_OUT:
      return {
        ...state,
        walletOut: action.payload.data,
      };
    case RESET_REPORTS_DATA:
      return {
        ...state,
        data: [],
      };

    default:
      return state;
  }
};
