import React, { Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Select, Popconfirm, message, DatePicker } from 'antd';

import { connect } from 'react-redux';
import moment from 'moment';

import './index.scss';

import { addDrawDate, getGameNewDraws } from '../../../../actions';
import 'antd/dist/antd.css';

const { Option } = Select;
const { confirm } = Modal;

class AddDrawDate extends Component {
  state = {
    loading: false,
    visible: false,
    drawdate: null,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  componentDidUpdate() {}

  onClose() {}

  submitConfirm() {
    confirm({
      title: 'Are you sure want to add this item?',
      content: 'please double check',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.drawNewWinners();
      },
      onCancel() {},
    });
  }

  handleCancel = () => {
    if (!this.state.loading) {
      this.setState({ visible: false });
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  addDrawDate() {
    if (this.state.drawdate) {
      this.setState({ loading: true });
      this.props
        .addDrawDate(
          this.state.drawdate,
          this.props.currentgame_api,
          this.props.token
        )
        .then((res) => {
          if (res.data.is_success) {
            this.setState({ visible: false, loading: false });
          } else {
            this.setState({ loading: false });

            message.error('Draw Date add Failed');
          }
        })
        .catch((err) => {
          message.error(err.message);
          this.setState({ loading: false });
        });
    } else {
      message.error('Please Select a Date');
    }
  }

  render() {
    const { visible, loading } = this.state;
    return (
      <div>
        <Button type="primary" onClick={this.showModal} className="add-button">
          <LegacyIcon type="plus" />
          Add Draw Date
        </Button>
        {visible ? (
          <Modal
            className="add-modal"
            visible={visible}
            title="Add Draw Date"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            afterClose={this.onClose}
            footer={
              <>
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>

                <Popconfirm
                  title={`Are you sure you want tot addd this draw date?`}
                  onConfirm={() => this.addDrawDate()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button key="submit" type="primary" loading={loading}>
                    Submit
                  </Button>
                </Popconfirm>
              </>
            }
          >
            <DatePicker
              onChange={(date, datestring) => {
                this.onChange('drawdate', datestring);
              }}
              format={'MM-DD-YYYY'}
              disabledDate={(current) => {
                return current < moment().endOf('day');
              }}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDrawDate: (draw_date, currentGame, token) =>
      dispatch(addDrawDate(draw_date, currentGame, token)),
    getGameNewDraws: (currentGame, token, controller, page, size) =>
      dispatch(getGameNewDraws(currentGame, token, controller, page, size)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDrawDate);
