import React, { Component } from 'react';

import {
  PageHeader,
  Modal,
  Pagination,
  Input,
  Button,
  Table,
  Select,
  Spin,
  Switch,
  message,
  Popover,
  List,
  Cascader,
  InputNumber,
  Statistic,
  Popconfirm,
  DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import './index.scss';

import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';

import {
  getData,
  addItem,
  editItem,
  deleteItem,
  openEdit,
  getOptions,
} from '../../../actions';
import axios from 'axios';
import { CSVLink } from 'react-csv';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const Headers = [
  { label: 'created_at', key: 'created_at' },
  { label: 'amount', key: 'amount' },
  { label: 'reference', key: 'reference' },
  { label: 'claimed', key: 'claimed' },
  { label: 'claimed_at', key: 'claimed_at' },
  { label: 'paid', key: 'paid' },
  { label: 'paid_at', key: 'paid_at' },
  { label: 'payment_gateway', key: 'payment_gateway' },
  { label: 'user', key: 'user' },
  { label: 'cp_num', key: 'cp_num' },
  { label: 'void', key: 'void' },
  { label: 'void_at', key: 'void_at' },
  { label: 'void_reason', key: 'void_reason' },
  { label: 'updated_at', key: 'updated_at' },
];

class Export extends Component {
  state = {
    loading: false,
    visible: false,
    data: [],
    exportlist: [],
    newHeaders: [],
  };

  exportData() {
    if (this.props.selectedData.length > 0 && this.state.loading == false) {
      this.setState({ loading: true });
      setTimeout(
        function () {
          message.info('exporting');
          this.csvLink.link.click();
          this.setState({ loading: false });
        }.bind(this),
        2000
      );
    } else if (this.props.selectedData.length < 1) {
      message.error('No items selected');
    } else if (this.state.loading) {
      message.error('Please wait retreiving data');
    }
  }

  render() {
    return (
      <div>
        <CSVLink
          data={this.props.selectedData}
          headers={Headers}
          filename={`transactions.csv`}
          target="_blank"
          className="hiddencsv"
          ref={(r) => (this.csvLink = r)}
        ></CSVLink>
        <Button
          type="primary"
          className="add-button"
          onClick={() => {
            this.exportData();
          }}
          loading={this.state.loading}
        >
          <DownloadOutlined />
          Export Selected
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.crud_data.data,
    editModalOpen: state.crud_data.editModalOpen,
    item: state.crud_data.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    addItem: (token, data, apiurl) => dispatch(addItem(token, data, apiurl)),
    editItem: (token, data, apiurl, id) =>
      dispatch(editItem(token, data, apiurl, id)),
    deleteItem: (token, apiurl, id) => dispatch(deleteItem(token, apiurl, id)),
    openEdit: (item, editModalOpen) => dispatch(openEdit(item, editModalOpen)),
    getOptions: (token, apiurl, controller) =>
      dispatch(getOptions(token, apiurl, controller)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
