import {
  GET_DATA_AUTH,
  SEARCH_DATA_AUTH,
  ADD_ITEM_AUTH,
  EDIT_ITEM_AUTH,
  OPEN_EDIT_AUTH,
  OPEN_PREVIEW_AUTH,
  RESET_DATA_AUTH,
  VERIFY_PASSWORD_START,
  VERIFY_PASSWORD_SUCCESS,
  AUTH_LOGOUT,
  VERIFY_PASSWORD_FAIL,
} from './types';
import axios from 'axios';

export const getData_AUTH = (token, apiurl, controller) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(process.env.REACT_APP_AUTH_SERVER + apiurl + controller)
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_DATA_AUTH,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_DATA_AUTH,
          payload: { data: null },
        });
      });
  };
};

export const searchData_AUTH = (filter, data) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_DATA_AUTH,
      filter: filter,
      data: data,
    });
  };
};

export const addItem_AUTH = (data, apiurl, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(process.env.REACT_APP_AUTH_SERVER + apiurl, data);
  };
};

export const openEdit_AUTH = (item, editModalOpen) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_EDIT_AUTH,
      item: item,
      editModalOpen: editModalOpen,
    });
  };
};

export const editItem_AUTH = (data, apiurl, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios
      .patch(process.env.REACT_APP_AUTH_SERVER + apiurl + `${id}/`, data)
      .then((res) => {});
  };
};

export const userVerify_AUTH = (verify, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    var data = {
      user_id: id,
      is_verify: verify,
    };
    return axios.patch(
      process.env.REACT_APP_AUTH_SERVER + 'userverifyitem',
      data
    );
  };
};

export const ageVerifyAuth = (verify, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    var data = {
      user_id: id,
      age_verify: verify,
    };
    return axios.patch(
      process.env.REACT_APP_AUTH_SERVER + 'userverifyitem',
      data
    );
  };
};

export const verifyPassword = (password, onSuccess, onFail, onLock) => {
  return (dispatch, getState) => {
    const authState = getState().auth;

    const data = {
      password: password,
    };
    console.log(getState());

    dispatch({ type: VERIFY_PASSWORD_START });

    return axios.post(
      process.env.REACT_APP_AUTH_SERVER + 'verify_password',
      data,
      {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      }
    );
  };
};

export const deleteItem_AUTH = (apiurl, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios.delete(process.env.REACT_APP_AUTH_SERVER + apiurl + `${id}/`);
  };
};

export const openPreview_AUTH = (item, previewModalOpen) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_PREVIEW_AUTH,
      item: item,
      previewModalOpen: previewModalOpen,
    });
  };
};

export const getOptions_AUTH = (token, apiurl, controller) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios.get(process.env.REACT_APP_AUTH_SERVER + apiurl + controller);
  };
};

export const logAction_AUTH = (data, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios.post(
      process.env.REACT_APP_AUTH_SERVER + 'api/userActionLogs/',
      data
    );
  };
};

export const checkAuthAccess = (user_role, screen, action, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    if (action) {
      return axios.get(
        process.env.REACT_APP_AUTH_SERVER +
          'user_permissions/' +
          `?limit=${1}&offset=${0}` +
          `&user_role=${user_role}` +
          `&screen=${screen}`
      );
    } else {
      return axios.get(
        process.env.REACT_APP_AUTH_SERVER +
          'user_permissions/' +
          `?limit=${0}&offset=${0}`
      );
    }
  };
};

export const logout = (token = null, user_id = null) => {
  return (dispatch) => {
    if (token !== null && token !== undefined && user_id !== null) {
      dispatch({
        type: AUTH_LOGOUT,
      });
      // localStorage.removeItem('token');
      // localStorage.removeItem('user');
      // localStorage.removeItem('expirationDate');
      // window.reload('/');
    }
  };
};
