import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  PageHeader,
  Modal,
  Pagination,
  Input,
  Button,
  Table,
  Select,
  Spin,
  Switch,
  message,
  Drawer,
  Tag,
} from 'antd';
import { connect } from 'react-redux';

import ImageChecker from '../ImageChecker';
import './index.scss';

import { openPreview, getOptions } from '../../actions';

import { resolve } from 'dns';
const URLprefix = `https://s3-${process.env.REACT_APP_S3_BUCKET_REGION}.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_COMPANY}/sku/`;

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;
class PreviewDrawer extends Component {
  state = {
    loading: false,
    visible: false,
  };

  componentDidUpdate() {
    if (this.state.visible !== this.props.previewModalOpen) {
      this.setState({
        visible: this.props.previewModalOpen,

        ...this.props.item,
      });
    }
  }

  closeDrawer() {
    this.props.openPreview(null, false);
  }

  renderOptions(key) {
    var optionsName = key.split('_id')[0] + 'Options';

    if (this.state[optionsName]) {
      var options = Object.entries(this.state[optionsName]).map(
        ([key, value]) => {
          var optionValue = '';
          var optionName = '';
          Object.entries(value).map(([key2, value2]) => {
            if (key2.includes('id')) {
              optionValue = value2;
            }
            if (key2.includes('name')) {
              optionName = value2;
            } else if (key2.includes('email')) {
              optionName = value2;
            }
          });

          return <Option value={optionValue}>{optionName}</Option>;
        }
      );
      return options;
    } else {
      this.getOptions(key);
    }
  }

  renderDetails() {
    var renderform = Object.entries(this.props.blueprint).map(
      ([key, value]) => {
        if (
          key !== '0' &&
          value.key !== 'created_at' &&
          value.key !== 'date' &&
          value.key !== 'last_login' &&
          value.key !== 'name'
        ) {
          if (value.type === 'text-area') {
            return (
              <div className="formitem text-area">
                <span className="key">{value.title + ' : '}</span>
                {this.state[value.key] ? (
                  <span className="value">{this.state[value.key]}</span>
                ) : (
                  <span className="error">none</span>
                )}
              </div>
            );
          } else if (value.type === 'boolean') {
            return (
              <div className="formitem text">
                <span className="key">{value.title + ' : '}</span>
                {this.state[value.key] ? (
                  <span className="value">yes</span>
                ) : (
                  <span className="value">no</span>
                )}
              </div>
            );
          } else if (value.type === 'options') {
            return null;
          } else if (value.type === 'picture') {
            return (
              <div className="formitem picture">
                <ImageChecker
                  url={URLprefix + this.state.id + `.jpg`}
                  success={
                    <div className="picture-container">
                      <img
                        src={URLprefix + this.state.id + `.jpg`}
                        className="picture"
                      />
                    </div>
                  }
                  fail={
                    <div className="picture-container">
                      <LegacyIcon type="file-image" />
                    </div>
                  }
                />
              </div>
            );
          } else if (value.type === 'password') {
            return null;
          } else if (value.type === 'dont') {
            return null;
          } else {
            return (
              <div className="formitem text">
                <span className="key">{value.title + ' : '}</span>
                {this.state[value.key] ? (
                  <span className="value">{this.state[value.key]}</span>
                ) : (
                  <span className="error">none</span>
                )}
              </div>
            );
          }
        }
      }
    );
    return renderform;
  }

  render() {
    const { visible, loading } = this.state;

    if (visible) {
      return (
        <Drawer
          placement="right"
          closable={false}
          destroyOnClose={true}
          onClose={() => {
            this.closeDrawer();
          }}
          visible={this.props.previewModalOpen}
          width={window.innerWidth < 800 ? '75vw' : '45vw'}
          title={
            <span>
              <LegacyIcon
                type="left"
                onClick={() => {
                  this.closeDrawer();
                }}
              />
              {this.state.name ? ' ' + ' ' + ' ' + this.state.name : null}
              {this.state.user_id ? ' ' + ' ' + ' ' + this.state.user_id : null}
            </span>
          }
        >
          <div className="sku-preview-drawer">{this.renderDetails()}</div>
        </Drawer>
      );
    } else {
      return <div></div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    user_id: state.auth.id,
    token: state.auth.token,
    data: state.crud_data.data,
    item: state.crud_data.item,
    previewModalOpen: state.crud_data.previewModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPreview: (item, previewModalOpen) =>
      dispatch(openPreview(item, previewModalOpen)),
    getOptions: (token, apiurl) => dispatch(getOptions(token, apiurl)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDrawer);
