import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Input,
  Button,
  Select,
  InputNumber,
  Popconfirm,
  message,
} from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './index.scss';

import {
  getData,
  addItem,
  getOptions,
  getGameDraws,
  drawWinners,
  addDraw,
  logAction,
  generateDrawCombination,
  getGameNewDraws,
  verifyPassword,
  logout,
  setInitialDraw,
} from '../../../../actions';
import 'antd/dist/antd.css';

const { Option } = Select;
const { confirm } = Modal;

const DRAW_API = 'api/draws/';
const WINNER_API = 'api/winners/';
class AddDraw extends Component {
  state = {
    loading: false,
    visible: false,
    drawCombination: '',
    drawCombination2: '',
    drawinit: '',
    password: '',
    passwordError: false,
    winnersDrawn: 'none',
    currentDraw: 'none',
    captcha: true,
    rngCombination: null,
    verifiedPassword: false,
  };

  showModal = () => {
    var draw = '';
    for (let i = 0; i < this.props.digitCount; i++) {
      draw += '0';
    }

    this.setState({
      visible: true,
      drawCombination: draw,
      drawCombination2: draw,
      drawinit: draw,
    });
  };

  componentDidUpdate() {
    console.log(this.state);
    if (this.state.winnersDrawn !== this.props.winnersDrawn) {
      this.setState({ winnersDrawn: this.props.winnersDrawn });
    }
    if (this.state.currentDraw !== this.props.currentDraw) {
      this.setState({ currentDraw: this.props.currentDraw });
    }
  }

  onClose() {}

  submitConfirm() {
    confirm({
      title: 'Are you sure want to add this item?',
      content: 'please double check',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.drawNewWinners();
      },
      onCancel() {},
    });
  }

  loadDraws(limit, offset, sort, filter, search) {
    console.log('hehe');
    if (this.props.token && this.props.currentGame) {
      var limitoffset = `?limit=${6}&offset=${0}`;
      var controllerText = limitoffset;

      this.props.getGameDraws(
        this.props.currentGame.api_gateway_admin,
        this.props.token,
        controllerText,
        1,
        8
      );
      this.props.getGameNewDraws(
        this.props.currentGame.api_gateway_admin,
        this.props.token,
        controllerText,
        1,
        8
      );
    }
  }

  handleSubmit = () => {
    var submitObject = {
      game: this.props.currentGame.game_id,
      winning_combination: this.state.drawCombination,
      user_id: this.props.user_id,
    };
    this.props
      .addDraw(submitObject, DRAW_API, this.props.token)
      .then((res) => {
        if (this.props.token) {
          this.loadDraws();
        }
        var winners = this.state.winnersDrawn;
        console.log(winners);
        var winnerCount = Object.keys(winners).length;

        if (winnerCount > 0) {
          this.submitWinners(winners, winnerCount, 0);
        } else {
          this.setState({
            loading: false,
            visible: false,
            drawCombination: '',
            drawCombination2: '',
            winnersDrawn: 'none',
            currentDraw: 'none',
          });
        }
      })
      .catch((e) => {
        message.error('admin server did not log draw,but game did draw');
        this.setState({
          loading: false,
          visible: false,
        });
      });
  };

  drawNewWinners() {
    this.setState({ loading: true });

    var dwApi = 'setDraw';
    if (
      this.props.token &&
      this.props.currentGame &&
      this.state.drawCombination &&
      this.props.drawdateID
    ) {
      this.props
        .drawWinners(
          this.props.currentGame.api_gateway_admin,
          dwApi,
          this.state.drawCombination,
          this.props.token,
          this.props.drawdateID
        )
        .then((res) => {
          if (res.data.is_success) {
            message.success('You have successfully set the draw.');
            this.loadDraws();
            this.setState({
              visible: false,
              captcha: true,
              rngCombination: null,
              verifiedPassword: false,
              password: '',
              passwordError: false,
            });
          } else if (!res.data.is_success) {
            if (res.data.user_match) {
              message.error(
                'Same user cannot set both combinations for a draw'
              );
            } else if (res.data.invalid_combination) {
              message.error('Invalid Date. Please input a valid date');
            } else if (res.data.no_match) {
              message.error('First combination has not been set');
            } else if (res.data.not_match) {
              message.error(
                'Draw Combination not matching with the first. Please set the draw again'
              );
              this.loadDraws();
              this.setState({
                visible: false,
                captcha: true,
                rngCombination: null,
                verifiedPassword: false,
                password: '',
                passwordError: false,
              });
            }
            this.setState({
              loading: false,
            });
          }
        })
        .catch((err) => {
          message.error('error: can not access game server');
          this.setState({
            loading: false,
            visible: false,
          });
        });
    }
  }

  setInitialDraw() {
    this.setState({ loading: true });
    var dwApi = 'setInitialDraw';

    if (
      this.props.token &&
      this.props.currentGame &&
      this.state.drawCombination &&
      this.props.drawdateID
    ) {
      this.props
        .setInitialDraw(
          this.props.currentGame.api_gateway_admin,
          dwApi,
          this.state.drawCombination,
          this.props.token,
          this.props.drawdateID
        )
        .then((res) => {
          if (res.data.is_success) {
            message.success('You have successfully set the draw.');
            this.loadDraws();
            this.setState({
              visible: false,
              captcha: true,
              rngCombination: null,
              verifiedPassword: false,
              password: '',
              passwordError: false,
            });
          } else if (!res.data.is_success) {
            if (res.data.invalid_date) {
              message.error('Invalid Date. Please input a valid date');
            } else if (res.data.is_drawn) {
              message.error('First combination has already been set');
              this.setState({
                visible: false,
                captcha: true,
                rngCombination: null,
                verifiedPassword: false,
                password: '',
                passwordError: false,
              });
            }
          }
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          message.error('error:', err);
          this.setState({
            loading: false,
            visible: false,
          });
        });
    }
  }

  submitWinners(winners, winnerCount, index) {
    //winners[index]['amount']
    var submitObject = {
      game: this.props.currentGame.game_id,
      winner_id: winners[index]['user_id'],
      user_id: this.props.user_id,
      draw: this.state.currentDraw.draw_id,
      ref_number: winners[index]['ref_num'],
      transaction_id: winners[index]['id'],
      prize_money: 200,
      is_redeemed: false,
      collected: false,
    };
    console.log(index + 1, winnerCount);
    console.log(submitObject);
    this.props
      .addItem(submitObject, WINNER_API, this.props.token)
      .then(() => {
        if (index + 1 < winnerCount) {
          this.submitWinners(winners, winnerCount, index + 1);
        } else {
          this.setState({
            loading: false,
            visible: false,
            drawCombination: '',
            drawCombination2: '',
            winnersDrawn: 'none',
            currentDraw: 'none',
          });
        }
      })
      .catch((err) => {
        message.error('error: draw was logged but winners not recorded');
        this.setState({
          loading: false,
          visible: false,
        });
      });
  }

  handleCancel = () => {
    if (!this.state.loading) {
      this.setState({
        visible: false,
        captcha: true,
        rngCombination: null,
        verifiedPassword: false,
        password: '',
        passwordError: false,
      });
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  onChangeCaptchaLogin(value) {
    this.setState({ captcha: false });
  }

  onChangeCombination = (key, value) => {
    var digit = value;
    if (Number.isInteger(digit)) {
      if (digit > 9) {
        digit = 9;
      }
      var draw = [...this.state.drawCombination];
      draw[key] = digit.toString();
      var combination = draw.toString().replace(/,/g, '');
      this.setState({ drawCombination: combination });
    }
  };

  onChangeCombination2 = (key, value) => {
    var digit = value;
    if (Number.isInteger(digit)) {
      if (digit > 9) {
        digit = 9;
      }
      var draw = [...this.state.drawCombination2];
      draw[key] = digit.toString();
      var combination = draw.toString().replace(/,/g, '');
      this.setState({ drawCombination2: combination });
    }
  };

  renderform() {
    let drawInput = [];

    for (let i = 0; i < this.props.digitCount; i++) {
      drawInput.push(
        <InputNumber
          className="digit"
          size="large"
          min={0}
          max={9}
          defaultValue={0}
          precision={0}
          onChange={(e) => {
            this.onChangeCombination(i, e);
          }}
          value={parseInt(this.state.drawCombination[i])}
        />
      );
    }
    var renderform = (
      <div className={classNames('formitem', 'center')}>
        <h1>{this.state.drawCombination}</h1>
        <div className="digit-group">{drawInput}</div>
      </div>
    );
    return renderform;
  }

  onSuccess() {
    this.setState({ verifiedPassword: true });
    message.success('Your password has been verified');
  }
  onFail(error = null) {
    message.error(error);
  }
  onLock(error = null) {
    message.error(error);
  }

  verifyPassword() {
    this.setState({ passwordError: false });
    if (this.state.password === '') {
      this.onFail('Please fill up field');
    } else {
      this.props
        .verifyPassword(this.state.password)
        .then((res) => {
          console.log(res);
          if (res.data.is_success) {
            if (res.data.is_match) {
              this.onSuccess();
            } else if (!res.data.is_match && !res.data.is_locked) {
              this.onFail('Incorrect Password');
            } else {
              this.onLock('Your account has been locked');
            }
          } else {
            this.onLock('Server error please login again.');
          }
        })
        .catch((err) => {
          this.onLock('Server error please login again.');
        });
    }
  }

  renderPassword() {
    var renderform = (
      <div className={classNames('formitem', 'center')}>
        <h2 className={`${this.state.passwordError ? 'error-color ' : null} `}>
          {this.state.passwordError
            ? this.state.passwordError
            : 'Enter Password'}
        </h2>
        <div className="digit-group">
          <Input.Password
            value={this.state.password}
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
          />
        </div>
        <div className="submitbutton">
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              this.verifyPassword();
            }}
            loading={this.state.loading}
          >
            Submit
          </Button>
        </div>
      </div>
    );
    return renderform;
  }
  renderform2() {
    let drawInput = [];

    for (let i = 0; i < this.props.digitCount; i++) {
      drawInput.push(
        <InputNumber
          className="digit"
          size="large"
          min={0}
          max={9}
          defaultValue={0}
          precision={0}
          onChange={(e) => {
            this.onChangeCombination2(i, e);
          }}
          value={parseInt(this.state.drawCombination2[i])}
        />
      );
    }
    var renderform = (
      <div className={classNames('formitem', 'center')}>
        <h1>{this.state.drawCombination2}</h1>
        <div className="digit-group">{drawInput}</div>
      </div>
    );
    return renderform;
  }

  getRNG() {
    this.props
      .generateDrawCombination(
        this.props.currentGame.api_gateway_admin,
        this.props.token
      )
      .then((res) => {
        this.setState({ rngCombination: res.data.draw_combination });
      })
      .catch((err) => {
        message.error('cant retrieve RNG');
      });
  }

  renderRNG(rngCombination) {
    return (
      <div className="rngform">
        <Button
          type="primary"
          onClick={() => {
            this.getRNG();
          }}
        >
          Generate Combination
        </Button>
        <h3>{rngCombination}</h3>
      </div>
    );
  }

  render() {
    const { visible, loading } = this.state;
    return (
      <div className="add-button">
        <Button type="primary" onClick={this.showModal}>
          {this.props.drawObject.first_set
            ? 'Draw Second Combination'
            : 'Draw First Combination'}
        </Button>
        {visible ? (
          <Modal
            className="add-modal"
            visible={visible}
            title={`Draw Winning Combination for ${this.props.drawdate}`}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            afterClose={this.onClose}
            footer={
              <>
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>
                {this.state.captcha ? null : this.state.drawCombination !==
                  this.state.drawinit ? (
                  <Popconfirm
                    title={`Are you sure to draw this combination: ${this.state.drawCombination}`}
                    onConfirm={() =>
                      this.props.drawObject.first_set
                        ? this.drawNewWinners()
                        : this.setInitialDraw()
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button key="submit" type="primary" loading={loading}>
                      Submit
                    </Button>
                  </Popconfirm>
                ) : null}
              </>
            }
          >
            {this.state.captcha ? (
              <>
                <div className={classNames('formitem', 'center')}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_REACTAPP_SITE_KEY}
                    onChange={(e) => this.onChangeCaptchaLogin(e)}
                  />
                </div>
              </>
            ) : !this.state.captcha && !this.state.verifiedPassword ? (
              <>{this.renderPassword()}</>
            ) : this.state.verifiedPassword ? (
              <>{this.renderform()}</>
            ) : null}
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    currentGame: state.game_data.currentGame,
    data: state.crud_data.data,
    winnersDrawn: state.game_data.winnersDrawn,
    currentDraw: state.game_data.currentDraw,
    dataCount: state.crud_data.dataCount,
    dataNext: state.crud_data.dataNext,
    dataPrevious: state.crud_data.dataPrevious,
    user_id: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    addItem: (data, apiurl, token) => dispatch(addItem(data, apiurl, token)),
    addDraw: (data, apiurl, token) => dispatch(addDraw(data, apiurl, token)),
    getOptions: (token, apiurl) => dispatch(getOptions(token, apiurl)),
    getGameDraws: (currentGame, token, controller, page) =>
      dispatch(getGameDraws(currentGame, token, controller, page)),
    logAction: (data, token) => dispatch(logAction(data, token)),
    drawWinners: (
      gameAdminApi,
      getWinnerUrl,
      winningCombination,
      token,
      draw_id
    ) =>
      dispatch(
        drawWinners(
          gameAdminApi,
          getWinnerUrl,
          winningCombination,
          token,
          draw_id
        )
      ),
    setInitialDraw: (
      gameAdminApi,
      getWinnerUrl,
      winningCombination,
      token,
      draw_id
    ) =>
      dispatch(
        setInitialDraw(
          gameAdminApi,
          getWinnerUrl,
          winningCombination,
          token,
          draw_id
        )
      ),
    generateDrawCombination: (gameAdminApi, token) =>
      dispatch(generateDrawCombination(gameAdminApi, token)),
    getGameNewDraws: (currentGame, token, controller, page, size) =>
      dispatch(getGameNewDraws(currentGame, token, controller, page, size)),
    verifyPassword: (password, onSuccess, onFail, onLock) =>
      dispatch(verifyPassword(password, onSuccess, onFail, onLock)),
    logout: (token, userId) => dispatch(logout(token, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDraw);
