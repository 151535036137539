import {
  PASS_CURRENT_GAME,
  GET_GAME_TRANSACTIONS,
  GET_GAME_DRAWS,
  DRAW_WINNERS,
  ADD_DRAW,
  GET_FILTERED_WINNERS,
  RESET_GAME_DATA,
  GET_TICKET_TRANSACTION,
  GET_GAME_NEW_DRAWS,
  GET_ALL_BETS_PER_DRAW,
  RESET_REPORTS_DATA,
  GET_ALL_BETS_PER_DRAW_ID,
  GET_ALL_BETS_PER_DRAW_ID_START,
  GET_FILTERED_WINNERS_FAIL,
  SET_INITIAL_DRAW_FAIL,
  SET_INITIAL_DRAW_START,
  SET_INITIAL_DRAW_SUCCESS,
} from './types';
import axios from 'axios';

export const passCurrentGame = (currentGame, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    var part_url = 'api/admin/games/';
    if (currentGame.game_id === undefined) {
    } else if (currentGame.game_id) {
      part_url += currentGame.game_id;
    } else {
    }

    return axios
      .get(process.env.REACT_APP_API_URL + part_url)
      .then((res) => {
        dispatch({
          type: PASS_CURRENT_GAME,
          currentGame: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: RESET_GAME_DATA,
        });
      });
  };
};

export const addDraw = (data, apiurl, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return axios
      .post(process.env.REACT_APP_API_URL + apiurl, data)
      .then((res) => {
        dispatch({
          type: ADD_DRAW,
          currentDraw: res.data,
        });
      });
  };
};

export const addDrawDate = (draw_date, currentGame, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    var data = {
      draw_date: draw_date,
    };
    return axios.post(currentGame + 'api/game/add_draw_date', data);
  };
};

export const getGameDraws = (currentGame, token, controller, page, size) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    var pagesize = 8;
    if (size) {
      pagesize = size;
    }
    return axios
      .get(
        currentGame +
          'api/game/get_draws' +
          `?page_num=${page}&page_size=${pagesize}`
      )

      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_GAME_DRAWS,
          draws: res.data,
        });
      });
  };
};
export const getGameNewDraws = (currentGame, token, controller, page, size) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    var pagesize = 8;
    if (size) {
      pagesize = size;
    }
    return axios
      .get(
        currentGame +
          'api/game/get_available_draws' +
          `?page_num=${page}&page_size=${pagesize}`
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_GAME_NEW_DRAWS,
          newdraws: res.data,
        });
      });
  };
};

export const getAllBetsByDrawDateById = (token, drawId) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    dispatch({
      type: GET_ALL_BETS_PER_DRAW_ID_START,
    });
    axios
      .get(
        process.env.REACT_APP_GAME_SERVER +
          'get_all_bets_by_draw_id?draw_id=' +
          drawId
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_ALL_BETS_PER_DRAW_ID,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getGameTransactions = (
  gameAdminApi,
  getTransactionsUrl,
  token,
  controllerText
) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(gameAdminApi + getTransactionsUrl + controllerText)
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_GAME_TRANSACTIONS,
          gameTransactions: res.data.transactions_list,
          gameTransactionsCount: res.data.total_count,
        });
      })
      .catch((err) => {});
  };
};

export const getRangedMonths = (
  gameAdminApi,
  getTransactionsUrl,
  token,
  controllerText
) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return axios.get(gameAdminApi + getTransactionsUrl + controllerText);
  };
};

export const drawWinners = (
  gameAdminApi,
  getWinnerUrl,
  winningCombination,
  token,
  draw_id
) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    var data = {
      bet_combination: winningCombination,
      draw_id: draw_id,
    };

    return axios.post(gameAdminApi + 'api/game/set_draw', data);
  };
};

export const setInitialDraw = (
  gameAdminApi,
  getWinnerUrl,
  winningCombination,
  token,
  draw_id
) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    var data = {
      bet_combination: winningCombination,
      draw_id: draw_id,
    };
    console.log(data);

    return axios.post(gameAdminApi + 'api/game/set_initial_draw', data);
  };
};

export const getFilteredWinners = (draw_id, token, controller, onFail) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (draw_id === undefined) {
      var data = {
        controller_url: 'api/game/get_winners_by_drawid',
      };
    } else if (draw_id) {
      var data = {
        draw_id: draw_id,
        controller_url:
          'api/game/get_winners_by_drawid' + `?draw_id=${draw_id}`,
      };
    } else {
      var data = {
        controller_url: 'api/game/get_winners_by_drawid',
      };
    }

    return axios
      .get(controller + data.controller_url)
      .then((res) => {
        if (res.data.is_success)
          dispatch({
            type: GET_FILTERED_WINNERS,
            filteredWinners: res.data.winners_list,
            count: res.data.count,
          });
        else {
          onFail();
          dispatch({
            type: GET_FILTERED_WINNERS_FAIL,
          });
        }
      })
      .catch((err) => {
        onFail();
        dispatch({
          type: GET_FILTERED_WINNERS_FAIL,
        });
      });
  };
};

export const getTicketTransaction = (ref_number, gameAdminApi, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': '	application/json;charset=ISO-8859-1',
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(
        gameAdminApi +
          'api/game/get_transaction_by_ref' +
          `?ref_num=${ref_number}`
      )
      .then((res) => {
        dispatch({
          type: GET_TICKET_TRANSACTION,
          gameTransactions: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const generateDrawCombination = (gameAdminApi, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': '	application/json;charset=ISO-8859-1',
      Authorization: `Bearer ${token}`,
    };

    return axios.get(gameAdminApi + 'api/birthday_panalo/generate_draw');
  };
};

export const sendNotification = (content, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': '	application/json;charset=ISO-8859-1',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(
      process.env.REACT_APP_API_URL + 'api/game/send_notification',
      content
    );
  };
};
/*
0:
amount: 5000
bet_type: "1"
bp_bet: "123456"
is_redeemed: 0
outlet_code: "0000001"
tid: "0000000001"
user_id: 1
__proto__: Object
1:
amount: 5000
bet_type: "1"
bp_bet: "123456"
is_redeemed: 0
outlet_code: "0000001"
tid: "0000000001"
user_id: 3
*/
