import {
  GET_DAILY_USER_CREDITS,
  GET_DAILY_USER_CREDITS_START,
  GET_DAILY_BETS_PRICE,
  GET_DAILY_PAYMAYA_PAYMENTS,
  GET_DAILY_GRABPAY_PAYMENTS,
  GET_DAILY_GCASH_PAYMENTS,
  RESET_REPORTS_DATA,
  GET_DAILY_UNDRAWN_COUNT,
  GET_DAILY_BETS_COUNT,
  GENERATE_DAILY_WALLET_COUNT,
  GENERATE_DAILY_WALLET_START,
  GET_WILL_DRAW_COUNT,
  GET_DAILY_BETS_PRICE_START,
  GET_DAILY_BETS_COUNT_START,
  GET_ALL_BETS_PER_DRAW,
  GET_ALL_WALLET_IN,
  GET_ALL_WALLET_OUT,
  GET_CURRENT_WALLET_PER_USER,
  GET_GATEWAY_CHARGES,
  GET_GATEWAY_CHARGES_START,
  GET_RANGED_USER_CREDITS,
  GET_RANGED_BETS_PRICE_START,
  GET_RANGED_BETS_PRICE,
  GENERATE_RANGED_WALLET_COUNT,
  GENERATE_RANGED_WALLET_START,
  GET_RANGED_BETS_COUNT,
  GET_RANGED_BETS_COUNT_START,
  GET_RANGED_GATEWAY_CHARGES_START,
  GET_RANGED_GATEWAY_CHARGES,
  GET_STARTING_WALLET_IN,
  GET_STARTING_WALLET_IN_START,
  GET_STARTING_WALLET_OUT,
  GET_STARTING_WALLET_OUT_START,
} from './types';

import axios from 'axios';

export const getDailyCredits = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    dispatch({
      type: GET_DAILY_USER_CREDITS_START,
    });
    axios
      .get(
        process.env.REACT_APP_AUTH_SERVER +
          'user_coins?start_date=' +
          payload.date
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_DAILY_USER_CREDITS,
          payload: { data: res.data.total_coins },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getBetsPrice = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    dispatch({
      type: GET_DAILY_BETS_PRICE_START,
    });
    axios
      .get(
        process.env.REACT_APP_GAME_SERVER +
          'get_total_bets_price_today?start_date=' +
          payload.date +
          '&created_at=' +
          payload.created_at
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_DAILY_BETS_PRICE,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const generateDailyWalletCount = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    dispatch({
      type: GENERATE_DAILY_WALLET_START,
    });
    return axios
      .get(
        process.env.REACT_APP_CALLCARD_SERVER +
          'posts/generate_daily_wallet_count?paid_date=' +
          payload.date
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GENERATE_DAILY_WALLET_COUNT,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getBetsDailyByTransaction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_DAILY_BETS_COUNT_START,
    });

    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    axios
      .get(
        process.env.REACT_APP_GAME_SERVER +
          'find_bets_daily_by_transaction?draw_date=' +
          payload.date
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_DAILY_BETS_COUNT,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getRangedCredits = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    axios
      .post(process.env.REACT_APP_AUTH_SERVER + 'ranged_coins', {
        start_date: payload.dates,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_RANGED_USER_CREDITS,
          payload: { data: res.data.total_coins },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getBetsPriceRange = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };

    dispatch({
      type: GET_RANGED_BETS_PRICE_START,
    });
    axios
      .post(process.env.REACT_APP_GAME_SERVER + 'get_total_bets_price_range', {
        created_at: payload.created_at,
        start_date: payload.dates,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_RANGED_BETS_PRICE,
          payload: { data: res.data.bets_array },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getStartingWalletOut = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };

    dispatch({
      type: GET_STARTING_WALLET_OUT_START,
    });
    axios
      .post(process.env.REACT_APP_GAME_SERVER + 'get_starting_wallet_out', {
        start_date: payload.dates,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_STARTING_WALLET_OUT,
          payload: { data: res.data.bets_array },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getStartingWalletIn = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    dispatch({
      type: GET_STARTING_WALLET_IN_START,
    });

    return axios
      .post(
        process.env.REACT_APP_CALLCARD_SERVER +
          'posts/generate_starting_wallet_in',
        {
          paid_date: payload.dates,
        }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_STARTING_WALLET_IN,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getRangedGatewayCharges = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    dispatch({
      type: GET_RANGED_GATEWAY_CHARGES_START,
    });

    return axios
      .post(
        process.env.REACT_APP_CALLCARD_SERVER +
          'posts/generate_gateway_charges_with_range',
        {
          paid_date: payload.dates,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_RANGED_GATEWAY_CHARGES,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const generateRangeWalletCount = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    dispatch({
      type: GENERATE_RANGED_WALLET_START,
    });
    return axios
      .post(
        process.env.REACT_APP_CALLCARD_SERVER +
          'posts/generate_daily_wallet_count_with_range',
        {
          start_date: payload.dates,
        }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GENERATE_RANGED_WALLET_COUNT,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getBetsRangedByTransaction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_RANGED_BETS_COUNT_START,
    });

    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    axios
      .post(
        process.env.REACT_APP_GAME_SERVER + 'find_bets_range_by_transaction',
        {
          draw_dates: payload.dates,
        }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_RANGED_BETS_COUNT,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getPaymayaTransactions = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    axios
      .get(
        process.env.REACT_APP_CALLCARD_SERVER +
          'paymayatransactions/generate_daily_payment?paid_date=' +
          payload.date
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_DAILY_PAYMAYA_PAYMENTS,
          payload: { data: res.data.total_coins },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getGCashTransactions = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    axios
      .get(
        process.env.REACT_APP_CALLCARD_SERVER +
          'paymongotransactions/generate_daily_payment?paid_date=' +
          payload.date +
          '&payment_type=gcash'
      )
      .then((res) => {
        dispatch({
          type: GET_DAILY_GCASH_PAYMENTS,
          payload: { data: res.data.total_coins },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getGatewayCharges = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };

    dispatch({
      type: GET_GATEWAY_CHARGES_START,
    });
    axios
      .get(
        process.env.REACT_APP_CALLCARD_SERVER +
          'posts/generate_gateway_charges?paid_date=' +
          payload.date
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_GATEWAY_CHARGES,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getGrabPayTransactions = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    return axios
      .get(
        process.env.REACT_APP_CALLCARD_SERVER +
          'paymongotransactions/generate_daily_payment?paid_date=' +
          payload.date +
          '&payment_type=grab_pay'
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_DAILY_GRABPAY_PAYMENTS,
          payload: { data: res.data.total_coins },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getAllBetsByDrawDate = (token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(process.env.REACT_APP_GAME_SERVER + 'get_all_bets_by_draw')
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_ALL_BETS_PER_DRAW,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getUnDrawnCount = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    return axios
      .get(
        process.env.REACT_APP_GAME_SERVER +
          'get_undrawn_bets_count?draw_date=' +
          payload.date
      )
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_DAILY_UNDRAWN_COUNT,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getDrawnTodayCount = (payload) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.token}`,
    };
    axios
      .get(
        process.env.REACT_APP_GAME_SERVER +
          'get_will_drawn_bets_count?draw_date=' +
          payload.date
      )
      .then((res) => {
        dispatch({
          type: GET_WILL_DRAW_COUNT,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getCurrentWalletPerUser = (token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(process.env.REACT_APP_AUTH_SERVER + 'users/get_coin_per_user')
      .then((res) => {
        dispatch({
          type: GET_CURRENT_WALLET_PER_USER,
          payload: { data: res.data.user_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getWalletOutTransactions = (token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(process.env.REACT_APP_GAME_SERVER + 'get_transaction_sum_per_user')
      .then((res) => {
        dispatch({
          type: GET_ALL_WALLET_OUT,
          payload: { data: res.data.bets_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};

export const getWalletInTransactions = (token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        process.env.REACT_APP_CALLCARD_SERVER +
          'posts/get_transaction_sum_per_user'
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_WALLET_IN,
          payload: { data: res.data.user_list },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_REPORTS_DATA,
          payload: { data: null },
        });
      });
  };
};
