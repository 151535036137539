import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../store/utility'

const initialState = {
    token: null,
    user: null,
    error: null,
    loading: false,

    
    screenActions:[
        'create',
        'read',
        'update',
        'delete',
        'activate',
        'deactivate'
        ]
  };

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const authSuccess = (state, action) => {
    const user= action.user
    return updateObject(state, {
        token: action.token,
        user:user,
        error: null,
        loading: false
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const authLogout = (state, action) => {

    return updateObject(state, {
        token: null,
        user: null,
        error: null,
        loading: false,
    })
}

export default (state = initialState,action) => {
  
    switch(action.type){
        case actionTypes.AUTH_START: 
            return authStart(state,action);
        case actionTypes.AUTH_SUCCESS: 
            return authSuccess(state,action);
        case actionTypes.AUTH_FAIL: 
            return authFail(state,action);
        case actionTypes.AUTH_LOGOUT: 
            return authLogout(state,action);
        default:
            return state;
    }

}