import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PageHeader, Pagination, Input, Button, Table, Spin } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames'
import Highlighter from 'react-highlight-words';
import './index.scss'
import AddModal from '../../components/AddModal'
import EditModal from '../../components/EditModal'
import PreviewDrawer from '../../components/PreviewDrawer'
import {getData,searchData,openEdit,openPreview} from '../../actions'

const { Search } = Input;
const INITIAL_STATE = {  
    searchText: ''
}
const API_URL="api/requests/"
const PAGENAME ="Request"
const MODAL_BLUEPRINT=[
    {
        title: 'ID',
        key: 'request_id',
        type: 'dont'     
    },
    {
        title: 'Status',
        key: 'status',
        type: 'text'    
    },
    {
        title: 'Game',
        key: 'game_id',
        type: 'options',
        name_variable:'game_name',
        api:'api/admin/games'    
    },
    {
        title: 'Message',
        key: 'message',
        type: 'text-area'         
    },
    {
        title: 'Priority ',
        key: 'priority',
        type: 'text',   

    },

]
class Requests extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            ...INITIAL_STATE,
            data:[],
            data:[],          
            sorter: null,
            filterArray:[],
            filters:null,
            page:1,
            loading:true,
            searchText:null,
            offset:0,        
            pageSize:6,
            defaultpageSize:6,
            range:[1,6],
            COLUMNS:[
                {
                    title: 'ID',
                    dataIndex: 'request_id',
                    key: 'request_id',
                    sorter: (a, b) => a.request_id > b.request_id,  
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    sorter: (a, b) => a.status > b.status,
                    ...this.getColumnSearchProps('status'),
                },  
                {
                    title: 'Game id',
                    dataIndex: 'game_id',
                    key: 'game_id',
                    sorter: (a, b) => a.game_id > b.game_id,       
                    ...this.getColumnSearchProps('game_id'), 
                },
                {
                    title: 'Game Name',
                    dataIndex: 'game_name',
                    key: 'game_name',
                    sorter: (a, b) => a.game_name > b.game_name,     
                },
                {
                    title: 'Created at',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    sorter: (a, b) => a.created_at > b.created_at,      
                    ...this.getColumnSearchProps('created_at'),  
                },
                {
                    title: 'Created by',
                    dataIndex: 'created_by',
                    key: 'created_by',
                    sorter: (a, b) => a.created_by > b.created_by,
                    ...this.getColumnSearchProps('created_by'), 
                }
              ]
        }
    
    }

    
    
    componentDidMount() {
        this.getTableData(this.state.defaultpageSize,0,null,null,null)
    }

    componentDidUpdate(){
        if(this.state.data !== this.props.data){
            this.setState({data:this.props.data })   
            if(this.props.data.length>0){
                this.setState({loading: false})
            }else{
                setTimeout(function() { //Start the timer
                    this.setState({loading: false}) //After 1 second, set render to true
                }.bind(this), 1000)
            }
       
        
        }
        
    }

    getTableData(limit,offset,sort,filter,search){
        this.setState({loading:true})
        if(this.props.token){
            var limitoffset = `?limit=${limit}&offset=${offset}`
            var controllerText =limitoffset
            var searchText =''
            if(search !== null){              
                searchText = `&search=${search}`
                controllerText =controllerText + searchText
            }
            var filterText =''
            if(filter !== null){              
                filterText = filter
                controllerText =controllerText +filterText
            }     
            var sortText =''
            if(sort !== null){              
                sortText = sort
                controllerText =controllerText +sortText
            }     
            this.props.getData(this.props.token,API_URL,controllerText)     
        }

    }

    handleTableChange = (pagination, filters, sorter) => {
        this.handleSorter(sorter)
     
    };




    handleSorter(sorter){

        if(this.state.sorter!== sorter){
            var sorterValue = null
            //console.log(sorter)
            if(sorter.order === 'ascend'){

                //console.log('ASC',sorter.columnKey )
                sorterValue = `&ordering=${sorter.columnKey}`
                this.setState({sorter:sorterValue})
                this.getTableData(this.state.pageSize,this.state.offset,sorterValue,this.state.filters,this.state.searchText)
            }else if(sorter.order === 'descend'){
                //console.log('DCS',sorter.columnKey)
                sorterValue = `&ordering=-${sorter.columnKey}`
                this.setState({sorter:sorterValue})
                this.getTableData(this.state.pageSize,this.state.offset,sorterValue,this.state.filters,this.state.searchText)
            }
            else if(sorter.order === undefined){
            
                sorterValue = null
                this.setState({sorter:sorterValue})
              
            }       
        }

    }
    
  

    onSearch(value){
        var searchval = value.toString();
        this.setState({searchText:searchval, page:1})   
        if(searchval){
            this.getTableData(this.state.defaultpageSize,0,null,null,searchval)
        }else{
            this.getTableData(this.state.defaultpageSize,0,null,this.state.filters,null)
        }     
    }

    getColumnSearchProps = dataIndex => ({
       
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm,dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm,dataIndex)}
              icon={<LegacyIcon type="search" />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters,dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <LegacyIcon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>{
            if(  record[dataIndex] !== undefined){
              return(          record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
                )
            }
          },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText,this.state.filterText]}
            autoEscape
            textToHighlight={'' + text}
          />
        ),
      });
    
    handleSearch = (selectedKeys, confirm,dataIndex) => {
        confirm();
      
        this.addtoFilterArray(selectedKeys[0],dataIndex)
        this.setState({ filterText: selectedKeys[0] });
    };

    handleReset = (clearFilters,dataIndex) => {
        clearFilters();
     
        this.removeFilterArray(dataIndex)
        this.setState({ filterText: '' });
    };
    generateFilterController(currentArray){
       
        var filter =''
        Object.entries(currentArray).map(([key, value]) =>{
            filter = filter + `&${value.dataIndex}=${value.value}`
        }) 
        this.setState({filters:filter})
        this.getTableData(this.state.defaultpageSize,0,null,filter,this.state.searchText)
    }


    addtoFilterArray(value,dataIndex){
        //console.log(value,dataIndex)
        var newItem = {
            value: value,
            dataIndex:dataIndex
        }   
        var i;
        for (i = 0; i < this.state.filterArray.length; i++) {
            if (this.state.filterArray[i]['dataIndex'] === dataIndex) {
                var newArray = this.state.filterArray
                newArray[i] = newItem
                return (this.setState({filterArray:newArray}, this.generateFilterController(newArray)));
            }
        }
        return (this.setState({filterArray: this.state.filterArray.concat(newItem)}, this.generateFilterController(this.state.filterArray.concat(newItem))));
    }

    removeFilterArray(dataIndex){  
        var i;
        for (i = 0; i < this.state.filterArray.length; i++) {
            if (this.state.filterArray[i]['dataIndex'] === dataIndex) {
                var newArray = this.state.filterArray
                newArray.splice(i, 1)             
                return (this.setState({filterArray:newArray}, this.generateFilterController(newArray)));
            }
        }
        return (this.generateFilterController(this.state.filterArray));
    }
    

    resetTable(){
        this.getTableData(this.state.defaultpageSize,0,null,null,null)
       //console.log(this.state)
        this.setState({      
             defaultpageSize:6,
             pageSize:6,
            range:[1,6],
            page:1,
            filterArray:[],
            filters:null,
            filterText:null,
            searchText:null,
            sorter:null,
            loading:true
        })
    }
    openEditModal(record){
        if(record && record !==undefined){              
            this.props.openEdit(record,true)
        }

    }


    onChangePage(page, pageSize){
        if(this.state.loading == false){
            this.setState({
                page: page,      
            })
        }    
    }

    onChangePageSize(currentPage, pageSize){
        if(this.state.pageSize !== pageSize && this.state.loading == false){
            this.setState({
                pageSize: pageSize,      
                page:1,
                offset:0,
            })                
            this.getTableData(pageSize,0,this.state.sorter,this.state.filters,this.state.searchText)
        }   
    }

    onChangeRange(total, range){  
    
        if(this.state.range[0] !== range[0] || this.state.range[1] !== range[1] ){
            var offset = range[0]-1        
            this.setState({range:range})                      
            this.getTableData(this.state.pageSize,offset,this.state.sorter,this.state.filters,this.state.searchText)
        }
    }

    columnfilter(){
        var filteredColumn=this.state.COLUMNS.slice()
        const editpart = [
            {
                title:'Edit',                   
                render: row =>{
                    var editItem = row
                    return (
                        <div onClick={(e)=>{e.stopPropagation();this.openEditModal(editItem);  }}>
                            <LegacyIcon type="edit" />
                        </div>
                    );                                      
                }
            }
        ]
        filteredColumn = filteredColumn.concat(editpart)
        return(filteredColumn)
    }



    render() {
        let { sorter} = this.state;
        sorter = sorter || {};     
        return <>
        <div className="generic-container">
            <PageHeader
               title={PAGENAME+'s'}
               subTitle=""                  
               extra={      
                <Search
                    className="search"
                    placeholder="search"
                    allowClear={true}                    
                    onSearch={(value)=>{this.onSearch(value)}}                  
                /> 
                 }
            >          
                <div  className="header">
                    <Button onClick={()=>{this.resetTable()}} className="button"><LegacyIcon type="reload" /></Button>
                    <AddModal headername={PAGENAME} blueprint={MODAL_BLUEPRINT} apiurl={API_URL}/>
                </div>
              
            </PageHeader>
            <div className="content-container">
          
                <Table 
                    loading={this.state.loading}
                    dataSource={this.state.data} 
                    columns={this.columnfilter()} 
                    sorter={true}
                    pagination={ {
                        onChange: (page, pageSize) => {this.onChangePage(page, pageSize)},
                        showTotal: (total, range)	=> {this.onChangeRange(total, range)},    
                        onShowSizeChange:(current, size)=>{this.onChangePageSize(current, size)}, 
                        current: this.state.page,                               
                        showQuickJumper:true,                              
                        defaultPageSize:this.state.defaultpageSize,
                        pageSizeOptions:[this.state.defaultpageSize, '20', '50', '100'],
                        showSizeChanger:true,
                        total:this.props.dataCount }
                        }
                    onRow={(record, rowIndex) => {
                        return {                             
                            onClick: () => {this.props.openPreview(record,true)}, // click row
                        };
                        }}
                    onChange={this.handleTableChange}
                        />
            </div>
                      
        </div>
        <EditModal headername={PAGENAME} blueprint={MODAL_BLUEPRINT} apiurl={API_URL}/>
        <PreviewDrawer blueprint={MODAL_BLUEPRINT}/>
        </>;
    }


}


const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        data: state.crud_data.data,
        dataCount:state.crud_data.dataCount,
        dataNext:state.crud_data.dataNext,
        dataPrevious:state.crud_data.dataPrevious,  
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (token,apiurl,contoller) => dispatch(getData(token,apiurl,contoller)),
        searchData:  (filter, data)  => dispatch(searchData(filter, data)),
        openEdit: (item,editModalOpen)  => dispatch(openEdit(item,editModalOpen)),
        openPreview: (item,previewModalOpen)  => dispatch(openPreview(item,previewModalOpen)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Requests);