import {
  GET_DATA_CALLCARD,
  SEARCH_DATA_CALLCARD,
  ADD_ITEM_CALLCARD,
  EDIT_ITEM_CALLCARD,
  OPEN_EDIT_CALLCARD,
  OPEN_PREVIEW_CALLCARD,
  RESET_DATA_CALLCARD,
  LOG_ACTION_CALLCARD,
} from './types';
import axios from 'axios';

export const getCallCardData = (token, apiurl, controller) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    console.log(process.env.REACT_APP_CALLCARD_SERVER + apiurl + controller);
    axios
      .get(process.env.REACT_APP_CALLCARD_SERVER + apiurl + controller)
      .then((res) => {
        // console.log(res)
        dispatch({
          type: GET_DATA_CALLCARD,
          payload: { data: res.data },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: RESET_DATA_CALLCARD,
          payload: { data: null },
        });
      });
  };
};

export const getRangedData_callcard = (token, data) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios.post(
      process.env.REACT_APP_CALLCARD_SERVER + 'posts/range',
      data
    );
  };
};

export const searchCallCardData = (filter, data) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_DATA_CALLCARD,
      filter: filter,
      data: data,
    });
  };
};

export const addItem_callcard = (data, apiurl, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(process.env.REACT_APP_CALLCARD_SERVER + apiurl, data);
  };
};

export const openEdit_callcard = (item, editModalOpen) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_EDIT_CALLCARD,
      item: item,
      editModalOpen: editModalOpen,
    });
  };
};

export const editItem_callcard = (data, apiurl, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios
      .put(process.env.REACT_APP_CALLCARD_SERVER + apiurl + `${id}/`, data)
      .then((res) => {});
  };
};

export const deleteItem_callcard = (apiurl, id, token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios
      .delete(process.env.REACT_APP_CALLCARD_SERVER + apiurl + `${id}/`)
      .then((res) => {});
  };
};

export const openPreview_callcard = (item, previewModalOpen) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_PREVIEW_CALLCARD,
      item: item,
      previewModalOpen: previewModalOpen,
    });
  };
};

export const getOptions_callcard = (token, apiurl, controller) => {
  return (dispatch) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return axios.get(
      process.env.REACT_APP_CALLCARD_SERVER + apiurl + controller
    );
  };
};
