import {
  PASS_CURRENT_GAME,
  GET_GAME_TRANSACTIONS,
  GET_GAME_DRAWS,
  DRAW_WINNERS,
  GET_GAME_NEW_DRAWS,
  ADD_DRAW,
  GET_FILTERED_WINNERS,
  RESET_GAME_DATA,
  GET_TICKET_TRANSACTION,
  SET_DRAW_FILTER,
  GET_FILTERED_WINNERS_FAIL,
} from '../actions/types';

const game_data = {
  data: [],
  editModalOpen: false,
  item: [],

  gameTransactions: [],
  gameTransactionsCount: 0,

  currentGame: [],
  currentDraw: [],

  draws: [],
  drawsCount: 0,

  newdraws: [],
  newdrawsCount: 0,

  winnersDrawn: [],

  filteredWinners: [],
  filteredWinnersCount: 0,

  drawFilter: null,
};

export default (state = game_data, action) => {
  var dataArray;
  switch (action.type) {
    case PASS_CURRENT_GAME:
      return {
        ...state,
        currentGame: action.currentGame,
      };

    case GET_GAME_TRANSACTIONS:
      var data = Object.entries(action.gameTransactions).map(([key, value]) => {
        var datum = { key, ...value };
        return datum;
      });
      return {
        ...state,
        gameTransactions: data,
        gameTransactionsCount: action.gameTransactionsCount,
      };
    case GET_TICKET_TRANSACTION:
      return {
        ...state,
        gameTransactions: action.gameTransactions,
      };

    case ADD_DRAW:
      return {
        ...state,
        currentDraw: action.currentDraw,
      };

    case GET_GAME_DRAWS:
      return {
        ...state,
        draws: action.draws.draws_list,
        drawsCount: action.draws.total_count,
      };

    case GET_GAME_NEW_DRAWS:
      return {
        ...state,
        newdraws: action.newdraws.draws_list,
        newdrawsCount: action.newdraws.total_count,
      };

    case DRAW_WINNERS:
      return {
        ...state,
        winnersDrawn: action.winnersDrawn,
      };

    case GET_FILTERED_WINNERS:
      return {
        ...state,
        filteredWinners: action.filteredWinners,
        filteredWinnersCount: action.count,
      };
    case GET_FILTERED_WINNERS_FAIL:
      return {
        ...state,
        filteredWinners: [],
        filteredWinnersCount: 0,
      };

    case SET_DRAW_FILTER:
      return {
        ...state,
        draw_filter: action.draw_filter,
      };

    case RESET_GAME_DATA:
      return {
        data: [],
        editModalOpen: false,
        item: [],
        gameTransactions: [],
        gameTransactionsCount: 0,
        currentGame: [],
        currentDraw: [],
        draws: [],
        drawsCount: 0,
        winnersDrawn: [],
        filteredWinners: [],
        filteredWinnersCount: 0,
      };

    default:
      return state;
  }
};
