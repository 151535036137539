import React, { Component } from 'react';

import { Modal, Input, Button, Select, message } from 'antd';
import { connect } from 'react-redux';

import moment from 'moment';
import './index.scss';

import { DownloadOutlined } from '@ant-design/icons';

import {
  getData,
  addItem,
  editItem,
  deleteItem,
  openEdit,
  getOptions,
  getRangedData_callcard,
} from '../../../actions';

import { CSVLink } from 'react-csv';

const Headers = [
  { label: 'created_at', key: 'created_at' },
  { label: 'amount', key: 'amount' },
  { label: 'reference', key: 'reference' },
  { label: 'claimed', key: 'claimed' },
  { label: 'claimed_at', key: 'claimed_at' },
  { label: 'paid', key: 'paid' },
  { label: 'paid_at', key: 'paid_at' },
  { label: 'payment_gateway', key: 'payment_gateway_id' },
  { label: 'user', key: 'user' },
  { label: 'cp_num', key: 'cp_num' },
  { label: 'updated_at', key: 'updated_at' },
];

class Export extends Component {
  state = {
    loading: false,
    visible: false,
    data: [],
    exportlist: [],
  };

  exportData() {
    if (this.props.dateRange.length > 0 && this.state.loading == false) {
      this.setState({ loading: true });
      var moment1 = this.props.dateRange[0];
      var moment2 = this.props.dateRange[1];
      var startDate = moment(moment1).format('YYYY-MM-DD');
      var endDate = moment(moment2).format('YYYY-MM-DD');
      var data = {
        start_date: startDate,
        end_date: endDate,
      };
      this.props
        .getRangedData_callcard(this.props.token, data)
        .then((res) => {
          this.setState({ data: this.dataFilter(res.data.data) }, () => {
            this.Export();
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else if (this.props.dateRange.length < 1) {
      message.error('No Dates selected');
    } else if (this.state.loading) {
      message.error('Please wait retreiving data');
    }
  }

  Export() {
    setTimeout(
      function () {
        message.info('exporting');
        this.csvLink.link.click();
        this.setState({ loading: false });
      }.bind(this),
      2000
    );
  }

  filterPaymentgGateway(id) {
    switch (id) {
      case 2:
        return 'Paymaya';
        break;
      case 4:
        return 'GCash';
        break;
      case 5:
        return 'Grabpay';
        break;
      default:
        return id;
    }
  }

  dataFilter(data) {
    var newData = data;
    console.log(data);
    if (data) {
      console.log(data);
      newData = Object.entries(data).map(([key, value]) => {
        var newvalue = {
          ...value,
          payment_gateway_id: this.filterPaymentgGateway(
            value.payment_gateway_id
          ),
        };
        return newvalue;
      });
    }
    return newData;
  }

  render() {
    return (
      <div>
        <CSVLink
          data={this.state.data}
          headers={Headers}
          filename={`transactions.csv`}
          target="_blank"
          className="hiddencsv"
          ref={(r) => (this.csvLink = r)}
        ></CSVLink>
        <Button
          type={this.props.dateRange.length > 0 ? 'primary' : 'dashed'}
          className="add-button"
          onClick={() => {
            this.exportData();
          }}
          loading={this.state.loading}
        >
          <DownloadOutlined />
          Export all within dates
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.crud_data.data,
    editModalOpen: state.crud_data.editModalOpen,
    item: state.crud_data.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRangedData_callcard: (token, data) =>
      dispatch(getRangedData_callcard(token, data)),
    getData: (token, apiurl, contoller) =>
      dispatch(getData(token, apiurl, contoller)),
    addItem: (token, data, apiurl) => dispatch(addItem(token, data, apiurl)),
    editItem: (token, data, apiurl, id) =>
      dispatch(editItem(token, data, apiurl, id)),
    deleteItem: (token, apiurl, id) => dispatch(deleteItem(token, apiurl, id)),
    openEdit: (item, editModalOpen) => dispatch(openEdit(item, editModalOpen)),
    getOptions: (token, apiurl, controller) =>
      dispatch(getOptions(token, apiurl, controller)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
